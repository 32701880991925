.popup {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    &__block {
        display: flex;
        align-items: center;
        padding: 15px 60px 15px 75px;
        z-index: 500;
        bottom: 0;
        max-width: 100%;
        width: 100%;
        background-color: #fff;
        overflow: hidden;
        box-shadow: 0 25px 60px rgba(0, 0, 0, 0.05);
        position: relative;
        margin-left: auto;

        .popup--dark & {
            background-color: #09101B;
        }
    }

    &__icon {
        width: 55px;
        height: 55px;
        position: absolute;
        bottom: -9px;
        left: -9px;
        background-image: url('/local/html/img/cookies.png');
        background-size: 100%;

        .popup--dark & {
            width: 58px;
            height: 58px;
            bottom: 0;
            left: 8px;
        }
    }

    &__heading {
        margin-bottom: 0;
        font-size: 20px;
        line-height: 1.2;

        .popup--dark & {
            color: white;
        }
    }

    &__text {
        margin-bottom: 7px;
        color: rgba(#1a1c1b, .8) !important;
        font-size: 14px;

        a {
            color: var(--light-blue);
        }

        .popup--dark & {
            color: white !important;
        }
    }

    &__close-btn {
        width: 17px;
        height: 17px;
        position: absolute;
        top: 15px;
        right: 15px;
        &:hover {
            .popup__close {
                fill: #1A1C1B;
            }
        }
    }

    &__close {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: .3s ease;
        fill: rgba(#1A1C1B, .6);

        .popup--dark & {
            fill: white;
        }
    }

    &__close-icon {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
    @media (max-width: 768px) {
        &__block {
            padding: 20px;
            padding-right: 25px;
            padding-left: 55px;
        }

        &__icon {
            bottom: -15px;
            left: -15px;
            width: 75px;
            height: 75px;
        }

        &__heading {
            font-size: 18px;
        }
        &__text {
            font-size: 12px;
        }
        &__close {
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &__close-btn {
            top: 0px;
            right: 0px;
            width: 30px;
            height: 30px;
        }
    }
}


.bx-panel-box #panel {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 50000;
}

.d-none_md {
    display: none !important;
    @media(max-width:767px) {
        display: flex !important;
    }
}

.footer  {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
    @media(max-width:767px) {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
}
