@media (min-width: 769px) {
    .CTA {
        position: fixed;
        bottom: 64px;
        right: 1rem;
        visibility: hidden;
        opacity: 0;
        background: #fff;
        color: var(--main-bg) !important;
        transition: all 0.3s ease;
        border: 1px solid var(--main-bg);
    }

    .CTA.enabled {
        position: fixed;
        visibility: visible;
        opacity: 1;
        z-index: 100;
        width: auto;
        height: 54px;
    }

    .CTA i,
    .CTA svg {
        display: none;
        font-style: normal;
    }
}

.CTA i,
.CTA svg {
    font-style: normal;
}

.CTA.enabled {
    color: #fff !important;
    border: none;
}

.CTA.enabled .bg_gradient {
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background: var(--main-gradient) !important;
    position: absolute;
    z-index: -1;
}

.CTA.enabled .bg_gradient {
    opacity: 1;
    visibility: visible;
    border-radius: 0.15rem;
    display: none;
}
@media (max-width: 768px) {
    .CTA .bg_gradient {
        top: 0;
        left: 0;
        position: absolute;
        z-index: -1;
    }

    .CTA.enabled .bg_gradient {
        display: block;
        border-radius: 0 !important;
    }

    .CTA {
        border: none;
        border-radius: 0;

        position: fixed;
        visibility: hidden;
        opacity: 0;
        color: #fff !important;
        transition: all 0.3s ease;
        width: 100vw;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        height: 54px;
    }

    .CTA.enabled {
        color: #fff !important;
        width: 100vw;
        z-index: 100;
        visibility: visible;
        opacity: 1;
    }

    .CTA i,
    .CTA svg {
        display: inline;
    }

    main > .bg-img.fight:first-child:before,
    .contact-wrapper:before {
        background-size: 50% !important;
    }
}
.row.mt-5.mx-0 {
    z-index: 5;
}
.bg-img {
    position: relative;
    overflow: hidden;
    z-index: 1;
    &_lines {
        &:before {
            background-image: url("/local/html/img/lines-sec.svg");
            background-size: contain;
        }
    }
    &__img {
        width: 634px;
        height: 60%;
        position: absolute;
        top: 250px;
        right: 22px;
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
    }

    @media (max-width: 1440px) {
        &__img {
            width: 40%;
        }
    }

    @media (max-width: 992px) {
        &__img {
            position: static;
            width: 50%;
            height: 300px;
            background-position: center;
            margin-top: 50px;
            order: 1;
        }
    }

    @media (max-width: 767px) {
        &__img {
            width: 90%;
        }
    }
}

.banner-dark {
    .arrow-link {
        color: #1a1c1b;
    }
}
