//firsst

// vendors
@import "_vendor/app.css";

// default

// libs
//@import "_vendor/";

// forms & buttons

// main
@import "main/base";
@import "main/links";
@import "main/grid";
@import "main/ui";
@import "main/typography";
@import "main/text";
@import "main/section";

//sliders
@import "sliders/promo-slider";
@import "sliders/utility-slider";

// blocks
@import "blocks/introduction";
@import "blocks/solutions";
@import "blocks/functions";
@import "blocks/main-form";
@import "blocks/contacts";
@import "blocks/f-serv";
@import "blocks/error-page";
@import "blocks/chess-grid";
@import "blocks/cta";
@import "blocks/portfolio";
@import "blocks/nx-list-slider";
@import "blocks/footer";
@import "blocks/rating";
@import "blocks/header";
@import "blocks/services";
@import "blocks/nx-files";
@import "blocks/integrations";
@import "blocks/clients-main";
//@import "blocks/aos";
@import "blocks/wow";
@import "blocks/news-blog";
@import "blocks/news";
@import "blocks/subscribe";
@import "blocks/show-more";
@import "blocks/preloader";
@import "blocks/socials";
@import "blocks/quote";
@import "blocks/video";
@import "blocks/modal";
@import "blocks/popup";
@import "blocks/nx-actions";
@import "blocks/v-filter";
@import "blocks/v-list";
@import "blocks/v-select";
@import "blocks/media";
@import "blocks/tvc";
@import "blocks/ae";

// india
@import "blocks/india";

// Emirates
@import "blocks/emirates";

// UK
@import "blocks/uk";

// USA
@import "blocks/usa";

// Welcome
@import "blocks/welcome";

.standard-padding_b-none-sm {
    @media(max-width:768px) {
        padding-bottom: 0 !important;
    }
}
.main > .fight_pd {
    padding-bottom: 0 !important;
}
.fight_pd h1{
    overflow-wrap: break-word;
}
//pages
//@import "pages/";

// themes
//@import "themes/bootstrap-main-styles";
