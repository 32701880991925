a,a:visited,a:focus {
    color: var(--light-gray);
}
a:hover,.bg_white a:hover {
    color: var(--light-blue);
}
a.hover-inherit:hover,a.hover-inherit {
    color: inherit;
}
a.no-underline:hover {
    text-decoration: none;
}
.button-transition {
    color: #fff;
    background: transparent;
    transition: all .3s ease;
}
.button-transition:hover {
    color: #000 !important;
    background: #fff;
}
.arrow-link {
    display: inline-block;
    position: relative;
    padding-right: calc(26px + 1rem);
}
.arrow-link:after {
    position: absolute;
    content: url("data:image/svg+xml; utf8, <svg width='26' height='13' viewBox='0 0 26 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.485352 6.14992L24.4854 6.14992M24.4854 6.14992L18.8246 0.706299M24.4854 6.14992L18.8246 11.9063' stroke='white' stroke-opacity='0.5' stroke-width='1.5'/></svg>");
    top: 50%;
    transform: translate3D(0,-50%,0);
    margin-top: 2px;
    margin-left: 1rem;
    right: 0;
    transition: transform .3s ease;
    @media(max-width: 991px) {
        position: static;
        transform: none;
        margin-top: 4px;
    }
}
.bg_white .arrow-link::after {
    content: url("data:image/svg+xml; utf8, <svg width='26' height='13' viewBox='0 0 26 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.485352 6.14992L24.4854 6.14992M24.4854 6.14992L18.8246 0.706299M24.4854 6.14992L18.8246 11.9063' stroke='rgb(26,28,27)' stroke-opacity='0.5' stroke-width='1.5'/></svg>");
}
.arrow-link:hover:after {
    transform: translate3D(8px,-50%,0);
    content: url("data:image/svg+xml; utf8, <svg width='26' height='13' viewBox='0 0 26 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.485352 6.14992L24.4854 6.14992M24.4854 6.14992L18.8246 0.706299M24.4854 6.14992L18.8246 11.9063' stroke='rgb(37,162,202)' stroke-opacity='0.5' stroke-width='1.5'/></svg>");
}

.arrow-link_light:hover:after {
    content: url("data:image/svg+xml; utf8, <svg width='26' height='13' viewBox='0 0 26 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.485352 6.14992L24.4854 6.14992M24.4854 6.14992L18.8246 0.706299M24.4854 6.14992L18.8246 11.9063' stroke='rgb(255,255,255)' stroke-opacity='1' stroke-width='1.5'/></svg>");
}
