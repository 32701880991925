$file: (
  icon-w: 68px,
  icon-w-big: 110px,
  icon-w-xs: 60px,
  icon-h: 90px,
  icon-h-big: 150px,
  icon-h-hs: 80px,
);
.nx-files {
  margin-bottom: -40px;

  &__item {
    display: block;
    margin-bottom: 40px;
    position: relative;
  }

  &__del {
    display: flex;
    align-items: center;
    line-height: 1;
    justify-content: center;
    padding: 0 15px;
    color: red;
    font-size: 30px;

    &:hover {
      opacity: 0.7;
    }
  }

  &__link {
    display: flex;
    width: 100%;
  }

  &__icon {
    flex: none;
    display: block;
    align-self: flex-start;
    width: map_get($file, icon-w);
    height: map_get($file, icon-h);
    background: transparent center no-repeat;
    background-size: contain;
    margin-right: 15px;
  }

  &__type {
    display: block;
    margin-top: 10px;
    color: red;
    font-size: 12px;
  }

  &_upload {
    margin-bottom: 0;
    width: 100%;

    .nx-files__item {
      margin-bottom: 15px;
      height: 105px;
      background-color: rgba(#fff, 0.1);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .nx-files__icon {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 30px;
      left: 30px;
      transition: all .3s ease;

      &--gradient {
        opacity: 0;
      }
    }

    .nx-files__content {
      width: 100%;
      position: relative;
      padding-left: 90px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .nx-files__link {
      cursor: default;
      fill: blue;
      height: 100%;
      display: block;
      transition: all .3s ease;

      &.link {
        cursor: pointer;
      }

      &:hover {
        .nx-files__icon {
          &--gradient {
            opacity: 1;
          }
        }

        .nx-files__title {
          background: linear-gradient(270deg, #0b6ba7 0%, #96c15b 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    span:not(.nx-files__title) {
      border-bottom: none;
    }

    .nx-files__title {
      font-size: 18px;
      margin-bottom: 5px;

      span {
        opacity: 0.6;
      }
    }

    .nx-files__subtitle {
      font-size: 14px;
      color: rgba(#fff, 0.4);
    }

    .nx-files__type {
      margin-top: 5px;
    }
  }

  @media (min-width: 767px) {
    margin-bottom: -20px;

    &__item {
      margin-bottom: 20px;
    }

    &__icon {
      width: map_get($file, icon-w-xs);
      height: map_get($file, icon-h-xs);
      margin-bottom: 10px;
    }

    &_upload {
      margin-bottom: 0;
    }
  }

  .files {
    &__list {
      .nx-files {
        &__link {
          position: relative;
          display: flex;
          align-items: center;
          height: auto;
          padding-right: 54px;
        }
        &__title {
          font-size: 14px;
          line-height: 15px;
          margin: 0;
          word-break: break-word;
          span {
            opacity: 0.4;
          }
        }

        &__content {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          padding: 0;
          &::before {
            content: "";
            width: 35px;
            height: 35px;
            margin-right: 10px;
            flex-shrink: 0;
            -webkit-background-size: contain;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(../img/icon-file-default.svg);
          }
          &.jpeg,
          &.jpg {
            &::before {
              background-image: url(../img/icon-jpg.svg);
            }
          }
          &.png {
            &::before {
              background-image: url(../img/icon-png.svg);
            }
          }
          &.doc,
          &.docx {
            &::before {
              background-image: url(../img/icon-doc.svg);
            }
          }
          &.pdf {
            &::before {
              background-image: url(../img/icon-pdf.svg);
            }
          }
        }

        &__del {
          position: absolute;
          top: 50%;
          right: 0;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          transform: translateY(-50%);
          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24px;
            height: 2px;
            background: var(--red);
            transition: 0.2s;
          }
          &::before {
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }

          .link-icon {
            display: none;
          }
        }
      }
    }

    &__el {
      margin-top: 8px;
    }
  }
}
