.text {
    font-size: 1.125rem;
    h2 {
        margin-bottom: 12px;
    }
    li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    ul {
        list-style-type: none;
        padding-left: 0;
        li {
            &:before {
                display: block;
                content: "";
                position: absolute;
                top: .5rem;
                left: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #96C15B;
            }
        }
    }
    ol {
        counter-reset: li;
        list-style: none;
        padding-left: 0;
        li {
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: counter(li);
                counter-increment: li;
                font-size: 1.125rem;
                color: #96C15B
            }
        }
    }
    
    figure {
        img  {
            display: block;
            width: 100%;
        }
        
        figcaption {
            margin-top: 10px;
            font-size: 1.125rem;
            color: rgba(26, 28, 27, 0.5) !important;
            font-style: italic;
        }
    }
    img {
        width: 100% !important;
        height: auto !important;
    }

    iframe {
        max-width: 100%;
    }
}

.note-list {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    
    .note {
        color: #1A1C1B !important;
        opacity: .8;
    }
}

.note {
    color: rgba(255, 255, 255, 0.4) !important;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
}

.container-vac {
    max-width: 730px;
    margin: 0 auto;

    .text {
        h2 {
            font-size: 50px;
            line-height: 60px;
            @media(max-width:768px) {
                font-size: 1.75rem;
                line-height: normal;
            }
        }
        h3 {
            font-size: 32px;
            line-height: 38.4px;
            @media(max-width:768px) {
                font-size: 1.5rem;
                line-height: normal;
            }
        }
        hr {
            margin-top: 40px;
            border: none;
            height: 2px;
            background: #1A1C1B;
            opacity: .1;
        }
    }

}
