*,
*::before,
*::after {
    box-sizing: inherit
}

.container_mg {
    margin-bottom: 165px;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.container-narrow {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 760px;
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:768px) {
    .container,
    .container-sm,
    .container-md {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1140px
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%
}

.col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%
}

.col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%
}

.col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    -ms-flex-order: -1;
    order: -1
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: flex!important
}

.d-inline-flex {
    display: inline-flex!important
}

.flex-row {
    flex-direction: row!important
}

.flex-column {
    flex-direction: column!important
}

.flex-row-reverse {
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    flex-direction: column-reverse!important
}

.flex-wrap {
    flex-wrap: wrap!important
}

.flex-nowrap {
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse!important
}

.justify-content-start {
    justify-content: flex-start!important
}

.justify-content-end {
    justify-content: flex-end!important
}

.justify-content-center {
    justify-content: center!important
}

.justify-content-between {
    justify-content: space-between!important
}

.justify-content-around {
    justify-content: space-around!important
}

.align-items-start {
    align-items: flex-start!important
}

.align-items-end {
    align-items: flex-end!important
}

.align-items-center {
    align-items: center!important
}

.align-items-baseline {
    align-items: baseline!important
}

.align-items-stretch {
    align-items: stretch!important
}

.align-content-start {
    align-content: flex-start!important
}

.align-content-end {
    align-content: flex-end!important
}

.align-content-center {
    align-content: center!important
}

.align-content-between {
    align-content: space-between!important
}

.align-content-around {
    align-content: space-around!important
}

.align-content-stretch {
    align-content: stretch!important
}

.align-self-auto {
    align-self: auto!important
}

.align-self-start {
    align-self: flex-start!important
}

.align-self-end {
    align-self: flex-end!important
}

.align-self-center {
    align-self: center!important
}

.align-self-baseline {
    align-self: baseline!important
}

.align-self-stretch {
    align-self: stretch!important
}

.m-0 {
    margin: 0!important
}

.mt-0,
.my-0 {
    margin-top: 0!important
}

.mr-0,
.mx-0 {
    margin-right: 0!important
}

.mb-0,
.my-0 {
    margin-bottom: 0!important
}

.ml-0,
.mx-0 {
    margin-left: 0!important
}

.m-1 {
    margin: 0.5rem!important
}

.mt-1,
.my-1 {
    margin-top: 0.5rem!important
}

.mr-1,
.mx-1 {
    margin-right: 0.5rem!important
}

.mb-1,
.my-1 {
    margin-bottom: 0.5rem!important
}

.ml-1,
.mx-1 {
    margin-left: 0.5rem!important
}

.m-2 {
    margin: 1rem!important
}

.mt-2,
.my-2 {
    margin-top: 1rem!important
}

.mr-2,
.mx-2 {
    margin-right: 1rem!important
}

.mb-2,
.my-2 {
    margin-bottom: 1rem!important
}

.ml-2,
.mx-2 {
    margin-left: 1rem!important
}

.m-3 {
    margin: 1.5rem!important
}

.mt-3,
.my-3 {
    margin-top: 1.5rem!important
}

.mr-3,
.mx-3 {
    margin-right: 1.5rem!important
}

.mb-3,
.my-3 {
    margin-bottom: 1.5rem!important
}

.ml-3,
.mx-3 {
    margin-left: 1.5rem!important
}

.m-4 {
    margin: 2rem!important
}

.mt-4,
.my-4 {
    margin-top: 2rem!important
}

.mr-4,
.mx-4 {
    margin-right: 2rem!important
}

.mb-4,
.my-4 {
    margin-bottom: 2rem!important
}

.ml-4,
.mx-4 {
    margin-left: 2rem!important
}

.m-5 {
    margin: 3rem!important
}

.mt-5,
.my-5 {
    margin-top: 3rem!important
}

.mr-5,
.mx-5 {
    margin-right: 3rem!important
}
.mr-5-xs,
.mx-5-xs {
    @media(max-width:767px) {
        margin-right: 1rem!important
    }
}

.mb-5,
.my-5 {
    margin-bottom: 3rem!important
}

.ml-5,
.mx-5 {
    margin-left: 3rem!important
}

.m-6 {
    margin: 4rem!important
}

.mt-6,
.my-6 {
    margin-top: 4rem!important
}

.mr-6,
.mx-6 {
    margin-right: 4rem!important
}

.mb-6,
.my-6 {
    margin-bottom: 4rem!important
}

.ml-6,
.mx-6 {
    margin-left: 4rem!important
}

.m-7 {
    margin: 6rem!important
}

.mt-7,
.my-7 {
    margin-top: 6rem!important
}

.mr-7,
.mx-7 {
    margin-right: 6rem!important
}

.mb-7,
.my-7 {
    margin-bottom: 6rem!important
}

.ml-7,
.mx-7 {
    margin-left: 6rem!important
}

.m-8 {
    margin: 12rem!important
}

.mt-8,
.my-8 {
    margin-top: 12rem!important
}

.mr-8,
.mx-8 {
    margin-right: 12rem!important
}

.mb-8,
.my-8 {
    margin-bottom: 12rem!important
}

.ml-8,
.mx-8 {
    margin-left: 12rem!important
}

.ml-auto {
    margin-left: auto!important
}

.mr-auto {
    margin-right: auto!important
}

.p-0 {
    padding: 0!important
}

.pt-0,
.py-0 {
    padding-top: 0!important
}

.pr-0,
.px-0 {
    padding-right: 0!important
}

.pb-0,
.py-0 {
    padding-bottom: 0!important
}

.pl-0,
.px-0 {
    padding-left: 0!important
}

.p-1 {
    padding: 0.5rem!important
}

.pt-1,
.py-1 {
    padding-top: 0.5rem!important
}

.pr-1,
.px-1 {
    padding-right: 0.5rem!important
}

.pb-1,
.py-1 {
    padding-bottom: 0.5rem!important
}

.pl-1,
.px-1 {
    padding-left: 0.5rem!important
}

.p-2 {
    padding: 1rem!important
}

.pt-2,
.py-2 {
    padding-top: 1rem!important
}

.pr-2,
.px-2 {
    padding-right: 1rem!important
}

.pb-2,
.py-2 {
    padding-bottom: 1rem!important
}

.pl-2,
.px-2 {
    padding-left: 1rem!important
}

.p-3 {
    padding: 1.5rem!important
}

.pt-3,
.py-3 {
    padding-top: 1.5rem!important
}

.pr-3,
.px-3 {
    padding-right: 1.5rem!important
}

.pb-3,
.py-3 {
    padding-bottom: 1.5rem!important
}

.pl-3,
.px-3 {
    padding-left: 1.5rem!important
}

.p-4 {
    padding: 2rem!important
}

.pt-4,
.py-4 {
    padding-top: 2rem!important
}

.pr-4,
.px-4 {
    padding-right: 2rem!important
}

.pb-4,
.py-4 {
    padding-bottom: 2rem!important
}

.pl-4,
.px-4 {
    padding-left: 2rem!important
}

.p-5 {
    padding: 3rem!important
}

.p-7 {
    padding: 7rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem!important
}

.pr-5,
.px-5 {
    padding-right: 3rem!important
}

.pb-5,
.py-5 {
    padding-bottom: 3rem!important
}

.pl-5,
.px-5 {
    padding-left: 3rem!important
}

.p-6 {
    padding: 4rem!important
}

.pt-6,
.py-6 {
    padding-top: 4rem!important
}

.pr-6,
.px-6 {
    padding-right: 4rem!important
}

.pb-6,
.py-6 {
    padding-bottom: 4rem!important
}

.pl-6,
.px-6 {
    padding-left: 4rem!important
}

.p-7 {
    padding: 6rem!important
}

.pt-7,
.py-7 {
    padding-top: 6rem!important
}

.pr-7,
.px-7 {
    padding-right: 6rem!important
}

.pb-7,
.py-7 {
    padding-bottom: 6rem!important
}

.pl-7,
.px-7 {
    padding-left: 6rem!important
}

.p-8 {
    padding: 12rem!important
}

.pt-8,
.py-8 {
    padding-top: 12rem!important
}

.pr-8,
.px-8 {
    padding-right: 12rem!important
}

.pb-8,
.py-8 {
    padding-bottom: 12rem!important
}

.pl-8,
.px-8 {
    padding-left: 12rem!important
}

@media (min-width:576px) {
    .col-sm {
        flex-basis: 0%;
        flex-grow: 1;
        max-width: 100%
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-sm-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-sm-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-sm-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-sm-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-sm-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-sm-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-sm-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-sm-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media (min-width:768px) {
    .col-md {
        flex-basis: 0%;
        flex-grow: 1;
        max-width: 100%
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-md-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-md-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-md-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-md-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-md-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-md-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-md-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media (min-width:992px) {
    .col-lg {
        flex-basis: 0%;
        flex-grow: 1;
        max-width: 100%
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-lg-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-lg-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-lg-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-lg-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-lg-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-lg-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-lg-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-lg-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}
.h-100vh {
    height: 100vh;
}
.list-style-none {
    list-style: none;
}
.cursor-pointer {
    cursor: pointer;
}
.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}
.fw-bold {
    font-weight: bold;
}
.text-uppercase {
    text-transform: uppercase;
}
body {
    padding-top: 168px;
}
@media(max-width: 991px) {
    body {
        padding-top: 186px;
    }
}
@media(max-width:768px) {
    body {
        padding-top: 170px;
    }
}
@media(max-width:638px) {
    body {
        padding-top: 194px;
    }
}
@media(max-width:576px) {
    body {
        padding-top: 200px;
    }
}
@media(max-width:480px) {
    body {
        padding-top: 190px;
    }
}
@media(max-width:353px) {
    body {
        padding-top: 208px;
    }
}
@media(max-width:320px) {
    body {
        padding-top: 244px;
    }
}
.breadcrumbs_offset {
    margin-bottom: 80px;
}
.breadcrumbs {
    margin-bottom: 40px;
}
.breadcrumbs>ul {
    align-items: center;
    list-style: none;
    margin: 0;
}
.breadcrumbs>ul>li {
    color: var(--white_opacity);
    display: inline-block;
}
.breadcrumbs>ul>li>a {
    color: var(--white_opacity);

    &:hover {
        text-decoration: none;
        color: var(--light-blue);
    }
}
.breadcrumbs>ul .h6 {
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 400;
}
.breadcrumbs>ul > li.h4 {
    font-size: 0.9rem;
    line-height: 1.8rem;
}
.breadcrumbs li:not(:last-child):after {
    content: '/';
    color: var(--deep-gray);
    padding: 0 .5rem;
    font-size: 18px;
}

.object-contain {
    object-fit: contain;
}


@media(max-width:768px) {
    .py-md-7 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }
    .px-md-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .row_f-xs {
        flex-wrap: nowrap;
    }
}

/* main-gradient-block */
.main-gradient-block {
    padding: 4rem;
}
@media(max-width:768px) {
    .pr-xs {
        padding-right: 15px !important;
    }
    .mt-1-xs {
        margin-top: 1rem !important;
    }
}
@media(max-width: 961px) {
    .js-hor-scroll {
        overflow: hidden;
        user-select: none;

        &_pdb {
            padding-bottom: 3px;
        }

        .pf-filter__list-top {
            display: flex;
            width: auto;
            max-width: unset;
            flex-wrap: nowrap;
        }

        .services-main__element,
        .portfolio__grid {
            flex-direction: row;
            flex-wrap: nowrap;
        }

        .portfolio-grid__item {
            flex: 0 0 83.333333%;
        }

        .services-main__item {
            max-width: 90%;
        }

        .depart-main__item,
        .services-main__item,
        .portfolio-grid__item {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
