.contact__row {
    position: relative;
}

.contact__row a {
    display: block;
    margin-bottom: 1rem;
}

.contact__row::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: -.5rem;
    left: -.5rem;
    background: #000000;
    opacity: 0;
    transition: .15s ease-out;
    border-radius: 8px;
}

.contact__row#id_row_0 {
    border-top: none;
}

.contact__row:first-child::before {
    top: -3rem;
}

.contact__row.active::before {
    opacity: .05;
    transition: .5s ease-out;
}
