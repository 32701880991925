.uk {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--india-bg-primary);
  padding: 0;
  color: var(--india-typography-primary);

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    margin: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .btn {
    text-decoration: none;
    outline: none;
    border-radius: 3px;
    padding: 16px 29px;
    white-space: nowrap;
    transition: all linear 0.2s;
    cursor: pointer;
    text-align: center;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      outline: none;
    }

    &--primary {
      font-weight: 400;
      color: white !important;
      background: var(--main-gradient);
      border: none;
      transition: all linear 0.2s;

      &:hover {
        color: var(--india-bg-primary) !important;
        background: linear-gradient(89.85deg, #96c15b 0.1%, #0b6ba7 99.87%);
      }
    }

    &--secondary {
      background: var(--india-bg-primary);
      border: none;
      font-weight: 600;

      &:hover {
        background: rgba(white, 0.9);
      }

      &:disabled {
        opacity: 0.2;
        border: 2px solid #ffffff;
        background: transparent;
      }

      &-text {
        color: var(--india-bg-primary);
        background: var(--main-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &--hollow {
      font-weight: 600;
      background: transparent;
      border: 2px solid white;
      color: white !important;

      &:hover {
        background: white;

        .btn--hollow-text {
          color: var(--india-bg-primary);
          background: var(--main-gradient);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &--link {
      background: transparent;
      border: none;
      color: var(--india-typography-primary);

      &:hover {
        color: var(--blue);
      }
    }
  }

  .wrapper {
    position: relative;
    max-width: calc(1200px + var(--padding-size) * 2);
    margin: 0 auto;
    padding: 0 var(--padding-size);
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .header-uk {
    background: var(--india-bg-primary);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 25px 0 18px;

    @media (max-width: 991px) {
      padding: 20px 0 20px;
    }

    &__logo {
      width: 270px;
      height: 52px;
      flex-shrink: 0;
      margin-right: 24px;

      img {
        width: 100%;
      }

      @media (max-width: 991px) {
        width: 210px;
        height: 40px;
      }
      @media (max-width: 767px) {
        width: 190px;
        height: 35px;
      }
    }

    a {
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
    }

    &__block {
      display: flex;
      align-items: center;

      @media (max-width: 991px) and (min-width: 767px) {
        width: 100%;
        justify-content: space-between;
      }
    }

    &__btn-wrapper {
      margin-left: 40px;

      @media (max-width: 767px) {
        display: none;
      }

      &--mobile {
        display: flex;
        justify-content: center;
        margin-left: 0;
        width: 100%;
        margin-top: 15px;
        text-align: center;

        @media (min-width: 767px) {
          display: none;
        }
      }
    }

    &__btn {
      font-weight: 400;
      padding: 10px 20px;

      &--mobile {
        max-width: 280px;
        width: 100%;
      }
    }

    &__contacts {
      display: flex;

      @media (max-width: 991px) {
        flex-direction: column;
      }
      @media (max-width: 767px) {
        flex-direction: row;
      }

      &--item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.11em;
        color: var(--india-typography-primary);

        @media (max-width: 991px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
        @media (max-width: 767px) {
          margin-bottom: 0;
          margin-right: 20px;
          span {
            display: none;
          }
        }

        img {
          width: 15px;
          height: 15px;
          margin-right: 10px;
          @media (max-width: 767px) {
            width: 20px;
            height: 20px;
            margin-right: 0;
          }
        }

        &:last-of-type {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }

    &--thank {
      .header-uk__content {
        justify-content: center;
      }

      .header-uk__logo {
        margin-right: 0;
      }
    }
  }

  .thank {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding-top: 94px;
    background: linear-gradient(90deg, #0b6ba7 0%, #96c15b 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-image: url("/local/html/img/thank-wave.svg");
      background-size: auto;
      background-repeat: no-repeat;
      background-position: bottom right;

      @media (max-width: 767px) {
        content: none;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
      max-width: 480px;
      padding: 130px 0;
      z-index: 7;

      @media (max-width: 991px) {
        max-width: 344px;
      }
      @media (max-width: 767px) {
        max-width: unset;
      }
    }

    &__title {
      margin-bottom: 20px;
      font-size: 50px;
      line-height: 55px;
      font-weight: 600;
      @media (max-width: 991px) {
        font-size: 40px;
        line-height: 44px;
      }
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 33px;
      }
    }

    &__text {
      margin-bottom: 40px;
      color: var(--india-typography-secondary);
      font-size: 24px;
      text-align: center;
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #0b6ba7;
      box-shadow: 0px 100px 80px rgba(2, 52, 69, 0.25), 0px 41.7776px 33.4221px rgba(2, 52, 69, 0.179714),
      0px 22.3363px 17.869px rgba(2, 52, 69, 0.149027), 0px 12.5216px 10.0172px rgba(2, 52, 69, 0.125),
      0px 6.6501px 5.32008px rgba(2, 52, 69, 0.100973), 0px 2.76726px 2.21381px rgba(2, 52, 69, 0.0702864);

      &:hover {
        box-shadow: none;
      }

      svg {
        margin-right: 10px;
      }

      @media (max-width: 767px) {
        width: 100%;
        max-width: 280px;
        align-self: center;
      }
    }
  }

  .hero {
    position: relative;
    padding-top: 94px;
    background: linear-gradient(90deg, #0b6ba7 0%, #96c15b 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 750px;
      padding: 125px 0 110px;
      z-index: 7;

      @media (max-width: 767px) {
        padding: 80px 0 320px;
        max-width: unset;
      }

      &--title, &--title p {
        margin-bottom: 55px;
        font-size: 50px;
        line-height: 55px;
        font-weight: 400;

        @media (max-width: 991px) {
          margin-bottom: 35px;
          font-size: 40px;
          line-height: 44px;
        }

        @media (max-width: 767px) {
          margin-bottom: 25px;
          font-size: 30px;
          line-height: 33px;
        }
      }

      &--title p {
        margin-bottom: 0;
      }

      &--text {
        margin-bottom: 45px;
        color: var(--india-typography-secondary);
        font-size: 24px;
        font-weight: 700;

        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
      }

      &--btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0b6ba7;
        -webkit-appearance: none;
        -webkit-box-shadow: 0px 100px 80px rgba(2, 52, 69, 0.25),
        0px 42px 33px rgba(2, 52, 69, 0.179714),
        0px 22px 18px rgba(2, 52, 69, 0.149027),
        0px 12px 10px rgba(2, 52, 69, 0.125),
        0px 7px 5px rgba(2, 52, 69, 0.100973),
        0px 3px 2px rgba(2, 52, 69, 0.0702864);

        box-shadow: 0px 100px 80px rgba(2, 52, 69, 0.25),
        0px 42px 33px rgba(2, 52, 69, 0.179714),
        0px 22px 18px rgba(2, 52, 69, 0.149027),
        0px 12px 10px rgba(2, 52, 69, 0.125),
        0px 7px 5px rgba(2, 52, 69, 0.100973),
        0px 3px 2px rgba(2, 52, 69, 0.0702864);

        &:hover {
          box-shadow: none;
        }

        svg {
          margin-right: 10px;
        }

        @media (max-width: 767px) {
          width: 100%;
          max-width: 280px;
          align-self: center;
        }
      }
    }

    &__bg {
      position: absolute;
      width: 1063px;
      height: 816px;
      right: 0;
      bottom: -260px;
      background-image: url("/local/html/img/london-tower-bridge.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 0;

      @media (max-width: 1600px) {
        right: -300px;
      }

      @media (max-width: 1140px) {
        bottom: -330px;
      }

      @media (max-width: 991px) {
        width: 700px;
        height: 538px;
        right: -140px;
        bottom: -190px;
      }

      @media (max-width: 767px) {
        width: 490px;
        height: 376px;
        right: 0;
        bottom: -100px;
      }

      @media (max-width: 420px) {
        right: -100px;
      }
    }

    &--bottom {
      padding-top: 120px;
      padding-bottom: 120px;
      overflow: hidden;
      background: none;

      .wrapper {
        &::after {
          position: absolute;
          content: "";
          width: 4013px;
          height: 1104px;
          top: -1225px;
          left: 0;
          transform: rotate(-45deg);
          background-image: url("/local/html/img/blue-lines.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;

          @media (max-width: 991px) {
            content: none;
          }
        }

        @media (max-width: 991px) {
          align-items: center;
        }
      }

      .hero__content {
        max-width: 530px;
        padding: 0;

        &--title, &--title p {
          font-weight: 600;
          font-size: 40px;
          line-height: 120%;
          color: #232B37;

          span {
            color: var(--light-green);
          }

          @media (max-width: 991px) {
            font-size: 30px;
          }

          @media (max-width: 767px) {
            font-size: 28px;
          }
        }

        &--title {
          margin-bottom: 40px;

          @media (max-width: 991px) {
            margin-bottom: 25px;
          }

          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }

        &--text {
          max-width: 510px;
          margin-bottom: 54px;
          font-weight: 400;
          color: #232B37;
          opacity: 0.8;

          @media (max-width: 991px) {
            max-width: 630px;
          }

          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        @media (max-width: 991px) {
          max-width: none;
          width: 100%;
        }
      }

      .hero__bg {
        max-width: 511px;
        width: 100%;
        height: auto;
        top: -50px;
        right: 100px;
        background-size: contain;

        .hero__bg-wrapper {
          position: relative;
          padding-top: 112%;
          width: 100%;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: contain;
          }
        }

        @media (max-width: 1100px) {
          right: 0;
        }

        @media (max-width: 991px) {
          position: static;
          display: none;
          margin-top: 50px;
        }

        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      @media (max-width: 991px) {
        padding-top: 80px;
        padding-bottom: 80px;
      }

      @media (max-width: 767px) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
    }
  }

  .advantages {
    margin-bottom: 100px;
    padding-top: 100px;

    &__link {
      display: inline-block;
      margin-top: 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;

      background: -moz-linear-gradient(15deg, #0b6ba7 0%, #96c15b 50%);
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, #0b6ba7), color-stop(50%, #96c15b));
      background: -webkit-linear-gradient(15deg, #0b6ba7 0%, #96c15b 50%);
      background: -o-linear-gradient(15deg, #0b6ba7 0%, #96c15b 50%);
      background: -ms-linear-gradient(15deg, #0b6ba7 0%, #96c15b 50%);
      background: linear-gradient(15deg, #0b6ba7 0%, #96c15b 50%);

      -webkit-background-clip: text;
      -moz-background-clip: text;
      -ms-background-clip: text;
      -o-background-clip: text;
      background-clip: text;

      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      -ms-text-fill-color: transparent;
      -o-text-fill-color: transparent;
      text-fill-color: transparent;
      opacity: 1;

      svg {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        stroke: #96c15b;
      }

      &:hover {
        text-decoration: none;
        background: -moz-linear-gradient(240deg, #0b6ba7 0%, #96c15b 110%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, #0b6ba7), color-stop(110%, #96c15b));
        background: -webkit-linear-gradient(240deg, #0b6ba7 0%, #96c15b 110%);
        background: -o-linear-gradient(240deg, #0b6ba7 0%, #96c15b 110%);
        background: -ms-linear-gradient(240deg, #0b6ba7 0%, #96c15b 110%);
        background: linear-gradient(240deg, #0b6ba7 0%, #96c15b 110%);

        -webkit-background-clip: text;
        -moz-background-clip: text;
        -ms-background-clip: text;
        -o-background-clip: text;
        background-clip: text;

        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        -ms-text-fill-color: transparent;
        -o-text-fill-color: transparent;
        text-fill-color: transparent;

        svg {
          stroke: #0b6ba7;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__top {
      max-width: 950px;
      margin-bottom: 100px;

      &--wide {
        max-width: 1160px;
        margin-bottom: 30px;

        .advantages__top--title {
          margin-bottom: 20px;
        }
      }

      &--title {
        margin-bottom: 40px;
        font-size: 40px;
        line-height: 48px;
        color: var(--india-typography-primary);

        span {
          color: var(--light-green);
        }

        @media (max-width: 991px) {
          margin-bottom: 25px;
          font-size: 35px;
          line-height: 42px;
        }

        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      &--text {
        font-size: 24px;
        line-height: 32px;
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 28px;
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 75px;

        &--wide {
            margin-bottom: 20px;
        }
      }
    }

    &__bottom {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: auto;
      grid-column-gap: 36px;
      grid-row-gap: 50px;

      @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 100px;
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 90px;
      }

      &--item {
        display: flex;
        flex-direction: column;

        .item {
          &__img {
            height: 80px;
          }

          &__title {
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 10px;

            @media (max-width: 767px) {
              font-size: 25px;
              line-height: 30px;
            }
          }

          &__text {
            font-size: 20px;
            line-height: 1.5;

            @media (max-width: 767px) {
              font-size: 16px;
            }
          }
        }
      }

      &--with-shadow {
        position: relative;
        padding: 78px 30px 27px;

        &::before,
        &::after {
          position: absolute;
          content: "";
          width: 120px;
          height: 120px;
          left: 46px;
          top: -50px;
          border-radius: 50%;
          background-color: #ffffff;
          box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.1);
          z-index: -1;
        }

        &::after {
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 0;
        }

        .item {
          &__img-wrapper {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 120px;
            left: 46px;
            top: -50px;
            border-radius: 50%;
            background-color: #ffffff;

            &::after,
            &::before {
              position: absolute;
              content: "";
              width: 90px;
              height: 90px;
              border-radius: 50%;
              background-image: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%);
              z-index: 1;
            }

            &::after {
              width: 88px;
              height: 88px;
              background-image: none;
              background-color: #ffffff;
              z-index: 2;
            }
          }

          &__img {
            height: 50px;
            z-index: 3;
            object-fit: contain;
          }

          &__title {
            font-weight: 700;
            font-size: 24px;
            line-height: 1.2;
            margin-bottom: 18px;
            color: #09101B;

            @media (max-width: 767px) {
              font-size: 18px;
            }
          }

          &__text {
            font-size: 16px;
            line-height: 22px;
            display: flex;
            flex-grow: 1;
          }
        }
      }

      &--four-in-row {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-row-gap: 40px;

        .item__img {
          margin-bottom: 15px;

          @media (max-width: 767px) {
            margin-bottom: 10px;
          }
        }

        .advantages__bottom--item {
          @media (max-width: 767px) {
            align-items: center;
          }
        }

        @media (max-width: 991px) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 767px) {
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    @media (max-width: 991px) {
      margin-bottom: 80px;
      padding-top: 60px;
    }

    @media (max-width: 767px) {
      margin-bottom: 60px;
    }
  }

  .platforms {
    position: relative;
    background-color: var(--india-bg-secondary);
    padding: 93px 0 80px;
    overflow: hidden;

    &__container {
      position: relative;
      display: flex;
      flex-direction: row;

      &::after {
        position: absolute;
        content: "";
        width: 4013px;
        height: 1104px;
        top: -590px;
        left: -500px;
        background-image: url("/local/html/img/blue-lines.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -1;

        @media (max-width: 991px) {
          top: -640px;
        }

        @media (max-width: 767px) {
          content: none;
        }
      }

      @media (max-width: 660px) {
        flex-direction: column-reverse;
        align-items: center;
      }
    }

    &__image-wrapper {
      min-width: 390px;
      margin-right: 86px;
      transform: translateY(-29px);

      .platforms__image-inner {
        position: relative;
        width: 100%;
        padding-top: 59%;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: contain;
          filter: drop-shadow(-19px 26px 30px rgba(11, 107, 167, 0.6))
                  drop-shadow(19px 13px 30px rgba(150, 193, 91, 0.6));
        }
      }

      @media (max-width: 991px) {
        min-width: 290px;
        margin-right: 40px;
        transform: none;
      }

      @media (max-width: 660px) {
        max-width: 280px;
        margin-right: 0;
        margin-top: 40px;
      }
    }

    &__heading {
      margin-bottom: 43px;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      color: #ffffff;

      span {
        color: var(--light-green);
      }

      @media (max-width: 991px) {
        margin-bottom: 25px;
        font-size: 35px;
        line-height: 42px;
      }

      @media (max-width: 767px) {
        margin-bottom: 15px;
        font-size: 28px;
        line-height: 34px;
      }
    }

    &__text {
      margin-bottom: 26px;
      font-size: 24px;
      line-height: 140%;
      color: #FFFFFF;

      @media (max-width: 991px) {
        margin-bottom: 20px;
        font-size: 18px;
      }

      @media (max-width: 767px) {
        margin-bottom: 15px;
        font-size: 16px;
      }
    }

    &__sub-text {
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: #FFFFFF;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    @media (max-width: 991px) {
      padding: 60px 0;
      margin: 80px 0 0;
    }

    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }

  .clientage {
    position: relative;
    background-color: var(--india-bg-secondary);
    padding: 100px 0;
    overflow: hidden;

    &__item {
      max-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: 100%;
        object-fit: contain;
      }

      @media (max-width: 991px) {
        max-height: 60px;
        justify-content: center;
      }

      @media (max-width: 767px) {
        max-height: unset;

        img {
          height: auto;
          max-height: unset;
          max-width: 120px;
          width: 100%;
        }

        &--risknowlogy {
          img {
            max-width: 140px;
          }
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;

      @media (max-width: 991px) {
        flex-direction: column;
      }

      &--text {
        margin-bottom: 45px;
        font-size: 38px;
        line-height: 120%;

        @media (max-width: 991px) {
          margin-bottom: 40px;
          font-size: 35px;
          line-height: 42px;
        }

        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      &--items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-row-gap: 40px;
        grid-column-gap: 40px;

        @media (max-width: 991px) {
          grid-template-columns: 1fr 1fr 1fr;
          grid-column-gap: 30px;
        }

        @media (max-width: 767px) {
          grid-column-gap: 20px;
          grid-row-gap: 40px;
        }

        @media (max-width: 600px) {
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    @media (max-width: 991px) {
      padding: 60px 0;
      margin: 0;
    }
  }

  .partners {
    background-color: var(--india-bg-secondary);
    padding: 100px 0;

    @media (max-width: 991px) {
      padding: 60px 0;
    }

    &__item {
      display: flex;
      justify-content: center;
      width: 180px;

      @media(max-width: 767px) {
        width: 130px;
        justify-content: flex-start;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      img {
        height: 56px;
        margin-bottom: 10px;
      }

      @media(max-width: 767px) {
        img {
          max-height: 40px;
          height: auto;
        }
      }
    }

    &__status {
      font-size: 20px;
      line-height: 1.5;
      color: #FFFFFF;
      opacity: 0.6;

      @media(max-width: 991px) {
        font-size: 16px;
      }

      @media(max-width: 767px) {
        font-size: 14px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;

      @media (max-width: 991px) {
        flex-direction: column;
      }

      &--text {
        margin-bottom: 40px;

        @media (max-width: 991px) {
          font-size: 35px;
          line-height: 42px;
        }

        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      &--items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-row-gap: 50px;
        grid-column-gap: 50px;

        @media (max-width: 1199px) {
          grid-template-columns: 1fr 1fr 1fr;
          grid-column-gap: 40px;
        }

        @media (max-width: 767px) {
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 20px;
          grid-row-gap: 30px;
          justify-items: center;
        }
      }
    }
  }

  .modules {
    margin-bottom: 100px;

    &__title {
      max-width: 1064px;
      margin-bottom: 54px;
      color: var(--india-typography-primary);

      span {
        color: var(--light-green);
      }

      @media (max-width: 991px) {
        margin-bottom: 40px;
        font-size: 35px;
        line-height: 42px;
      }
      @media (max-width: 767px) {
        padding-top: 30px;
        margin-bottom: 30px;
        font-size: 28px;
        line-height: 34px;
      }
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 36px;
      grid-row-gap: 35px;
      @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
      }
      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      padding: 30px 30px 40px;
      border: 1px solid var(--blue);

      &:nth-child(2n) {
        border-color: var(--light-green);
      }

      @media (max-width: 767px) {
        padding: 30px 20px 40px;
      }

      &--image {
        margin-bottom: 20px;
        width: 80px;
        height: 80px;
      }

      &--title {
        margin-bottom: 10px;
        color: var(--india-typography-primary);
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }

      &--text {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
        opacity: 0.8;
      }
    }

    @media (max-width: 991px) {
      margin-bottom: 80px;
    }

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .review {
    padding-top: 150px;
    padding-bottom: 150px;
    @media (max-width: 991px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__top {
      padding: 0 50px 64px;
      @media (max-width: 767px) {
        padding: 0 0 30px;
      }
    }

    &__info {
      display: flex;
      justify-content: flex-start;

      &--inner {
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &--avatar {
        width: 100px;
        height: 100px;
        margin-right: 30px;
        flex-shrink: 0;
        @media (max-width: 767px) {
          width: 80px;
          height: 80px;
        }
      }

      &--text {
        display: flex;
        flex-direction: column;
        margin-right: 40px;
        justify-content: center;

        .name {
          font-size: 30px;
          line-height: 36px;
          margin-bottom: 5px;
        }

        .post {
          font-size: 20px;
          line-height: 24px;
          opacity: 0.4;
        }
      }

      &--company {
        width: 100px;
        height: 100px;
        @media (max-width: 767px) {
          width: 50px;
          height: 50px;
        }
      }
    }

    &__bottom {
      position: relative;
      background-image: url("/local/html/img/quotes-up.svg"), url("/local/html/img/quotes-down.svg");
      background-color: var(--india-bg-secondary);
      background-position: 5% 10%, 95% 90%;
      background-repeat: no-repeat;
      background-size: auto;

      &--blockquote {
        color: var(--india-typography-secondary);
        max-width: 946px;
        font-size: 40px;
        padding: 80px 40px 90px;
        line-height: 52px;
        margin: auto;
        @media (max-width: 991px) {
          font-size: 35px;
          line-height: 42px;
          padding: 60px 40px 60px;
        }
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 28px;
          font-weight: 400;
          padding: 60px 30px 60px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        width: 100px;
        height: 30px;
        top: -28px;
        left: 50px;
        background: url("/local/html/img/review-arrow.svg") center no-repeat;
        background-size: contain;
        @media (max-width: 767px) {
          left: 0;
          width: 80px;
          height: 24px;
          top: -22px;
        }
      }
    }
  }

  .about {
    position: relative;
    padding: 100px 0 100px;
    background: linear-gradient(90deg, #0b6ba7 0%, #96c15b 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;

    @media (max-width: 991px) {
      padding: 60px 0;
    }

    &__desc {
      max-width: 1020px;
      margin-bottom: 70px;

      @media (max-width: 991px) {
        margin-bottom: 40px;
      }

      &--title {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 20px;
        @media (max-width: 991px) {
          font-size: 35px;
          line-height: 42px;
        }
        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      &--text, &--text p {
        margin-bottom: 20px;
        color: var(--india-typography-secondary);
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        opacity: 0.8;

        &:last-of-type {
          margin-bottom: 0;
        }

        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }

    &__items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 36px;
      grid-row-gap: 36px;

      @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }
    }

    &__item {
      padding: 25px 40px 35px;
      background: rgba(#ffffff, 0.05);
      border: 1px solid #FFFFFF;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18);

      &--value {
        width: min-content;
        font-size: 70px;
        line-height: 1.2;
        color: #ffffff;
      }

      &--text {
        color: var(--india-typography-secondary);
        font-size: 24px;
        line-height: 1.4;
        opacity: 0.8;
      }
    }

    &__link-wrapper {
      margin-top: 54px;

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }

    &__arrow-link {
      position: relative;
      padding-right: 34px;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;

      svg {
        position: absolute;
        right: 0;
        top: 4px;
        transition: all 0.3s ease;
      }

      &:hover {
        color: var(--light-blue);
        text-decoration: none;

        svg {
          right: -8px;
        }

        svg > path {
          stroke: var(--light-blue);
        }
      }
    }
  }

  .footer-uk {
    background-color: #1e2530;
    padding: 60px 0 30px;

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      padding-bottom: 50px;

      @media (max-width: 991px) {
        flex-direction: column;
      }
    }

    &__logo {
      flex-shrink: 0;
      width: 230px;
      height: 44px;

      img {
        width: 100%;
      }

      @media (max-width: 991px) {
        margin-bottom: 40px;
      }

      @media (max-width: 767px) {
        width: 100%;
        height: auto;
      }
    }

    &__contacts {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 30px;
      grid-row-gap: 15px;
      width: calc(100% - 325px);

      @media (max-width: 991px) {
        width: 100%;
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }

      &--block {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-row-gap: 15px;
      }

      &--item {
        .item {
          &--title {
            font-size: 18px;
            font-weight: 600;
            color: var(--india-typography-secondary);
            margin-bottom: 10px;
          }

          &--text, &--text p {
            margin-bottom: 0;
            color: var(--india-typography-secondary);
            opacity: 0.7;
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }

    &__bottom {
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.4);
      font-weight: 700;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      &--rights {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }
      }
    }

    &__authority {
      margin-bottom: 0;
    }

    &__reserved {
      margin: 0 30px 0 0;

      @media (max-width: 767px) {
        margin: 5px 0 5px;
      }
    }

    &__policy {
      color: rgba(255, 255, 255, 0.4);
    }

    &__download {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      justify-self: flex-end;
      width: 210px;
      height: 40px;
      padding: 0 10px;
      white-space: nowrap;
      background-color: #1e2530;
      font-weight: 700;
      font-size: 16px;
      color: #ffffff;

      &:hover {
        color: #ffffff;
        text-decoration: none;

        &::before {
          background-image: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%);
        }
      }

      &::before {
        position: absolute;
        content: "";
        left: -2px;
        top: -2px;
        right: -2px;
        bottom: -2px;
        border-radius: 3px;
        background-image: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%);
        z-index: -1;
      }

      @media (max-width: 991px) {
        justify-self: auto;
      }
    }
  }

  .ukForm.mainForm {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#000000, 0.85);

    .mainForm__content {
      display: flex !important;
      max-width: 360px;
      margin: auto;
      min-height: auto;
    }

    .mainForm__title {
      background: var(--main-gradient);
      margin-bottom: 0;
      padding: 30px 30px 20px;
      color: white;
      max-width: 360px;
      width: 100%;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      flex-direction: column;

      &--subtitle {
        margin-top: 8px;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .main-form-close {
      top: 10px;
      right: 10px;
    }

    form {
      margin-top: 0;
      background: white;
      width: 100%;
      max-width: 360px;
      padding: 25px 30px 50px;

      input {
        border: 1px solid #09101b;
        border-radius: 3px;
        color: var(--india-typography-primary);
      }

      .placeholder {
        color: var(--india-typography-primary);
        opacity: 0.4;

        &.error {
          color: var(--red);
          opacity: 1;
        }
      }

      .form__btn {
        font-size: 16px;
        line-height: 20px;
      }

      .error_submit {
        padding: 5px;
        margin-top: 16px;
      }
    }
  }

  .modal-success {
    &__content {
      background-color: white;
      position: relative;
      padding: 40px 30px 50px;
      max-width: 360px;
      margin: auto;

      .title {
        color: var(--india-typography-primary);
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 5px;
        font-weight: 600;
        text-align: left;
      }

      .main-form-close {
        top: 10px;
        right: 10px;
      }

      .form__btn {
        width: 100%;
        margin-top: 24px;
      }
    }

    &.active {
      background-color: rgba(#000000, 0.85);
    }
  }
}

.policy-uk {
  padding-top: 140px;
  padding-bottom: 100px;

  @media (max-width: 767px) {
    padding-top: 230px;
  }

  &__title {
    color: var(--india-typography-primary);
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
    font-weight: 600;

    @media (max-width: 991px) {
      font-size: 35px;
      line-height: 42px;
    }

    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__content {
    &--text {
      font-size: 24px;
      line-height: 32px;

      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}
