[data-utility-slider] {
    .swiper-wrapper {
        align-items: flex-end;
    }

    .swiper-slide {
        width: 66%;
    }

    .utility-text {
        position: relative;
        top: 1px;
        padding: 20px 40px;
        height: 80%;
        @media (max-width: 1200px) {
            padding: 10px 10px;
            font-size: 14px;
        }
        @media (max-width: 767px) {
            top: 0;
        }
    }

    &.light-blue {
        .utility-text {
            border: 1px solid var(--light-blue);
        }
    }

    &.white {
        .utility-text {
            border: 1px solid #fff;
            color: #000 !important
        }
        .swiper-slide {
            background-color: #fff;
            color: #000 !important;
        }
        .swiper-pagination-bullet {
            color: #fff;
            opacity: 1;
            &:after{
                background-color: #25A2CA !important;
            }
        }
        .swiper-pagination-bullet-active {
            color: var(--light-blue);
        }
    }

    &.dark-green {
        .utility-text {
            border: 1px solid var(--dark-green);
        }
    }

    &.green {
        .utility-text {
            border: 1px solid var(--green);
        }
    }

    &.light-green {
        .utility-text {
            border: 1px solid var(--light-green);
        }
    }
}

[data-utility-pagintaion] {
    min-height: 250px;
    width: 26% !important;
    right: auto;
    left: 0;

    .swiper-pagination-bullet {
        opacity: 1;
        font-weight: 600;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        color: var(--light-blue);

        .dark-green & {
            color: var(--dark-green);
        }

        .green & {
            color: var(--dark-green);
        }

        .light-green & {
            color: var(--light-green);
        }

        &:after {
            visibility: visible;
            opacity: 1;
        }
    }

    .swiper-pagination-bullet {
        display: block;
        background: none;
        font-size: 1.2rem;
        text-align: left;
        width: 100%;
        margin-bottom: 25px !important;
        margin-left: 0 !important;
        height: 25px !important;
        margin-top: 15px !important;
        position: relative;

        &:focus {
            outline-color: transparent !important;
        }

        &:after {
            content: '';
            display: block;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -75px;
            width: 65px;
            height: 1px;
            background-color: var(--dark-green);
            transition: all .3s ease;

            .light-blue & {
                background-color: var(--light-blue);
            }

            .dark-green & {
                background-color: var(--dark-green);
            }

            .green & {
                background-color: var(--green);
            }

            .light-green & {
                background-color: var(--light-green);
            }
        }
    }
}

[data-utility-slider].swiper-container-vertical > .swiper-pagination-bullets {
    top: 45%;
}
@media (max-width: 1200px) {
    [data-utility-pagintaion] .swiper-pagination-bullet {
        font-size: 1rem;
        margin-bottom: 15px !important;
        &:after {
            right: -55px;
        }
    }
}

@media (max-width: 992px) {
    [data-utility-slider] {
        display: none;
    }
    [data-utility-pagintaion] .swiper-pagination-bullet {
        font-size: 1rem;
        margin-bottom: 25px !important;
    }
    [data-utility-pagintaion] .swiper-pagination-bullet:after {
        width: 60px;
    }
}

@media (max-width: 767px) {
    [data-utility-pagintaion] {
        width: 100% !important;
        display: flex;
        min-height: auto;
        top: 0;
    }
    [data-utility-pagintaion] .swiper-pagination-bullet {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        height: 30% !important;
    }
    [data-utility-pagintaion] .swiper-pagination-bullet:after {
        display: none;
    }
    [data-utility-slider].swiper-container-vertical > .swiper-pagination-bullets {
        top: 10%;
    }
    [data-utility-slider].swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
        height: auto !important;
    }
    [data-utility-slider] {
        padding-top: 75px;
    }
    [data-utility-slider] .swiper-slide {
        width: 100%;
    }
    [data-utility-slider] .utility-text {
        padding: 10px 20px;
    }
}

@media (max-width: 1190px) {
    [data-utility-pagintaion] .swiper-pagination-bullet:after {
        right: -65px;
    }
}

[data-tabs-slider] {
    overflow: hidden;
}
.tabs-slider {
    overflow: hidden;
    position: relative;
    &.no-before {
        &:after {
            display: none;
        }
    }
    /*&:after {
        content: '';
        display: block;
        width: 50px;
        height: 38px;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 100;
    }*/
    &__arrow {
        width: 50px;
        height: 38px;
        position: absolute;
        top: 0;
        right: -2px;
        z-index: 2;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        border-radius: 0 19px 19px 0;
        display: none;
        svg {
            display: none;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &-prev {
            left: 5px;
            transform: translate(-50%, -50%) scale(-1);
        }
        &-next {
            right: -1px;
        }
    }
    &__plug {
        width: 120px;
        height: 0;
    }
    &__link {
        &:hover {
            text-decoration: none;
        }

    }

    &__links {
        margin-bottom: 20px;
        display: inline-flex !important;
        width: auto !important;
        position: relative;

        padding-bottom: 12px;
        /*&:after {
            content: '';
            display: block;

            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(244, 244, 245, .8);
            z-index: 0;
            border-radius: 30px;
        }*/

        .swiper-wrapper {
            z-index: 2;
        }

        &>.swiper-scrollbar {
            /* Костыль: отключаем скролл на десктопе, включаем на планшете */
            display: none;
            height: 2px;
            bottom: 0;
            background: var(--light-gray);
            .swiper-scrollbar-drag {
                background: rgba(0,0,0,.2);
            }
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 38px;
            border-radius: 19px;
            background-color: rgba(244, 244, 245, .8);
        }

        @media (max-width: 991px) {
            &>.swiper-scrollbar {
                display: unset;
            }
        }

        @media (max-width: 767px) {
            width: 100% !important;
            display: flex !important;
        }
    }

    &__list {
        border-radius: 50px;
        //width: auto !important;
    }

    &.dark-green {
        .tabs-slider__link {
            &.active {
                background-color: var(--dark-green);
            }
        }
    }

    &.green {
        .tabs-slider__link {
            &.active {
                background-color: var(--green);
            }
        }
    }

    &.light-blue,
    &.white {
        .tabs-slider {
            &__link {
                &.active {
                    background-color: var(--light-blue);
                }
            }
            &__links {
                &>.swiper-scrollbar {
                    .swiper-scrollbar-drag {
                        background: rgba(0,0,0,0.55);
                    }
                }
            }
        }
    }

    &.light-green {
        .tabs-slider__link {
            &.active {
                background-color: var(--light-green);
            }
        }
    }

    &__el {
        width: auto;
        position: relative;
        padding: 8px 0;
        //background-color: rgba(244, 244, 245, .8);
        //background-color: #f6f6f7;
        border: none;
        &:first-child {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
        }
        &:last-child {
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
        }
    }

    &__link {
        font-size: 16px;
        padding: 9px 20px;
        color: rgba(26, 28, 27, 0.4) !important;
        border-radius: 50px;
        position: relative;
        z-index: 2;

        span {
            position: relative;
            z-index: 2;
        }
        &.active {
            color: #fff !important;
            text-decoration: none !important;

        }
    }

    &__item {
        display: none;
        &.active {
            display: block;
        }
    }
    @media (max-width: 961px) {
        &__arrow {
            display: block;
        }
    }
}

.swiper-slide {
    flex-shrink: 0 !important;
}

.nx-list-slider__slider {
    .swiper-slide {
        max-width: none !important;
        flex:none;
    }
}
