@media (max-width: 1200px) {
  .portfolio__grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 210px);
  }

  .contain-1200:before {
    background-size: contain !important;
  }
}

.partners-main {
  margin-bottom: -15px;

  &__item {
    margin-bottom: 20px !important;
    width: auto;
    justify-content: space-between;
  }

  &__body {
    margin-bottom: 10px;
  }
}

@media (max-width: 992px) {
  .partners-main {
    &__item {
      width: 50%;
    }
  }
  .clients-img-list img {
    width: calc(100% / 6);
  }

  .vendors i {
    display: none;
  }

  .vendors__item {
    border-bottom: 1px solid var(--line-white-20);
  }

  .vendors__item:nth-child(odd) {
    border-right: 1px solid var(--line-white-20);
  }

  .portfolio__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 225px);
  }

  .about-main__aside > div {
    width: 35%;
    margin-right: 2rem;
  }

  .partners-main__items {
    justify-content: flex-start !important;
  }

  .partners-main__item {
    margin-bottom: 3rem;
  }

  .portfolio-white-block {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .rating-row__images {
    justify-content: flex-start !important;
    margin-top: 2rem;
  }
}

@media(max-width: 991px) {
  .about-main__aside {
    border-top: 1px solid var(--white_opacity);
    border-left: none;
    padding-left: 15px !important;
    display: flex;
    padding-top: 3rem;
    margin-top: 2rem;
  }
}

.portfolio-white-block {
  .arrow-link {
    color: #1A1C1B;
  }
}

.portfolio-white-block {
  .arrow-link {
    color: #1A1C1B;
  }
}

@media(max-width: 876px) {

  .main-form-close {
    top: 1rem !important;
    right: 1rem !important;
  }
}

@media (max-width: 768px) {
  main > div:first-child:not(.promo):not(.news-head):not(.error-page):not(.portfolio):not(.career):not(.vendors-block):not(.news-wr) {
    height: auto !important;
    min-height: 100vh;
    padding-top: 180px !important;
    padding-bottom: 50px !important;
  }

  #contact-page {
    padding-top: 0 !important;
  }

  .clients-img-list img {
    width: calc(100% / 5);
  }

  body {
    overflow-x: hidden;
  }

  .partners-main__item {
    width: 100%;
    justify-content: flex-start;
  }

  .partners-main__body {
    height: 40px;
  }

  .services-main-item__info img {
    display: block;
  }

  .promo-slider__control div:not(.swiper-pagination) {
    display: none;
  }

  .swiper-pagination {
    margin-right: 0 !important;
  }

  .swiper-pagination-bullet {
    background: transparent;
    border: 1px solid #fff;
    margin-right: .5rem;
    display: inline-block;
  }

  .swiper-pagination-bullet:last-child {
    margin-right: 0;
  }

  .swiper-pagination-bullet-active {
    background: #fff;
  }

  .fake-div:first-child {
    margin-bottom: 2rem;
  }

  .promo__CTA {
    padding: .5rem .75rem !important;
  }

  .promo-slider__info {
    padding: 0;
  }

  .portfolio__grid {
    display: flex;
    flex-direction: column;
  }

  .portfolio-grid__item {
    height: 350px;
    margin-bottom: 1rem;
  }

  .partners-main__item img {
    object-fit: contain;
    width: auto;
    height: 100%;
  }

  .partners-main__item {
    width: 50%;
    padding-right: 2rem;
    margin-bottom: 3rem;
    margin-right: 0;
  }

  .clients-main__item {
    width: 25%;
  }

  .depart-main-item__inner p {
    visibility: visible;
    opacity: 1;
  }

  .vendors .depart-main-item__inner {
    background: transparent;
  }

  .main-gradient-block {
    margin: 0 15px 0 15px;
    width: calc(100% - 30px);
  }

  .services-block__item {
    padding-top: 50px !important;
    padding-bottom: 50px !important;

    .col-md-6.col-12.pl-0.pr-5 {
      padding-right: 0 !important;
    }
  }

  .services-block__list {
    margin-top: 35px;
    padding-right: 0 !important;
  }

  .services-block__header {
    display: none;
  }
}

@media (max-height: 760px) {
  main > div:first-child:not(.promo, .contact-page, .news-head, .error-page, .portfolio, .career, .vendors-block, .news-wr, .services__top--corp, .services__top--bitrix) {
    padding-top: 200px !important;
  }
}

@media (max-width: 576px) {
  main > div:first-child:not(.promo):not(.news-head):not(.error-page):not(.portfolio):not(.vendors-block):not(.news-wr, .services__top--corp) {
    height: auto !important;
    min-height: 100vh;
    padding-top: 32px;
    padding-bottom: 32px !important;
  }
  .mainForm form {
    width: 260px;
    margin: 0 auto;
  }
  .mainForm__content {
    display: block;
    padding-top: 50px;
  }
  .mainForm__title {
    margin-bottom: 6px;
  }
  .mainForm__text {
    margin-bottom: 10px;
  }

  .clients-img-list img {
    width: calc(100% / 3);
    transform: scale(.8);
  }

  .services-main__item {
    min-height: 300px;
    border: 1px solid rgba(255, 255, 255, .1);
    background: rgba(34, 41, 46, 0.4);
  }

  .depart-main-item__inner, .services-main__item, .portfolio-grid-item__inner {
    padding: 32px !important;
  }

  .depart-main__item, .services-main__item {
    margin-bottom: 1rem;
  }

  .depart-main__item {
    height: 350px;
  }

  .vendors__item {
    border-right: none !important;
  }

  .rating-row__images {
    grid-template-columns: 50% 50%;
  }

  .contain-1200 {
    height: 200px !important;
  }
}

@media (max-width: 480px) {
  .portfolio-grid__item {
    height: 250px;
  }

  .clients-main__items {
    justify-content: flex-start !important;
  }

  .clients-main__item {
    width: 33%;
  }

  .main-gradient-block {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .standard-padding, main > div.standard-padding:first-child {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .standard-pt, main > div.standard-pt:first-child {
    padding-top: 32px !important;
  }
}

@media (max-width: 319px) {
  .container, .row, [class^="col-"] {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .main-gradient-block {
    width: 100% !important;
  }

  .smkrf-banner__content, .smkrf-banner__content {
    width: 280px !important;
    max-width: 280px !important;
  }

  .b-portfolio-rewards__item {
    margin: 0 !important;
  }

  .container img {
    max-width: 100%;
  }
}

@media (max-width: 279px) {
  body {
    min-width: 320px !important;
  }
}

.pf-filter__list {
  display: inline-flex;
  border-radius: 50px;

  .filter-btn {
    color: rgba(26, 28, 27, 0.4);
    font-size: 16px !important;
    padding: 8px 20px;

    &.active-filter,
    &.pf-filter-btn-active {
      color: #fff !important;
      background-color: #000;
      border-radius: 50px;
    }
  }

}

.pf-filter__list-top {
  .filter-btn {
    font-size: 21px;
    line-height: 27px;
    position: relative;
    padding: 0;

    &.active-filter,
    &.pf-filter-btn-active {
      color: var(--deep-gray);

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -16px;
        left: 0;
        right: 0;
        height: 3px;
        background-color: #000;
      }

      @media(max-width: 767px) {
        &:after {
          display: none;
        }
      }
    }
  }
}

.news-list-top {
  .filter-btn {
    &.pf-filter-btn-active {
      &:after {
        display: none;
      }
    }
  }
}
