.india {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--india-bg-primary);
  padding: 0;
  color: var(--india-typography-primary);

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    margin: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .btn {
    text-decoration: none;
    outline: none;
    border-radius: 3px;
    padding: 16px 29px;
    white-space: nowrap;
    transition: all linear 0.2s;
    cursor: pointer;
    text-align: center;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      outline: none;
    }

    &--primary {
      font-weight: 600;
      color: white !important;
      background: var(--main-gradient);
      border: none;
      transition: all linear 0.2s;

      &:hover {
        color: var(--india-bg-primary) !important;
        background: linear-gradient(89.85deg, #96c15b 0.1%, #0b6ba7 99.87%);
      }
    }

    &--secondary {
      background: var(--india-bg-primary);
      border: none;
      font-weight: 600;

      &:hover {
        background: rgba(white, 0.9);
      }

      &:disabled {
        opacity: 0.2;
        border: 2px solid #ffffff;
        background: transparent;
      }

      &-text {
        color: var(--india-bg-primary);
        background: var(--main-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &--hollow {
      font-weight: 600;
      background: transparent;
      border: 2px solid white;
      color: white !important;

      &:hover {
        background: white;

        .btn--hollow-text {
          color: var(--india-bg-primary);
          background: var(--main-gradient);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &--link {
      background: transparent;
      border: none;
      color: var(--india-typography-primary);

      &:hover {
        color: var(--blue);
      }
    }
  }

  .wrapper {
    position: relative;
    max-width: calc(1200px + var(--padding-size) * 2);
    margin: 0 auto;
    padding: 0 var(--padding-size);
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .header-india {
    background: var(--india-bg-primary);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 25px 0 18px;

    @media (max-width: 991px) {
      padding: 20px 0 20px;
    }

    &__logo {
      width: 270px;
      height: 52px;
      flex-shrink: 0;
      margin-right: 24px;

      img {
        width: 100%;
      }

      @media (max-width: 991px) {
        width: 210px;
        height: 40px;
      }
      @media (max-width: 767px) {
        width: 190px;
        height: 35px;
      }
    }

    a {
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
    }

    &__block {
      display: flex;
      align-items: center;

      @media (max-width: 991px) and (min-width: 767px) {
        width: 100%;
        justify-content: space-between;
      }
    }

    &__btn-wrapper {
      margin-left: 40px;

      @media (max-width: 767px) {
        display: none;
      }

      &--mobile {
        display: flex;
        justify-content: center;
        margin-left: 0;
        width: 100%;
        margin-top: 15px;
        text-align: center;

        @media (min-width: 767px) {
          display: none;
        }
      }
    }

    &__btn {
      font-weight: 400;
      padding: 10px 20px;

      &--mobile {
        max-width: 280px;
        width: 100%;
      }
    }

    &__contacts {
      display: flex;

      @media (max-width: 991px) {
        flex-direction: column;
      }
      @media (max-width: 767px) {
        flex-direction: row;
      }

      &--item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--india-typography-primary);

        @media (max-width: 991px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
        @media (max-width: 767px) {
          margin-bottom: 0;
          margin-right: 20px;
          span {
            display: none;
          }
        }

        img {
          width: 15px;
          height: 15px;
          margin-right: 10px;
          @media (max-width: 767px) {
            width: 20px;
            height: 20px;
            margin-right: 0;
          }
        }

        &:last-of-type {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }

    &--thank {
      .header-india__content {
        justify-content: center;
      }

      .header-india__logo {
        margin-right: 0;
      }
    }
  }

  .thank {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding-top: 94px;
    background: linear-gradient(90deg, #0b6ba7 0%, #96c15b 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-image: url("/local/html/img/thank-wave.svg");
      background-size: auto;
      background-repeat: no-repeat;
      background-position: bottom right;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
      max-width: 480px;
      padding: 130px 0;
      z-index: 7;

      @media (max-width: 991px) {
        max-width: 344px;
      }
      @media (max-width: 767px) {
        max-width: unset;
      }
    }

    &__title {
      margin-bottom: 20px;
      font-size: 50px;
      line-height: 55px;
      font-weight: 600;
      @media (max-width: 991px) {
        font-size: 40px;
        line-height: 44px;
      }
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 33px;
      }
    }

    &__text {
      margin-bottom: 40px;
      color: var(--india-typography-secondary);
      font-size: 24px;
      text-align: center;
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #0b6ba7;
      box-shadow: 0px 100px 80px rgba(2, 52, 69, 0.25), 0px 41.7776px 33.4221px rgba(2, 52, 69, 0.179714),
        0px 22.3363px 17.869px rgba(2, 52, 69, 0.149027), 0px 12.5216px 10.0172px rgba(2, 52, 69, 0.125),
        0px 6.6501px 5.32008px rgba(2, 52, 69, 0.100973), 0px 2.76726px 2.21381px rgba(2, 52, 69, 0.0702864);

      &:hover {
        box-shadow: none;
      }

      svg {
        margin-right: 10px;
      }

      @media (max-width: 767px) {
        width: 100%;
        max-width: 280px;
        align-self: center;
      }
    }
  }

  .hero {
    position: relative;
    padding-top: 94px;
    background: linear-gradient(90deg, #0b6ba7 0%, #96c15b 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 480px;
      padding: 105px 0 145px;
      z-index: 7;
      @media (max-width: 991px) {
        max-width: 344px;
      }
      @media (max-width: 767px) {
        padding: 80px 0 344px;
        max-width: unset;
      }

      &--title {
        margin-bottom: 20px;
        font-size: 50px;
        line-height: 55px;
        font-weight: 600;
        @media (max-width: 991px) {
          font-size: 40px;
          line-height: 44px;
        }
        @media (max-width: 767px) {
          font-size: 30px;
          line-height: 33px;
        }
      }

      &--text {
        margin-bottom: 40px;
        color: var(--india-typography-secondary);
        font-size: 24px;
      }

      &--btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0b6ba7;
        -webkit-appearance: none;
        -webkit-box-shadow: 0px 100px 80px rgba(2, 52, 69, 0.25),
                            0px 42px 33px rgba(2, 52, 69, 0.179714),
                            0px 22px 18px rgba(2, 52, 69, 0.149027),
                            0px 12px 10px rgba(2, 52, 69, 0.125),
                            0px 7px 5px rgba(2, 52, 69, 0.100973),
                            0px 3px 2px rgba(2, 52, 69, 0.0702864);

        box-shadow: 0px 100px 80px rgba(2, 52, 69, 0.25),
                    0px 42px 33px rgba(2, 52, 69, 0.179714),
                    0px 22px 18px rgba(2, 52, 69, 0.149027),
                    0px 12px 10px rgba(2, 52, 69, 0.125),
                    0px 7px 5px rgba(2, 52, 69, 0.100973),
                    0px 3px 2px rgba(2, 52, 69, 0.0702864);

        &:hover {
          box-shadow: none;
        }

        svg {
          margin-right: 10px;
        }

        @media (max-width: 767px) {
          width: 100%;
          max-width: 280px;
          align-self: center;
        }
      }
    }

    &__bg {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("/local/html/img/india-hero-bg.png");
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center bottom;

      @media (max-width: 767px) {
        background-size: cover;
        height: 42%;
        background-position: 81% bottom;
        top: unset;
      }

      @media (max-width: 600px) {
        background-position: 71% bottom;
      }

      @media (max-width: 475px) {
        background-position: 61% bottom;
      }
    }
  }

  .advantages {
    padding-top: 100px;

    @media (max-width: 991px) {
      padding-top: 60px;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__top {
      max-width: 891px;
      margin-bottom: 60px;

      @media (max-width: 991px) {
        margin-bottom: 40px;
      }

      &--title {
        color: var(--india-typography-primary);
        font-size: 40px;
        line-height: 48px;
        font-weight: 600;
        margin-bottom: 20px;

        @media (max-width: 991px) {
          font-size: 35px;
          line-height: 42px;
        }

        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      &--text {
        font-size: 24px;
        line-height: 32px;
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }

    &__bottom {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: auto;
      grid-column-gap: 36px;
      grid-row-gap: 50px;

      @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 40px;
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 40px;
      }

      &--item {
        display: flex;
        flex-direction: column;

        .item {
          &__img {
            height: 60px;
            margin-bottom: 30px;
          }

          &__title {
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 10px;

            @media (max-width: 767px) {
              font-size: 25px;
              line-height: 30px;
            }
          }

          &__text {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }

      &--four-in-row {
        grid-template-columns: 1fr 1fr 1fr 1fr;

        .item__img {
          margin-bottom: 15px;
        }

        @media (max-width: 991px) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 767px) {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .video-section {
    padding-top: 100px;

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__container {
      display: flex;
    }

    &__bottom {
      padding: 42px 67px 60px 60px;
      background: var(--main-gradient);
      position: relative;
      display: flex;

      @media (max-width: 767px) {
        padding: 30px 20px 40px;
        overflow: hidden;
      }

      &--content {
        position: relative;
        width: 100%;
        z-index: 5;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }
      }

      &--text {
        color: var(--india-typography-secondary);
        font-size: 40px;
        line-height: 48px;
        font-weight: 600;
        max-width: 700px;
        @media (max-width: 1197px) {
          font-size: 24px;
          line-height: 32px;
        }
        @media (max-width: 767px) {
          margin-bottom: 90px;
          font-weight: 400;
        }
      }

      &--btn {
        margin-right: 43px;
        margin-left: 80px;
        @media (max-width: 767px) {
          margin-right: 0;
          margin-left: 0;
        }
      }

      &--image {
        z-index: 4;
        position: absolute;
        bottom: 0;
        right: 53px;
        @media (max-width: 767px) {
          width: 266px;
          bottom: -50px;
          right: 50%;
          transform: translateX(50%);
        }
      }
    }
  }

  .clientage {
    background-color: var(--india-bg-secondary);
    padding: 118px 0;
    margin: 150px 0 0;
    background-image: url("/local/html/img/clientage-bg.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    @media (max-width: 991px) {
      padding: 60px 0;
      margin: 80px 0 0;
    }

    &__item {
      max-width: 130px;
      max-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      display: flex;
      align-items: center;
      @media (max-width: 991px) {
        flex-direction: column;
      }

      &--text {
        margin-right: 60px;
        max-width: 528px;
        @media (max-width: 991px) {
          max-width: unset;
          margin-right: 0;
          margin-bottom: 40px;
          font-size: 35px;
          line-height: 42px;
        }
        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      &--items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-row-gap: 50px;
        grid-column-gap: 50px;
        @media (max-width: 991px) {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          grid-column-gap: 40px;
        }
        @media (max-width: 767px) {
          grid-template-columns: 1fr 1fr 1fr;
          grid-column-gap: 30px;
          grid-row-gap: 30px;
        }
      }
    }
  }

  .modules {
    padding-top: 150px;
    @media (max-width: 991px) {
      padding-top: 80px;
    }

    &__title {
      color: var(--india-typography-primary);
      margin-bottom: 60px;
      @media (max-width: 991px) {
        margin-bottom: 40px;
        font-size: 35px;
        line-height: 42px;
      }
      @media (max-width: 767px) {
        margin-bottom: 30px;
        font-size: 28px;
        line-height: 34px;
      }
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 36px;
      grid-row-gap: 27px;
      @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
      }
      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      padding: 30px 30px 40px;
      border: 1px solid var(--blue);
      @media (max-width: 767px) {
        padding: 30px 20px 40px;
      }

      &--image {
        margin-bottom: 20px;
        width: 50px;
        height: 50px;
      }

      &--title {
        margin-bottom: 10px;
        color: var(--india-typography-primary);
        font-size: 20px;
        line-height: 24px;
      }

      &--text {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
      }
    }
  }

  .review {
    padding-top: 150px;
    padding-bottom: 150px;
    @media (max-width: 991px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__top {
      padding: 0 50px 64px;
      @media (max-width: 767px) {
        padding: 0 0 30px;
      }
    }

    &__info {
      display: flex;
      justify-content: flex-start;

      &--inner {
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &--avatar {
        width: 100px;
        height: 100px;
        margin-right: 30px;
        flex-shrink: 0;
        @media (max-width: 767px) {
          width: 80px;
          height: 80px;
        }
      }

      &--text {
        display: flex;
        flex-direction: column;
        margin-right: 40px;
        justify-content: center;

        .name {
          font-size: 30px;
          line-height: 36px;
          margin-bottom: 5px;
        }

        .post {
          font-size: 20px;
          line-height: 24px;
          opacity: 0.4;
        }
      }

      &--company {
        width: 100px;
        height: 100px;
        @media (max-width: 767px) {
          width: 50px;
          height: 50px;
        }
      }
    }

    &__bottom {
      position: relative;
      background-image: url("/local/html/img/quotes-up.svg"), url("/local/html/img/quotes-down.svg");
      background-color: var(--india-bg-secondary);
      background-position: 5% 10%, 95% 90%;
      background-repeat: no-repeat;
      background-size: auto;

      &--blockquote {
        color: var(--india-typography-secondary);
        max-width: 946px;
        font-size: 40px;
        padding: 80px 40px 90px;
        line-height: 52px;
        margin: auto;
        @media (max-width: 991px) {
          font-size: 35px;
          line-height: 42px;
          padding: 60px 40px 60px;
        }
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 28px;
          font-weight: 400;
          padding: 60px 30px 60px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        width: 100px;
        height: 30px;
        top: -28px;
        left: 50px;
        background: url("/local/html/img/review-arrow.svg") center no-repeat;
        background-size: contain;
        @media (max-width: 767px) {
          left: 0;
          width: 80px;
          height: 24px;
          top: -22px;
        }
      }
    }
  }

  .about {
    position: relative;
    padding: 100px 0 130px;
    background-color: var(--india-bg-secondary);
    overflow: hidden;

    &::after {
      position: absolute;
      //  Временно отключен
      /* content: ""; */
      width: 1910px;
      height: 2381px;
      top: -650px;
      right: -330px;
      background-image: url("/local/html/img/about-bg.svg");
      background-repeat: no-repeat;
      background-size: cover;

      @media (max-width: 991px) {
        top: -660px;
        right: -550px;
      }

      @media (max-width: 767px) {
        right: -560px;
      }
    }

    @media (max-width: 991px) {
      padding: 60px 0;
    }

    &__desc {
      max-width: 994px;
      margin-bottom: 60px;
      @media (max-width: 991px) {
        margin-bottom: 40px;
      }

      &--title {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 20px;
        @media (max-width: 991px) {
          font-size: 35px;
          line-height: 42px;
        }
        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      &--text {
        color: var(--india-typography-secondary);
        font-size: 24px;
        line-height: 32px;
        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 25px;
        }

        p {
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    &__items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 36px;
      grid-row-gap: 36px;

      @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }
    }

    &__item {
      background: #1e2530;
      padding: 25px 40px 35px;

      &--value {
        width: min-content;
        font-size: 70px;
        line-height: 84px;
        font-weight: 600;
        color: var(--india-bg-primary);
        background: linear-gradient(90deg, #0b6ba7 0%, #96c15b 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &--text {
        color: var(--india-typography-secondary);
        font-size: 24px;
        line-height: 32px;
      }
    }

    &__link-wrapper {
      margin-top: 30px;
    }

    &__arrow-link {
      position: relative;
      padding-right: 34px;

      svg {
        position: absolute;
        right: 0;
        top: 4px;
        transition: all 0.3s ease;
      }

      &:hover {
        color: var(--light-blue);
        text-decoration: none;

        svg {
          right: -8px;
        }

        svg > path {
          stroke: var(--light-blue);
        }
      }
    }
  }

  .advertising {
    // Временно отключен
    display: none;
    padding: 150px 0;

    @media (max-width: 991px) {
      padding: 40px 0 80px;
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 36px;

      @media (max-width: 991px) {
        grid-column-gap: 20px;
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }
    }

    &__item {
      padding: 40px 40px 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 767px) {
        padding: 40px 30px 50px;
      }

      &--title {
        margin-bottom: 10px;
        font-size: 30px;
        line-height: 36px;
        font-weight: 600;

        @media (max-width: 767px) {
          font-size: 25px;
          line-height: 30px;
        }
      }

      &--text {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 0;
      }

      &--btn {
        margin-top: 30px;
        width: min-content;
      }

      &--left {
        border: 1px solid var(--blue);

        .item {
          &--title {
            color: var(--india-typography-primary);
          }

          &--text {
          }
        }
      }

      &--right {
        background: var(--main-gradient);

        .item {
          &--title {
            color: var(--india-typography-secondary);
          }

          &--text {
            color: var(--india-typography-secondary);
          }
        }
      }
    }
  }

  .footer-india {
    background-color: #1e2530;
    /* background: var(--india-bg-secondary); */
    padding: 60px 0 30px;

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      padding-bottom: 50px;

      @media (max-width: 991px) {
        flex-direction: column;
      }
    }

    &__logo {
      flex-shrink: 0;
      width: 230px;
      height: 44px;

      img {
        width: 100%;
      }

      @media (max-width: 991px) {
        margin-bottom: 40px;
      }

      @media (max-width: 767px) {
        width: 100%;
        height: auto;
      }
    }

    &__contacts {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 40px;
      grid-row-gap: 15px;
      width: calc(100% - 320px);

      @media (max-width: 991px) {
        width: 100%;
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }

      &--block {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-row-gap: 15px;
      }

      &--item {
        .item {
          &--title {
            font-size: 18px;
            font-weight: 600;
            color: var(--india-typography-secondary);
            margin-bottom: 10px;
          }

          &--text {
            color: var(--india-typography-secondary);
            opacity: 0.4;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }

    &__bottom {
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.4);

      @media (max-width: 767px) {
        flex-direction: column;
      }

      &--rights {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }
      }
    }

    &__authority {
      margin-bottom: 0;
    }

    &__reserved {
      margin: 0 30px 0 0;

      @media (max-width: 767px) {
        margin: 5px 0 5px;
      }
    }

    &__policy {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .indiaForm.mainForm {
    background: rgba(#000000, 0.85);

    .mainForm__content {
      display: flex !important;
      max-width: 360px;
      margin: auto;
    }

    .mainForm__title {
      background: var(--main-gradient);
      margin-bottom: 0;
      padding: 30px 30px 20px;
      color: white;
      max-width: 360px;
      width: 100%;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      flex-direction: column;

      &--subtitle {
        margin-top: 8px;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .main-form-close {
      top: 10px;
      right: 10px;
    }

    form {
      margin-top: 0;
      background: white;
      width: 100%;
      max-width: 360px;
      padding: 25px 30px 50px;

      input {
        border: 1px solid #09101b;
        border-radius: 3px;
        color: var(--india-typography-primary);
      }

      .placeholder {
        color: var(--india-typography-primary);
        opacity: 0.4;

        &.error {
          color: var(--red);
          opacity: 1;
        }
      }

      .form__btn {
        font-size: 16px;
        line-height: 20px;
      }

      .error_submit {
        padding: 5px;
        margin-top: 16px;
      }
    }
  }

  .modal-success {
    &__content {
      background-color: white;
      position: relative;
      padding: 40px 30px 50px;
      max-width: 360px;
      margin: auto;

      .title {
        color: var(--india-typography-primary);
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 5px;
        font-weight: 600;
        text-align: left;
      }

      .main-form-close {
        top: 10px;
        right: 10px;
      }

      .form__btn {
        width: 100%;
        margin-top: 24px;
      }
    }

    &.active {
      background-color: rgba(#000000, 0.85);
    }
  }
}

.policy-india {
  padding-top: 140px;
  padding-bottom: 100px;

  @media (max-width: 767px) {
    padding-top: 230px;
  }

  &__title {
    color: var(--india-typography-primary);
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
    font-weight: 600;

    @media (max-width: 991px) {
      font-size: 35px;
      line-height: 42px;
    }

    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__content {
    &--text {
      font-size: 24px;
      line-height: 32px;

      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}
