.promo-slider {
  margin-top: -168px;

  @media(max-width: 991px) {
    margin-top: -186px;
  }
  @media(max-width: 768px) {
    margin-top: -170px;
  }
  @media(max-width: 638px) {
    margin-top: -194px;
  }
  @media(max-width: 576px) {
    margin-top: -200px;
  }
  @media(max-width: 480px) {
    margin-top: -190px;
  }
  @media(max-width: 353px) {
    margin-top: -208px;
  }
  @media(max-width: 320px) {
    margin-top: -244px;
  }

  &__info {
    position: relative;
    z-index: 4;
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    opacity: 0;
    transform: translate(-50%, -50%);

    &.active {
      opacity: 1;
    }

    @media(max-width: 768px) {
      display: none;
    }
  }

  img.promo-slider__mobile-wave {
    display: none;

    @media(max-width: 768px) {
      display: block;
      position: absolute;
      z-index: 1;
      top: auto;
      left: 0;
      bottom: 90px;
      width: 100%;
      height: auto;
    }
  }

  &__first-slide {

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0C0F0E;
    }

    .promo-slider__text {
      max-width: 815px;
      width: 100%;
      font-size: 3.2rem;
      @media (max-width: 767px) {
        font-size: 1.5rem;
        width: auto;
      }
    }
  }

  .promo-slider__video-img {
    z-index: 1;
    @media(max-width: 768px) {
      display: none;
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    will-change: transform;
    padding-top: 168px;
    padding-bottom: 132px;
    height: auto;
    min-height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    @media(max-width: 991px) {
      padding-top: 186px;
    }
    @media(max-width: 768px) {
      min-height: 90vh;
      padding-top: 232px;
    }
    @media(max-width: 767px) {
      padding-bottom: 64px;
    }
    @media(max-width: 480px) {
      padding-top: 170px;
    }
    @media(max-width: 320px) {
      padding-top: 244px;
    }
  }

}

.menu-link {
  &:hover {
    text-decoration: none !important;
    color: #fff !important;
  }
}

.promo-slider img {
  width: 100%;
  z-index: -1;
  left: 0;
  top: -85px; /* Потому что контейнер со слайдером смещен на 160px (класс .promo-slider) */
  height: calc(100% + 160px); /* Потому что контейнер со слайдером смещен на 160px (класс .promo-slider) */
  object-fit: cover;

  &.promo-slider__video-img {
    z-index: 1;
  }
}

.promo-slider__info p, .promo-slider__info div {
  @media(max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* .promo-slider p {
  width: 75%;
  font-size: 3.12rem;
  line-height: 1.2;
} */
.promo-slider__bottom {
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  z-index: 1;
  padding: 0;
  @media (max-width: 1024px) {
    bottom: 60px;
  }
  @media(max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
    /* bottom: 12px; */
  }
}

.promo-slider__control div {
  position: relative !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
}

.promo-slider__control .separator {
  border-right: 1px solid var(--white_opacity);
  width: 1px;
  height: 75%;
  opacity: .25;
}

.promo-slider__control .swiper-button-next:after, .promo-slider__control .swiper-button-prev:after {
  /*  font-family: 'ProximaNova-Regular';*/
  content: url('data:image/svg+xml; utf8, <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g fill="none"><path d="M0.271974 9.36367L24.272 9.36367M24.272 9.36367L15.854 17M24.272 9.36367L15.854 1.00001" stroke="white" stroke-width="2"/></g></svg>');
  line-height: 1;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.promo-slider__control .swiper-button-next:hover:after, .promo-slider__control .swiper-button-prev:hover:after {
  content: url('data:image/svg+xml; utf8, <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.271974 9.36367L24.272 9.36367M24.272 9.36367L15.854 17M24.272 9.36367L15.854 1.00001" stroke="rgb(37,162,202)" stroke-width="2"/></svg>');
}

.promo-slider__control .swiper-button-prev:after {
  transform: translateY(0) rotate(180deg);
}

.promo-slider__control .swiper-pagination-current {
  padding-right: 0.5rem;
}

.promo-slider__control .swiper-pagination-total {
  padding-left: 0.5rem;
}

.swiper-pagination-bullet {
  display: none;
}

.promo__pag {
  &-prev {
    margin-right: 5px;
  }

  &-next {
    margin-left: 5px;
  }
}
