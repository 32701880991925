.news-filter {
    border-bottom: 1px solid var(--line-dark-20);
}

.news-filter button:not(:active) {
    color: var(--gray_opacity) !important;
}

.news-filter button.pf-filter-btn-active {
    color: var(--main-bg) !important;
}

.news-filter button {
    background: none;
    border: none;
    outline: none;
    white-space: nowrap;
}

.news-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-flow: dense;
    grid-template-rows: auto;
    @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
    }
}

.news-grid-item {
    overflow: hidden;
    position: relative;
    height: 363px;

    &_no-pic {
        background-color: #22292e;
    }

    .subscribe {
        &__title {
            font-size: 21px;
            font-weight: 400;
        }

        &__form {
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        &__row {
            display: flex;
            flex-direction: column;
            height: auto;
            margin-bottom: 35px;

            &.error[data-form-group] {
                padding-bottom: 0;
            }

            &.error {
                margin-bottom: 35px;

                .form-error {
                    position: absolute;
                    margin-bottom: 0;
                }
            }

            label {
                position: relative;
                width: auto !important;
                margin-right: 0;
            }

            input {
                width: 100%;
                height: 50px;
                padding-top: 1rem;
                color: rgba(#ffffff, 0.4);
                background-color: transparent;
                border: none;
                border-bottom: 1px solid rgba(#ffffff, 0.3);
                outline: none;
            }
        }

        &__note {
            margin-top: auto;
            color: rgba(#ffffff, 0.4) !important;

            a {
                color: rgba(#25a2ca, 0.4) !important;
            }
        }
    }

    &.subscribe {
        display: flex;
        flex-direction: column;
        padding: 36px 36px 40px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 3px;

        .button {
            max-width: 160px;
            font-size: 16px;
        }

        .placeholder {
            color: rgba(#fff, 0.4);
            left: 0;
            top: 28px;
            z-index: 0;
            background: transparent;
            transform: translateY(-50%) scale(1);
            transition: all 0.3s ease;
        }

        .error .placeholder {
            color: var(--red);
        }

        &::after,
        &::before {
            content: none;
        }

        @media (max-width: 991px) {
            max-width: 400px;
            align-self: center;
        }
    }

    //  Исправляет глюк с накладкой из-за анимации
    .animation-done &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.135) 0%, rgba(0, 0, 0, 0.6) 100%);
        z-index: 1;
        transition: 0.3s ease;
        visibility: visible;
        opacity: 1;
    }

    &_no-pic {
        &::after {
            content: none;
        }
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.135) 0%, rgba(0, 0, 0, 0.8) 100%);
        z-index: 1;
        transition: 0.3s ease;
        visibility: hidden;
        opacity: 0;
    }
    &:hover {
        &:after {
            visibility: hidden;
            opacity: 0;
        }
        &:before {
            visibility: visible;
            opacity: 1;
        }
    }
    /* transition: all .3s ease; */
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &_big {
        grid-row: auto / span 1;
        grid-column: auto / span 2;
        .news-grid-item__title {
            font-size: 2rem;
            @media (max-width: 576px) {
                font-size: 14px;
            }
        }
    }

    &__inner {
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0);
        top: 0;
        transition: all 0.3s ease;
        box-sizing: border-box;
    }

    &__type {
        position: absolute;
        top: 30px;
        left: 35px;
        font-size: 14px;
        color: rgba(#fff, 1) !important;
    }

    &__info {
        position: relative;
        z-index: 10;
    }

    &__date {
        color: rgba(#fff, 0.8) !important;
        font-size: 16px;
        margin-bottom: 10px;
        @media (max-width: 576px) {
            font-size: 14px;
        }
    }

    &__title {
        color: #fff !important;
        font-size: 1.125rem;
        @media (max-width: 576px) {
            font-size: 14px;
        }
    }

    @media (max-width: 991px) {
        height: 350px;
        margin-top: 20px;
    }
    @media (max-width: 768px) {
        height: 250px;
    }
}

.news {
    margin-top: 40px;
    padding-bottom: 60px;

    .swiper-slide {
        height: 100%;
        margin-top: 0;
    }

    @media (min-width: 768px) {
        .swiper-wrapper {
            display: flex;
            flex-direction: column;
            height: auto;
        }

        .swiper-slide {
            height: 400px;
            margin-top: 20px;
        }

        .nx-list-slider__prev,
        .nx-list-slider__next {
            display: none;
        }
    }

    @media (min-width: 992px) {
        .swiper-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
            grid-auto-flow: dense;
            grid-template-rows: auto;
        }
    }
}

.news-wr {
    padding-bottom: 100px;
    @media (max-width: 768px) {
        padding-top: 50px !important;
        padding-bottom: 60px;
    }
}

.news-head {
    position: relative;
    padding-top: 30px;
    padding-bottom: 60px;
    &__body {
        position: relative;
        z-index: 2;
    }

    &__title {
        font-weight: 700;
        margin-bottom: 15px;
    }

    &__text {
        font-size: 1.125rem;
    }

    @media (max-width: 768px) {
        min-height: auto !important;
        padding-top: 70px;
        padding-bottom: 0;
    }

    .text {
        h1,
        h2 {
            font-weight: 700;
        }
    }
}
