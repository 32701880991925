.rating-row__images {
    display: grid !important;
    grid-template-rows: 1fr;
    grid-template-columns: 30% 30% 30%;
}

.rating-row__images img {
    object-fit: contain;
    max-width: 100%;
}

.rating-row__images .d-flex img {
    align-self: baseline;
    object-fit: scale-down;
}

.rating-row > .col-lg-6:first-child img {
    max-width: 30%;
}
