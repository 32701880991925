.tvc {
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: 16px -16px;
    list-style: none;
    padding: 0;
    @media(max-width: 580px) {
      margin: 16px 0 32px;
    }
    &__item {
      margin: 16px;
      background: #ffffff;
      padding: 32px;
      box-shadow: 0 4px 40px rgba(128, 128, 128, 0.09);
      border-radius: 20px;
      width: calc(100%/3 - 32px);
      @media(max-width: 991px) {
        width: calc(100%/2 - 32px);
      }
      @media(max-width: 580px) {
        width: 100%;
        margin: 16px 0 0;
      }
      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        background: #2570F2;
        border-radius: 50%;
        color: #ffffff !important;
        font-size: 26px;
        line-height: 26px;
        font-weight: 700;
        padding-top: 2px;
        margin-bottom: 78px;
        @media(max-width: 580px) {
          margin-bottom: 24px;
        }
      }
      .list {
        margin: 0;
        padding: 0;
        list-style: none;
        &__item {
          position: relative;
          margin-top: 20px;
          padding-left: 26px;
          &:first-child {
            margin-top: 0;
          }
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #FF892E;
            transform: translateY(-50%);
          }
        }
      }
      &--final {
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        .title {
          color: #363E4A;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 64px;
        }
        .count {
          font-size: 80px;
          line-height: 98px;
          font-weight: 700;
          color: #FF862E !important;
          span {
            display: block;
            font-size: 24px;
            line-height: normal;
            color: #FF862E !important;
          }
        }
      }
    }
  }

  &-proto-container {
    max-width: 1800px;
    margin: 0 auto;
    padding: 16px;
    @media(max-width: 580px) {
      padding: 0 0 32px;
    }
  }

  &-proto {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 16px -24px;
    list-style: none;
    @media(max-width: 991px) {
      margin: 16px -16px;
    }
    @media(max-width: 580px) {
      margin: 32px 0 32px;
    }
    &__item {
      width: calc(100%/3 - 48px);
      max-width: 490px;
      margin: 24px;
      @media(max-width: 991px) {
        width: calc(100%/2 - 32px);
        margin: 16px;
      }
      @media(max-width: 580px) {
        width: 100%;
        max-width: 400px;
        margin: 32px 0 0;
      }
      .title {
        font-size: 22px;
        font-weight: 700;
        color: #363E4A !important;
        margin-bottom: 18px;
      }
      .img {
        width: 100%;
        box-shadow: 0 3.85225px 77.045px rgba(128, 128, 128, 0.29);
        border-radius: 6px;
      }
    }


    &--4 {
      margin: -16px;
      @media(max-width: 580px) {
        margin: 32px 0 0;
      }
      .tvc-proto__item {
        width: calc(25% - 32px);
        margin: 32px 16px;
        max-width: 380px;
        @media(max-width: 1280px) {
          width: calc(100%/3 - 32px);
        }
        @media(max-width: 840px) {
          width: calc(50% - 32px);
        }
        @media(max-width: 580px) {
          width: 100%;
          margin: 32px 0 0;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

  }

  &-numbers {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1200px;
    margin: 100px auto 200px;
    @media(max-width: 1199px) {
      margin: 48px 0 60px;
    }
    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      right: -48px;
      bottom: -78px;
      width: 492px;
      height: 248px;
      background-image: url(../img/tvc-numbers-decor.svg);
      background-position: right bottom;
      background-repeat: no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
      @media(max-width: 1280px) {
        display: none;
      }
    }
    &__list {
      padding: 82px 32px;
      background: #257CF2;
      position: relative;
      z-index: 1;
      width: 100%;
      display: flex;
      list-style: none;
      @media(max-width: 1199px) {
        padding: 42px 16px;
      }
      @media(max-width: 680px) {
        display: block;
        padding: 16px;
      }
    }
    &__item {
      width: 25%;
      border-right: 1px solid rgba(255, 255, 255, 0.15);
      font-size: 22px;
      line-height: 26px;
      color: #ffffff !important;
      padding: 0 16px;
      text-align: center;
      @media(max-width: 1199px) {
        font-size: 16px;
      }
      @media(max-width: 680px) {
        width: 100%;
        margin-top: 16px;
        border-right: none;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
        padding: 16px 0 0;
        &:first-child {
          margin-top: 0;
          border-top: none;
        }
      }
      &:last-child {
        border-right: none;
      }
      .count {
        display: block;
        font-size: 70px;
        line-height: 58px;
        font-weight: 700;
        color: #ffffff !important;
        margin-bottom: 18px;
        @media(max-width: 1199px) {
          font-size: 42px;
          margin-bottom: 10px;
        }
      }
    }
  }

  &-tech {
    &__title {
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      color: #363e4a !important;
      margin: 0 auto 64px;
      max-width: 392px;
      text-transform: uppercase;
      @media(max-width: 1024px) {
        margin: 0 auto 32px;
      }
    }
    &__items {
      position: relative;
      width: 1000px;
      height: 534px;
      margin: 0 auto;
      display: flex;
      @media(max-width: 1024px) {
        width: 592px;
        height: 320px;
      }
      @media(max-width: 640px) {
        width: 280px;
        height: 540px;
      }
    }
    &__item {
      position: absolute;
      top: 0;
      flex-shrink: 0;
      width: 534px;
      height: 534px;
      border-radius: 50%;
      padding-top: 146px;
      @media(max-width: 1024px) {
        width: 320px;
        height: 320px;
        padding-top: 84px;
      }
      @media(max-width: 640px) {
        padding-top: 58px;
      }

      .title {
        font-size: 28px;
        line-height: 28px;
        font-weight: 700;
        color: #ffffff !important;
        text-transform: uppercase;
        margin-bottom: 30px;
        @media(max-width: 1024px) {
          margin-bottom: 16px;
        }
      }

      .list {
        margin: 0;
        padding: 0;
        list-style: none;
        &__item {
          position: relative;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff !important;
          margin-top: 28px;
          @media(max-width: 1024px) {
            font-size: 18px;
            line-height: 24px;
            margin-top: 10px;
          }
          &:first-child {
            margin-top: 0;
          }
          &::before {
            content: '';
            width: 6px;
            height: 6px;
            background: #ffffff;
            border-radius: 50%;
            position: absolute;
            top: 12px;
            left: -26px;
            @media(max-width: 1024px) {
              top: 10px;
              left: -16px;
            }
          }
        }
      }

      &--front {
        left: 0;
        z-index: 2;
        background: #FF892E;
        padding-left: 148px;
        padding-right: 122px;
        @media(max-width: 1024px) {
          padding-left: 84px;
          padding-right: 42px;
        }
        @media(max-width: 640px) {
          top: 0;
          left: 0;
          width: 280px;
          height: 280px;
        }
      }
      &--back {
        right: 0;
        z-index: 1;
        background: #257CF2;
        padding-left: 162px;
        padding-right: 132px;
        @media(max-width: 1024px) {
          padding-left: 84px;
          padding-right: 42px;
        }
        @media(max-width: 640px) {
          top: auto;
          right: 0;
          bottom: 0;
          width: 280px;
          height: 280px;
        }
      }
    }
  }

  &-subtract {
    width: 74px;
    height: 74px;
    margin: 278px auto 348px;
    @media(max-width: 1440px) {
      margin: 120px auto 240px;
    }
    @media(max-width: 1280px) {
      margin: 80px auto 160px;
    }
    @media(max-width: 1024px) {
      margin: 40px auto 80px;
    }
  }
}