.ae {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--india-bg-primary);
    padding: 0;
    color: var(--india-typography-primary);

    main {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .content {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    }

    .btn {
        text-decoration: none;
        outline: none;
        border-radius: 3px;
        padding: 16px 29px;
        white-space: nowrap;
        transition: all linear 0.2s;
        cursor: pointer;
        text-align: center;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            outline: none;
        }

        &--primary {
            font-weight: 600;
            color: white !important;
            background: var(--main-gradient);
            border: none;
            transition: all linear 0.2s;

            &:hover {
                color: var(--india-bg-primary) !important;
                background: linear-gradient(89.85deg, #96c15b 0.1%, #0b6ba7 99.87%);
            }
        }

        &--secondary {
            background: var(--india-bg-primary);
            border: none;
            font-weight: 600;

            &:hover {
                background: rgba(white, 0.9);
            }

            &:disabled {
                opacity: 0.2;
                border: 2px solid #ffffff;
                background: transparent;
            }

            &-text {
                color: var(--india-bg-primary);
                background: var(--main-gradient);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &--hollow {
            font-weight: 600;
            background: transparent;
            border: 2px solid white;
            color: white !important;

            &:hover {
                background: white;

                .btn--hollow-text {
                    color: var(--india-bg-primary);
                    background: var(--main-gradient);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        &--link {
            background: transparent;
            border: none;
            color: var(--india-typography-primary);

            &:hover {
                color: var(--blue);
            }
        }
    }

    .wrapper {
        position: relative;
        max-width: calc(1200px + var(--padding-size) * 2);
        margin: 0 auto;
        padding: 0 var(--padding-size);
        display: flex;
        flex-direction: column;
        z-index: 1;
    }

    .header-emirates {
        background: var(--india-bg-primary);
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 25px 0 18px;

        @media (max-width: 991px) {
            padding: 20px 0 20px;
        }

        &__logo {
            width: 270px;
            height: 52px;
            flex-shrink: 0;
            margin-right: 20px;

            img {
                width: 100%;
            }

            @media (max-width: 991px) {
                width: 210px;
                height: 40px;
            }
            @media (max-width: 767px) {
                width: 170px;
                height: 35px;
            }
        }

        a {
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 767px) {
                flex-wrap: wrap;
            }
        }

        &__block {
            display: flex;
            align-items: center;

            @media (max-width: 991px) and (min-width: 767px) {
                width: 100%;
                justify-content: space-between;
            }
        }

        &__btn-wrapper {
            margin-left: 40px;

            @media (max-width: 767px) {
                display: none;
            }

            &--mobile {
                position: fixed;
                display: flex;
                justify-content: center;
                margin-left: 0;
                width: 100%;
                margin-top: 15px;
                padding-top: 20px;
                padding-bottom: 20px;
                text-align: center;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(255, 255, 255, 0.87);
                backdrop-filter: blur(4px);
                transform: translateY(300px);
                transition: all 0.3s ease-in-out;

                &.visible {
                    transform: translateY(0);
                }

                @media (min-width: 767px) {
                    display: none;
                }
            }
        }

        &__btn {
            font-weight: 400;
            padding: 10px 20px;

            &--mobile {
                max-width: 280px;
                width: 100%;
            }
        }

        &__contacts {
            display: flex;

            @media (max-width: 991px) {
                flex-direction: column;
            }
            @media (max-width: 767px) {
                flex-direction: row;
            }

            &--item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 20px;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: var(--india-typography-primary);

                @media (max-width: 991px) {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
                @media (max-width: 767px) {
                    margin-bottom: 0;
                    margin-right: 5px;
                    &:first-child {
                        span {
                            display: none;
                        }
                        img {
                            margin-right: 0;
                        }
                    }
                }
                @media (max-width: 450px) {
                    span {
                        display: none;
                    }
                    img {
                        margin-right: 0;
                    }
                }

                img {
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                    @media (max-width: 767px) {
                        width: 34px;
                        height: 34px;
                        padding: 7px;
                        border: 1.2px solid #7CB16A;
                        border-radius: 3px;
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }

        &--thank {
            .header-emirates__content {
                justify-content: center;
            }

            .header-emirates__logo {
                margin-right: 0;
            }
        }
    }

    .thank {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding-top: 94px;
        background: linear-gradient(90deg, #0b6ba7 0%, #96c15b 100%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        &::after {
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-image: url("/local/html/img/thank-wave.svg");
            background-size: auto;
            background-repeat: no-repeat;
            background-position: bottom right;

            @media (max-width: 767px) {
                content: none;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-self: center;
            align-items: center;
            max-width: 480px;
            padding: 130px 0;
            z-index: 7;

            @media (max-width: 991px) {
                max-width: 344px;
            }
            @media (max-width: 767px) {
                max-width: unset;
            }
        }

        &__title {
            margin-bottom: 20px;
            font-size: 50px;
            line-height: 55px;
            font-weight: 600;
            @media (max-width: 991px) {
                font-size: 40px;
                line-height: 44px;
            }
            @media (max-width: 767px) {
                font-size: 30px;
                line-height: 33px;
            }
        }

        &__text {
            margin-bottom: 40px;
            color: var(--india-typography-secondary);
            font-size: 24px;
            text-align: center;
        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #0b6ba7;
            box-shadow: 0px 100px 80px rgba(2, 52, 69, 0.25), 0px 41.7776px 33.4221px rgba(2, 52, 69, 0.179714),
            0px 22.3363px 17.869px rgba(2, 52, 69, 0.149027), 0px 12.5216px 10.0172px rgba(2, 52, 69, 0.125),
            0px 6.6501px 5.32008px rgba(2, 52, 69, 0.100973), 0px 2.76726px 2.21381px rgba(2, 52, 69, 0.0702864);

            &:hover {
                box-shadow: none;
            }

            svg {
                margin-right: 10px;
            }

            @media (max-width: 767px) {
                width: 100%;
                max-width: 280px;
                align-self: center;
            }
        }
    }

    .hero {
        position: relative;
        padding-top: 94px;
        background: linear-gradient(90deg, #0b6ba7 0%, #96c15b 100%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 600px;
            padding: 105px 0 145px;
            z-index: 7;

            @media (max-width: 767px) {
                padding: 80px 0 200px;
                max-width: unset;
            }

            &--title {
                margin-bottom: 38px;
                font-size: 50px;
                line-height: 55px;
                font-weight: 400;

                @media (max-width: 991px) {
                    font-size: 40px;
                    line-height: 44px;
                }

                @media (max-width: 767px) {
                    font-size: 30px;
                    line-height: 33px;
                }
            }

            &--text {
                margin-bottom: 100px;
                color: var(--india-typography-secondary);
                font-size: 24px;
                font-weight: 700;

                @media (max-width: 767px) {
                    margin-bottom: 50px;
                }
            }

            &--btn {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #0b6ba7;
                -webkit-appearance: none;
                -webkit-box-shadow: 0px 100px 80px rgba(2, 52, 69, 0.25),
                0px 42px 33px rgba(2, 52, 69, 0.179714),
                0px 22px 18px rgba(2, 52, 69, 0.149027),
                0px 12px 10px rgba(2, 52, 69, 0.125),
                0px 7px 5px rgba(2, 52, 69, 0.100973),
                0px 3px 2px rgba(2, 52, 69, 0.0702864);

                box-shadow: 0px 100px 80px rgba(2, 52, 69, 0.25),
                0px 42px 33px rgba(2, 52, 69, 0.179714),
                0px 22px 18px rgba(2, 52, 69, 0.149027),
                0px 12px 10px rgba(2, 52, 69, 0.125),
                0px 7px 5px rgba(2, 52, 69, 0.100973),
                0px 3px 2px rgba(2, 52, 69, 0.0702864);

                &:hover {
                    box-shadow: none;
                }

                svg {
                    margin-right: 10px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    max-width: 280px;
                    align-self: center;
                }
            }
        }

        &__bg {
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 100%;
            right: -25%;
            bottom: 15px;
            background-image: url("/local/html/img/dubai-city-skyline-towers.svg");
            background-size: auto;
            background-repeat: no-repeat;
            background-position: center bottom;

            @media (max-width: 767px) {
                background-size: cover;
                height: 42%;
                background-position: 81% bottom;
                top: unset;
                right: auto;
                left: 0;
            }

            @media (max-width: 600px) {
                background-position: 71% bottom;
            }

            @media (max-width: 475px) {
                background-position: 61% bottom;
            }
        }

        &--bottom {
            margin-bottom: 100px;
            background: none;

            .wrapper {
                @media (max-width: 991px) {
                    align-items: center;
                }
            }

            .hero__content {
                max-width: 480px;
                padding: 0;

                &--title {
                    font-weight: 600;
                    font-size: 40px;
                    color: #232B37;

                    span {
                        color: var(--light-green);
                    }

                    @media (max-width: 991px) {
                        font-size: 30px;
                        line-height: 44px;
                    }

                    @media (max-width: 767px) {
                        font-size: 20px;
                        line-height: 33px;
                    }
                }

                &--text {
                    margin-bottom: 45px;
                    font-weight: 400;
                    color: #232B37;
                    opacity: 0.8;

                    @media (max-width: 767px) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            .hero__bg {
                width: 760px;
                height: 420px;
                top: 0;
                right: 0;
                background-image: url("/local/html/img/mac-bg.png");
                background-size: contain;

                img {
                    display: none;
                }

                @media (max-width: 1229px) {
                    right: -230px;
                }

                @media (max-width: 991px) {
                    position: static;
                    width: auto;
                    height: auto;
                    margin-top: 50px;
                    background: none;

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }

                @media (max-width: 767px) {
                    margin-bottom: 50px;
                }
            }

            @media (max-width: 767px) {
                margin-bottom: 0;
            }
        }
    }

    .advantages {
        margin-bottom: 100px;
        padding-top: 80px;

        @media (max-width: 991px) {
            padding-top: 30px;
        }

        &__link {
            display: inline-block;
            margin-top: 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            background: -moz-linear-gradient(15deg, #0b6ba7 0%, #96c15b 50%);
            background: -webkit-gradient(linear, left top, right top, color-stop(0%, #0b6ba7), color-stop(50%, #96c15b));
            background: -webkit-linear-gradient(15deg, #0b6ba7 0%, #96c15b 50%);
            background: -o-linear-gradient(15deg, #0b6ba7 0%, #96c15b 50%);
            background: -ms-linear-gradient(15deg, #0b6ba7 0%, #96c15b 50%);
            background: linear-gradient(15deg, #0b6ba7 0%, #96c15b 50%);

            -webkit-background-clip: text;
            -moz-background-clip: text;
            -ms-background-clip: text;
            -o-background-clip: text;
            background-clip: text;

            -webkit-text-fill-color: transparent;
            -moz-text-fill-color: transparent;
            -ms-text-fill-color: transparent;
            -o-text-fill-color: transparent;
            text-fill-color: transparent;
            opacity: 1;

            svg {
                width: 20px;
                height: 20px;
                margin-left: 10px;
                stroke: #96c15b;
            }

            &:hover {
                text-decoration: none;
                background: -moz-linear-gradient(240deg, #0b6ba7 0%, #96c15b 110%);
                background: -webkit-gradient(linear, left top, right top, color-stop(0%, #0b6ba7), color-stop(110%, #96c15b));
                background: -webkit-linear-gradient(240deg, #0b6ba7 0%, #96c15b 110%);
                background: -o-linear-gradient(240deg, #0b6ba7 0%, #96c15b 110%);
                background: -ms-linear-gradient(240deg, #0b6ba7 0%, #96c15b 110%);
                background: linear-gradient(240deg, #0b6ba7 0%, #96c15b 110%);

                -webkit-background-clip: text;
                -moz-background-clip: text;
                -ms-background-clip: text;
                -o-background-clip: text;
                background-clip: text;

                -webkit-text-fill-color: transparent;
                -moz-text-fill-color: transparent;
                -ms-text-fill-color: transparent;
                -o-text-fill-color: transparent;
                text-fill-color: transparent;

                svg {
                    stroke: #0b6ba7;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__top {
            max-width: 950px;
            margin-bottom: 100px;

            &--wide {
                max-width: 1160px;
            }

            &--title {
                color: var(--india-typography-primary);
                font-size: 40px;
                line-height: 48px;
                font-weight: 600;
                margin-bottom: 20px;

                span {
                    color: var(--light-green);
                }

                @media (max-width: 991px) {
                    font-size: 35px;
                    line-height: 42px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 34px;
                }
            }

            &--text {
                font-size: 24px;
                line-height: 32px;
                @media (max-width: 767px) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            @media (max-width: 767px) {
                margin-bottom: 50px;
            }
        }

        &__bottom {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-auto-rows: auto;
            grid-column-gap: 36px;
            grid-row-gap: 50px;

            @media (max-width: 991px) {
                grid-template-columns: 1fr 1fr;
                grid-row-gap: 100px;
            }

            @media (max-width: 767px) {
                grid-template-columns: 1fr;
                grid-row-gap: 90px;
            }

            &--item {
                display: flex;
                flex-direction: column;

                .item {
                    &__img {
                        height: 80px;
                    }

                    &__title {
                        font-size: 30px;
                        line-height: 36px;
                        margin-bottom: 10px;

                        @media (max-width: 767px) {
                            font-size: 25px;
                            line-height: 30px;
                        }
                    }

                    &__text {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }

            &--with-shadow {
                position: relative;
                padding: 78px 30px 27px;

                &::before,
                &::after {
                    position: absolute;
                    content: "";
                    width: 120px;
                    height: 120px;
                    left: 46px;
                    top: -50px;
                    border-radius: 50%;
                    background-color: #ffffff;
                    box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.1);
                    z-index: -1;
                }

                &::after {
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 0;
                }

                .item {
                    &__img-wrapper {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 120px;
                        height: 120px;
                        left: 46px;
                        top: -50px;
                        border-radius: 50%;
                        background-color: #ffffff;

                        &::after,
                        &::before {
                            position: absolute;
                            content: "";
                            width: 90px;
                            height: 90px;
                            border-radius: 50%;
                            background-image: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%);
                            z-index: 1;
                        }

                        &::after {
                            width: 88px;
                            height: 88px;
                            background-image: none;
                            background-color: #ffffff;
                            z-index: 2;
                        }
                    }

                    &__img {
                        height: 50px;
                        z-index: 3;
                    }

                    &__title {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 1.2;
                        margin-bottom: 18px;
                        color: #09101B;

                        @media (max-width: 767px) {
                            font-size: 18px;
                        }
                    }

                    &__text {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }

            &--four-in-row {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-row-gap: 40px;

                .item__img {
                    margin-bottom: 15px;
                }

                @media (max-width: 991px) {
                    grid-template-columns: 1fr 1fr 1fr;
                }

                @media (max-width: 767px) {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }

    .clientage {
        position: relative;
        background-color: var(--india-bg-secondary);
        padding: 118px 0;
        margin: 150px 0 0;
        overflow: hidden;

        &::after {
            position: absolute;
            content: "";
            width: 1320px;
            height: 1180px;
            top: -150px;
            left: -570px;
            transform: rotate(-85deg);
            background-image: url("/local/html/img/about-bg.svg");
            background-repeat: no-repeat;
            background-size: cover;

            @media (max-width: 767px) {
                content: none;
            }
        }

        @media (max-width: 991px) {
            padding: 60px 0;
            margin: 80px 0 0;
        }

        &__item {
            max-height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: auto;
                height: 100%;
                max-height: 80px;
            }

            @media (max-width: 991px) {
                max-height: 60px;
                justify-content: flex-start;

                img {
                    width: auto;
                    height: 100%;
                    max-height: 60px;
                }
            }

            @media (max-width: 767px) {
                max-height: unset;

                img {
                    height: auto;
                    max-height: unset;
                    max-width: 120px;
                    width: 100%;
                }

                &--risknowlogy {
                    img {
                        max-width: 140px;
                    }
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;

            @media (max-width: 991px) {
                flex-direction: column;
            }

            &--text {
                margin-bottom: 60px;
                font-size: 40px;
                font-weight: 400;
                max-width: 55%;

                @media (max-width: 991px) {
                    margin-bottom: 40px;
                    font-size: 35px;
                    line-height: 42px;
                    max-width: 100%;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 34px;
                }
            }

            &--items {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-template-rows: auto;
                grid-row-gap: 40px;
                grid-column-gap: 40px;

                @media (max-width: 991px) {
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-column-gap: 30px;
                }

                @media (max-width: 767px) {
                    grid-column-gap: 20px;
                    grid-row-gap: 30px;
                }

                @media (max-width: 600px) {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }

    .nx-list-slider {
        &__prev,
        &__next {
            position: absolute;
            bottom: -100px;
            top: auto;
            width: 40px;
            height: 40px;
            text-align: center;
            cursor: pointer;
            z-index: 10;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            border: 2px solid white;
            transform: scaleX(-1);
            color: white;

            &:after {
                display: none;
            }

            &:before {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%), #B13737;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                opacity: 1;
                transition: all 0.2s ease-in-out;
            }

            &-icon{
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                display: block;
                font-size: 30px;
                line-height: 1;
                width: 14px;
                height: 10px;
            }
            &:hover{
                opacity: .8;
            }
            &.swiper-button-disabled{
                &, &:hover{
                    opacity: .1;
                    cursor: default;
                }

                :before {
                    background: rgba(255, 255, 255, 0.2);
                }
            }

            @media (max-width: 991px) {
                display: none;
            }
        }

        &__prev{
            left: calc(50% - 90px);
            transform: scaleX(1);

            &:before {
                right: -95px;
                left: auto;
            }
        }

        &__next{
            right: calc(50% - 90px);

            &:before {
                right: -95px;
                left: auto;
            }
        }

        &__progressbar {
            display: block;
            width: 52px;
            height: 10px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            opacity: 1;
            transition: all 0.2s ease-in-out;
            position: absolute;
            bottom: -85px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
            overflow: hidden;

            @media (max-width: 991px) {
                display: none;
            }
        }

        .timer {
            position: absolute;
            height: 100%;
            left: 0;
            right: auto;
            border-radius: 10px;
            background: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%), #B13737;
            animation: 4.7s progress both;
            display: none;

            &.active {
                display: block;
            }
        }
    }

    .review__slider {

        .nx-list-slider {
            &__prev,
            &__next {
                border: 2px solid #232B37;
                color: #232B37;

                &.swiper-button-disabled{

                    :before {
                        background: #232B37;
                    }
                }
            }

            &__progressbar {
                background-color: #E7E7E7;
            }
        }
    }

    .partners {
        background-color: var(--india-bg-secondary);
        padding: 118px 0;
        background-image: url("/local/html/img/clientage-bg.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;

        @media (max-width: 991px) {
            padding: 60px 0;
            margin: 80px 0 0;
        }

        &__item {
            display: flex;
            justify-content: center;
            width: 180px;

            @media(max-width: 767px) {
                width: 130px;
                justify-content: flex-start;
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            img {
                height: 56px;
                margin-bottom: 10px;
                object-fit: contain;
            }

            @media(max-width: 767px) {
                img {
                    max-height: 40px;
                    height: auto;
                }
            }
        }

        &__status {
            font-size: 20px;
            line-height: 1.5;
            color: #FFFFFF;
            opacity: 0.6;

            @media(max-width: 991px) {
                font-size: 16px;
            }

            @media(max-width: 767px) {
                font-size: 14px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;

            @media (max-width: 991px) {
                flex-direction: column;
            }

            &--text {
                margin-bottom: 70px;
                font-size: 40px;
                font-weight: 400;

                @media (max-width: 991px) {
                    margin-bottom: 40px;
                    font-size: 35px;
                    line-height: 42px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 34px;
                }
            }

            &--items {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                grid-template-rows: auto;
                grid-row-gap: 50px;
                grid-column-gap: 50px;

                @media (max-width: 1199px) {
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-column-gap: 40px;
                }

                @media (max-width: 767px) {
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 20px;
                    grid-row-gap: 30px;
                    justify-items: center;
                }
            }
        }
    }

    .modules {
        padding-top: 150px;

        &_ae {
            padding-top: 0;
        }

        @media (max-width: 991px) {
            padding-top: 80px;

            &_ae {
                padding-top: 0;
            }
        }

        &__title {
            max-width: 1064px;
            color: var(--india-typography-primary);
            margin-bottom: 60px;

            span {
                color: var(--light-green);
            }
            @media (max-width: 991px) {
                margin-bottom: 40px;
                font-size: 35px;
                line-height: 42px;
            }
            @media (max-width: 767px) {
                margin-bottom: 30px;
                font-size: 28px;
                line-height: 34px;
            }
        }

        &__content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto;
            grid-column-gap: 36px;
            grid-row-gap: 27px;
            @media (max-width: 991px) {
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 20px;
                grid-row-gap: 20px;
            }
            @media (max-width: 767px) {
                grid-template-columns: 1fr;
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            padding: 30px 30px 40px;
            border: 1px solid var(--blue);

            &:nth-child(2n) {
                border-color: var(--light-green);
            }

            @media (max-width: 767px) {
                padding: 30px 20px 40px;
            }

            &--image {
                margin-bottom: 20px;
                width: 80px;
                height: 80px;
            }

            &--title {
                margin-bottom: 10px;
                color: var(--india-typography-primary);
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
            }

            &--text {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 0;
                opacity: 0.8;
            }
        }
    }

    .review {
        padding-top: 150px;
        padding-bottom: 150px;

        @media (max-width: 991px) {
            padding-top: 80px;
            padding-bottom: 30px;
        }

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__top {
            padding: 0 50px 64px;
            @media (max-width: 767px) {
                padding: 0 0 30px;
            }
        }

        &__info {
            display: flex;
            justify-content: flex-start;

            &--inner {
                display: flex;
                align-items: center;
                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            &--avatar {
                width: 100px;
                height: 100px;
                margin-left: 30px;
                flex-shrink: 0;
                border-radius: 50%;
                overflow: hidden;
                background: linear-gradient(61.07deg, #0B6BA7 12.7%, #96C15B 88.44%);
                backdrop-filter: blur(66px);
                @media (max-width: 767px) {
                    width: 80px;
                    height: 80px;
                }
            }

            &--text {
                display: flex;
                flex-direction: column;
                margin-right: 40px;
                justify-content: center;

                .name {
                    font-size: 30px;
                    line-height: 36px;
                    margin-bottom: 5px;
                }

                .post {
                    font-size: 20px;
                    line-height: 24px;
                    opacity: 0.4;
                }
            }

            &--company {
                width: 100px;
                height: 100px;
                margin-right: 30px;

                @media (max-width: 767px) {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        &__bottom {
            position: relative;
            background-image: url("/local/html/img/quotes-up.svg"), url("/local/html/img/quotes-down.svg");
            background-color: var(--india-bg-secondary);
            background-position: 5% 10%, 95% 90%;
            background-repeat: no-repeat;
            background-size: auto;

            &--blockquote {
                color: var(--india-typography-secondary);
                max-width: 946px;
                font-size: 40px;
                padding: 80px 40px 90px;
                line-height: 52px;
                margin: auto;
                @media (max-width: 991px) {
                    font-size: 35px;
                    line-height: 42px;
                    padding: 60px 40px 60px;
                }
                @media (max-width: 767px) {
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 400;
                    padding: 60px 30px 60px;
                }
            }

            &:after {
                content: "";
                position: absolute;
                width: 100px;
                height: 30px;
                top: -28px;
                left: 50px;
                background: url("/local/html/img/review-arrow.svg") center no-repeat;
                background-size: contain;
                @media (max-width: 767px) {
                    left: 0;
                    width: 80px;
                    height: 24px;
                    top: -22px;
                }
            }
        }
    }

    .about {
        position: relative;
        padding: 100px 0 130px;
        background: linear-gradient(90deg, #0b6ba7 0%, #96c15b 100%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        overflow: hidden;

        &::after {
            position: absolute;
            content: "";
            width: 1910px;
            height: 2381px;
            top: -1150px;
            right: -620px;
            background-image: url("/local/html/img/about-bg.svg");
            background-repeat: no-repeat;
            background-size: cover;

            @media (max-width: 991px) {
                top: -660px;
                right: -550px;
            }

            @media (max-width: 767px) {
                right: -560px;
            }
        }

        @media (max-width: 991px) {
            padding: 60px 0;
        }

        &__desc {
            max-width: 994px;
            margin-bottom: 60px;
            @media (max-width: 991px) {
                margin-bottom: 40px;
            }

            &--title {
                font-weight: 400;
                font-size: 40px;
                line-height: 48px;
                margin-bottom: 20px;
                @media (max-width: 991px) {
                    font-size: 35px;
                    line-height: 42px;
                }
                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 34px;
                }
            }

            &--text {
                color: var(--india-typography-secondary);
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                opacity: 0.8;
                @media (max-width: 767px) {
                    font-size: 18px;
                    line-height: 25px;
                }

                p {
                    margin-bottom: 20px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__items {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto;
            grid-column-gap: 36px;
            grid-row-gap: 36px;

            @media (max-width: 991px) {
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 20px;
                grid-row-gap: 20px;
            }

            @media (max-width: 767px) {
                grid-template-columns: 1fr;
            }
        }

        &__item {
            padding: 25px 40px 35px;
            background: rgba(#ffffff, 0.05);
            border: 1px solid #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18);

            &--value {
                width: min-content;
                font-size: 70px;
                line-height: 1.2;
                color: #ffffff;
            }

            &--text {
                color: var(--india-typography-secondary);
                font-size: 24px;
                line-height: 1.4;
                opacity: 0.8;
            }
        }

        &__link-wrapper {
            margin-top: 30px;
        }

        &__arrow-link {
            position: relative;
            padding-right: 34px;
            color: #ffffff;

            svg {
                position: absolute;
                right: 0;
                top: 4px;
                transition: all 0.3s ease;
            }

            &:hover {
                color: var(--light-blue);
                text-decoration: none;

                svg {
                    right: -8px;
                }

                svg > path {
                    stroke: var(--light-blue);
                }
            }
        }
    }

    .footer-emirates {
        background-color: #1e2530;
        /* background: var(--india-bg-secondary); */
        padding: 60px 0 30px;

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__info {
            display: flex;
            justify-content: space-between;
            padding-bottom: 50px;

            @media (max-width: 991px) {
                flex-direction: column;
            }
        }

        &__logo {
            flex-shrink: 0;
            width: 230px;
            height: auto;

            img {
                width: 100%;
                margin-bottom: 20px;
            }

            @media (max-width: 991px) {
                margin-bottom: 40px;
            }

            @media (max-width: 767px) {
                width: 100%;
                height: auto;
            }
        }

        &__logo-global {
            text-align: center;
            display: block;
            font-size: 20px;
            font-weight: 600;
            background: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%), #B13737;
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }

        &__contacts {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto;
            grid-column-gap: 30px;
            grid-row-gap: 15px;
            width: calc(100% - 280px);

            @media (max-width: 991px) {
                width: 100%;
            }

            @media (max-width: 767px) {
                grid-template-columns: 1fr;
                grid-row-gap: 20px;
            }

            &--block {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: min-content;
                grid-row-gap: 15px;
            }

            &--item {
                .item {
                    &--title {
                        font-size: 18px;
                        font-weight: 600;
                        color: var(--india-typography-secondary);
                    }

                    &--text {
                        display: block;
                        min-height: 32px;
                        color: var(--india-typography-secondary);
                        opacity: 0.4;
                        font-size: 12px;
                        line-height: 16px;

                        @media (max-width: 767px) {
                            min-height: auto;
                        }
                    }
                }
            }
        }

        &__social {
            display: flex;
            align-items: flex-start;
            margin-left: -3px;

            a {
                display: inline-block;
                margin-right: 5px;
                padding: 3px;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        &__bottom {
            padding-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.4);

            @media (max-width: 767px) {
                flex-direction: column;
            }

            &--rights {
                display: flex;
                justify-content: space-between;

                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        &__authority {
            margin-bottom: 0;
        }

        &__reserved {
            margin: 0 30px 0 0;

            @media (max-width: 767px) {
                margin: 5px 0 5px;
            }
        }

        &__policy {
            color: rgba(255, 255, 255, 0.4);
            cursor: pointer;
        }

        &__download {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 210px;
            height: 40px;
            margin-bottom: 9px;
            padding: 0 10px;
            white-space: nowrap;
            background-color: #1e2530;
            font-weight: 700;
            font-size: 16px;
            color: #ffffff;

            &:hover {
                color: #ffffff;
                text-decoration: none;

                &::before {
                    background-image: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%);
                }
            }

            &::before {
                position: absolute;
                content: "";
                left: -2px;
                top: -2px;
                right: -2px;
                bottom: -2px;
                border-radius: 3px;
                background-image: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%);
                z-index: -1;
            }
        }
    }

    .emiratesForm.mainForm {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(#000000, 0.85);

        .mainForm__content {
            display: flex !important;
            max-width: 360px;
            margin: auto;
            min-height: auto;
        }

        .mainForm__title {
            background: var(--main-gradient);
            margin-bottom: 0;
            padding: 30px 30px 20px;
            color: white;
            max-width: 360px;
            width: 100%;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            flex-direction: column;

            &--subtitle {
                margin-top: 8px;
                font-size: 14px;
                line-height: 16px;
            }
        }

        .main-form-close {
            top: 10px;
            right: 10px;
        }

        form {
            margin-top: 0;
            background: white;
            width: 100%;
            max-width: 360px;
            padding: 25px 30px 50px;

            input {
                border: 1px solid #09101b;
                border-radius: 3px;
                color: var(--india-typography-primary);
            }

            .placeholder {
                color: var(--india-typography-primary);
                opacity: 0.4;

                &.error {
                    color: var(--red);
                    opacity: 1;
                }
            }

            .form__btn {
                font-size: 16px;
                line-height: 20px;
            }

            .error_submit {
                padding: 5px;
                margin-top: 16px;
            }
        }
    }

    .aeForm.mainForm {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(#000000, 0.85);
        overflow: hidden;

        .main-form-close {
            top: 20px;
            right: 20px;
        }
    }

    .modal-success {
        &__content {
            background-color: white;
            position: relative;
            padding: 40px 30px 50px;
            max-width: 360px;
            margin: auto;

            .title {
                color: var(--india-typography-primary);
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 5px;
                font-weight: 600;
                text-align: left;
            }

            .main-form-close {
                top: 10px;
                right: 10px;
            }

            .form__btn {
                width: 100%;
                margin-top: 24px;
            }
        }

        &.active {
            background-color: rgba(#000000, 0.85);
        }
    }

    .benefits {
        padding-top: 148px;
        padding-bottom: 107px;
        background-color: var(--india-bg-secondary);
        position: relative;
        overflow: hidden;

        @media (max-width: 991px) {
            padding-top: 70px;
            padding-bottom: 50px;
        }

        @media (max-width: 768px) {
            padding-top: 30px;
            padding-bottom: 20px;
        }

        &:after {
            position: absolute;
            content: "";
            width: 1516px;
            height: 1087px;
            top: -40px;
            left: 0;
            background-image: url(/local/html/img/adv-bg.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        &__title {
            max-width: 1068px;
            font-size: 40px;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 74px;

            @media (max-width: 767px) {
                margin-bottom: 30px;
                font-size: 28px;
                line-height: 34px;
            }
        }

        &__list {
            display: flex;
            justify-content: space-between;
            margin-bottom: 58px;

            @media (max-width: 767px) {
                flex-direction: column;
            }
        }

        &__item {
            color: #FFFFFF;
            max-width: 320px;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }

            @media (max-width: 767px) {
                margin-bottom: 30px;
                margin-right: 0;
                flex-direction: row;
                max-width: 450px;

                &:last-child {
                    margin-bottom: 0;
                }

            }
        }

        &__item-title {
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;
            text-align: center;
            margin-bottom: 10px;

            @media (max-width: 767px) {
                font-size: 16px;
                margin-bottom: 5px;
            }
        }

        &__item-subtitle {
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }

        &__img {
            width: 162px;
            height: 162px;
            background-color: white;
            position: relative;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            flex-shrink: 0;

            img {
                max-width: 52px;
                height: auto;
                object-fit: contain;
                z-index: 2;
            }

            &:before {
                content: "";
                position: absolute;
                background: #FFFFFF;
                box-shadow: 40px 40px 40px rgba(0, 0, 0, 0.12);
                width: 120px;
                height: 120px;
                border-radius: 50%;
            }

            @media (max-width: 768px) {
                width: 80px;
                height: 80px;
                margin-bottom: 0;
                margin-right: 20px;

                &:before {
                    width: 75px;
                    height: 75px;
                }

                img {
                    max-width: 30px;
                }
            }
        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;

            .hero__content--btn {
                background: white;

                span {
                    background: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%), #B13737;
                    color: transparent;
                    background-clip: text;
                    -webkit-background-clip: text;
                }
            }
        }
    }
}

.policy {
    max-width: 90vw;
    max-height: 90vh;
    min-height: auto;
    background: #FFFFFF;

    &__top {
        height: 54px;
        padding: 20px;
        display: flex;
        background: #09101B;
        color: white;
        position: relative;

        svg {
            margin-right: 10px;
        }
    }
}

.policy-ae {
    max-height: calc(90vh - 54px);
    padding-top: 60px;
    padding-bottom: 30px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #09101B;
        opacity: 0.8;
        border-radius: 3px;
    }

    h2, &__title {
        color: var(--india-typography-primary);
        font-size: 40px;
        line-height: 1.2;
        margin-bottom: 20px;
        font-weight: 600;

        @media (max-width: 991px) {
            font-size: 32px;
        }

        @media (max-width: 767px) {
            font-size: 20px;
        }
    }

    &__content {
        &--text {
            font-size: 24px;
            line-height: 1.3;

            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
    }
}

@keyframes progress {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}