.news-blog {
  padding-top: 60px;
  padding-bottom: 120px;

  .news {
    padding-bottom: 0;
  }

  .news-grid-item {
    height: 400px;
  }
}
