.introduction {
    &__list {
        margin: 0;
        padding: 0;
        padding-bottom: 10px;
        list-style: none;
        counter-reset: items;
    }

    &__el {
        position: relative;
        margin-bottom: 60px;
        padding-left: 75px;
        font-size: 1.3rem;
        counter-increment: items;

        &::before {
            position: absolute;
            content: "0" counter(items);
            top: calc(50% - 35px);
            left: 0;
            font-size: 50px;
            color: var(--light-green);
        }

        &::after {
            position: absolute;
            content: url('/local/html/img/steps-icon.svg');
            left: 25px;
            bottom: -53px;
        }

        &:last-child {
            margin-bottom: 0;

            .introduction__icon {
                display: none;
            }

            &::after {
                content: none;
            }
        }

    }

    &__icon {
        margin-top: 15px;
    }

    &__img-list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        border-left: 1px solid rgba(26, 28, 27, 0.2);

        @media(max-width: 992px) {
            flex-direction: row;
            justify-content: space-around;
        }
    }

    &__img {
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }

        @media(max-width: 992px) {
            margin-bottom: 0;
            padding: 5px;
        }
    }

    @media(max-width:992px) {
        &__img-list {
            border-left: none;
            align-items: center;
            margin-top: 40px;
        }
    }
}
