/* services-main */
.serv-link {
  text-decoration: none;
  border-bottom: none !important;
}

.services-main__item {
  min-height: 330px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;

  .icon {
    margin-bottom: 34px;
  }
}

.services-main_crm {
  .services-main__item {
    min-height: unset;
  }
}

.services-main_dark {
  .h4.mb-2 {
    font-weight: 600;
  }
}

.services-main__item p {
  margin-bottom: 0;
}

.services-main__item {
  @media (max-width: 768px) {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.services-main__item:nth-child(2n) {
  @media (max-width: 991px) {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.services-main__item:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.services-main__item:hover {
  background: rgba(34, 41, 46, 0.4);
}

.services-main_dark .services-main__item:hover {
  background: transparent;
}

.services-main__item span {
  transition: color 0.3s ease;
}

.services-main__item:nth-child(1):hover span {
  color: #269ec5;
}

.services-main__item:nth-child(2):hover span {
  color: #089883;
}

.services-main__item:nth-child(3):hover span {
  color: #359655;
}

.services-main__item:nth-child(4):hover span {
  color: #88ac54;
}

.services-main__element a {
  text-decoration: none !important;
}

/* services-block */
.services-block__hovered {
  .services-block__list {
    a {
      &:hover {
        text-decoration: none;

        span {
          color: var(--light-blue) !important;
        }
      }
    }
  }
}

.services-block__header {
  border-bottom: 1px solid var(--main-bg);
}

.services-block__item {
  border-bottom: 1px solid var(--line-dark-20);
  align-items: baseline;
}

.services-block__list {
  align-items: baseline;
}

.services-block__list a {
  border-bottom: 1px solid var(--line-dark-20);
}

.services-block__list div {
  margin-bottom: 1rem;
}

.services-main {
  &_full-xs {
    .services-main__item {
      background-color: transparent;
      /* border-right: 1px solid rgba(0, 0, 0, 0.2); */
    }
  }
}

.services-block__item {
  .arrow-link {
    &:hover {
      color: #25a2ca !important;
    }

    color: #1a1c1b !important;
  }
}

.btn-gr {
  background: -webkit-linear-gradient(89.85deg, #0b6ba7 0.1%, #96c15b 99.87%) !important;
  color: #fff !important;
}

.btn-gr-border {
  padding: 15px 30px;
  position: relative;
  z-index: 2;
  display: inline-block;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  text-align: center;
  background-color: transparent;
  background: var(--main-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 2px solid #008000;
  border-image-source: linear-gradient(89.85deg, #0b6ba7 0.1%, #96c15b 99.87%);
  border-image-slice: 2;
  overflow: hidden;
  font-size: 16px;

  &:hover {
    border-image-source: linear-gradient(89.85deg, #96c15b 0.1%, #0b6ba7 99.87%);

    &:after {
      background: linear-gradient(89.85deg, #96c15b 0.1%, #0b6ba7 99.87%);
    }
  }

  &:after {
    content: "";
    position: absolute;
    transition: 0.3s;
    width: 0;
    bottom: -1px;
    background: linear-gradient(89.85deg, #0b6ba7 0.1%, #96c15b 99.87%);
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: -1;
  }
}

.services {
  padding-bottom: 180px;

  &__top {
    position: relative;
    flex-direction: column;

    &--crm {
      padding-bottom: 26px !important;
    }

    &--corp,
    &--bitrix {
      padding-top: calc(168px + 120px);
      padding-bottom: 132px;
      margin-top: -168px;

      @media (max-width: 1640px) {
        padding-top: calc(168px + 32px) !important;
      }
      @media (max-width: 991px) {
        padding-top: 186px !important;
        padding-bottom: 60px;
        margin-top: -186px !important;
      }
      @media (max-width: 768px) {
        padding-top: 170px !important;
        padding-bottom: 64px;
        margin-top: -170px !important;
      }
      @media (max-width: 638px) {
        padding-top: 194px !important;
        margin-top: -194px !important;
      }
      @media (max-width: 576px) {
        padding-top: 200px !important;
        margin-top: -200px !important;
      }
      @media (max-width: 480px) {
        padding-top: 190px !important;
        margin-top: -190px !important;
      }
      @media (max-width: 353px) {
        padding-top: 208px !important;
        margin-top: -208px !important;
      }
      @media (max-width: 320px) {
        padding-top: 244px !important;
        margin-top: -244px !important;
      }

      .container {
        @media (min-width: 768px) {
          padding: 0;
        }
      }

      .bg-img__img {
        width: 850px;
        height: 853px;
        top: auto;
        bottom: -268px;
        right: 88px;

        @media (max-width: 1640px) {
          width: 600px;
          height: 600px;
          bottom: -120px;
        }

        @media (max-width: 1440px) {
          width: 40%;
          height: 600px;
          top: 250px;
        }

        @media (max-width: 992px) {
          position: static;
          width: 100%;
          height: 500px;
        }

        @media (max-width: 768px) {
          position: static;
          width: 100%;
          height: 300px;
        }
      }

      .services-top {
        &__title {
          @media (max-width: 991px) {
            margin-top: 16px !important;
            margin-bottom: 16px !important;
          }
        }

        &__description {
          font-size: 1.125rem;

          @media (max-width: 991px) {
            margin-bottom: 24px !important;
          }
        }
      }
    }
  }

  &__top,
  &-list {
    .breadcrumbs li:not(:last-child)::after {
      color: var(--white_opacity);
    }
  }

  &-functions {
    padding-top: 32px;
    padding-bottom: 180px;
    @media (max-width: 1199px) {
      padding-bottom: 64px;
    }

    &__video {
      width: 100%;
      max-width: 730px;
      margin: 0 auto 96px;
      @media (max-width: 1440px) {
        margin: 0 auto 32px;
      }

      .video {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__item {
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
      }

      p {
        opacity: 0.8;
      }
    }

    &__mobile {
      margin-top: 122px;

      @media (max-width: 1199px) {
        margin-top: 64px;
      }

      .img {
        @media (max-width: 991px) {
          margin-bottom: 32px;
        }

        img {
          @include colorShadows();
          max-width: 100%;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h4 {
          font-weight: 600;
        }
      }
    }
  }

  &-about {
    padding: 142px 0 180px;

    @media (max-width: 1199px) {
      padding: 64px 0 64px;
    }

    &__info {
      justify-content: space-between;
      padding: 80px 0 130px;

      @media (max-width: 1199px) {
        padding: 32px 0 48px;
      }
      @media (max-width: 991px) {
        padding: 0 0 32px;
      }

      .img {
        @media (max-width: 991px) {
          margin-top: 16px;
          margin-bottom: 24px;
        }

        img {
          max-width: 100%;
          @include colorShadows();

          &.no-filter {
            filter: none;
          }

          @media (max-width: 991px) {
            display: block;
            margin: 0 auto;
          }
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          /* max-width: 472px; */
          @media (max-width: 991px) {
            max-width: 100%;
          }

          .op {
            opacity: 0.5;
          }
        }

        .under-heading {
          font-size: 1.5rem;
          opacity: 0.5;

          @media (max-width: 768px) {
            font-size: 1.3rem;
          }

          @media (max-width: 576px) {
            font-size: 1.125rem;
          }
        }
      }

      &--bitrix {
        padding: 0;
      }
    }

    &__rewards {
      .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 991px) {
          margin-top: 48px;
        }
      }

      .number {
        display: flex;
        justify-content: center;
        width: 72px;
        height: 68px;
        font-size: 32px;
        line-height: 60px;
        font-weight: 600;
        color: #ffffff;
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(../img/reward_bg.svg);
        margin-bottom: 42px;
        @media (max-width: 991px) {
          margin-bottom: 16px;
        }
      }

      .text {
        margin-bottom: 16px;
      }

      .name {
        font-weight: 600;
        letter-spacing: 0.11em;
      }
    }

    &--bitrix {
      margin-bottom: 90px;
      padding: 0;
    }
  }

  &-steps {
    padding-top: 155px;
    padding-bottom: 80px;

    @media (max-width: 1199px) {
      padding-top: 72px;
      padding-bottom: 40px;
    }
    @media (max-width: 768px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    &__content {
      justify-content: space-between;
    }

    &__list {
      margin: 24px 0 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      counter-reset: steps;
      max-width: 540px;
    }

    &__item {
      position: relative;
      width: calc(50% - 16px);
      margin-top: 20px;
      list-style: none;
      counter-increment: steps;
      padding: 0 0 0 28px;
      @media (max-width: 540px) {
        width: 100%;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 16px;
        color: var(--green);
        background: -webkit-linear-gradient(0deg, #0b6ba7, #96c15b, #0b6ba7);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        content: counter(steps) ".";
      }

      .title {
        margin-bottom: 5px;
      }

      .description {
        opacity: 0.5;
      }
    }

    &__rewards {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-left: 1px solid var(--white_opacity);
      padding-top: 24px;
      padding-bottom: 24px;
      @media (max-width: 991px) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        border-left: 0;
        border-top: 1px solid var(--white_opacity);
        margin-top: 24px;
        padding: 24px 0 0 !important;
      }
      @media (max-width: 767px) {
        padding: 24px 15px 0 !important;
      }

      img {
        width: 100%;
        max-width: 170px;
        margin-top: 48px;
        @media (max-width: 991px) {
          width: auto;
          margin-top: 0;
          margin-right: 16px;
          margin-bottom: 24px;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &--bitrix {
      padding: 0;

      .services-steps {
        &__list {
          max-width: 100%;
          margin-bottom: 65px;
        }
      }

      .text {
        @media (max-width: 991px) {
          margin-bottom: 65px;
        }
      }

      .img {
        @media (max-width: 991px) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  &-integrations {
    margin-bottom: 100px;
  }

  &-advantages {
    padding-top: 80px;
    padding-bottom: 140px;

    @media (max-width: 1199px) {
      padding-top: 72px;
      padding-bottom: 40px;
    }

    @media (max-width: 768px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      @media (max-width: 991px) {
        margin: 0;
      }
    }

    &__item {
      .title {
        transition: color 0.3s ease;
      }

      &:nth-child(1):hover .title {
        color: #269ec5;
      }

      &:nth-child(2):hover .title {
        color: #089883;
      }

      &:nth-child(3):hover .title {
        color: #359655;
      }

      &:nth-child(4):hover .title {
        color: #88ac54;
      }

      @media (max-width: 576px) {
        margin-top: 1rem;
      }

      .icon {
        margin-bottom: 34px;
        height: 60px;

        @media (max-width: 991px) {
          margin-bottom: 24px;
        }
      }

      .title {
        font-weight: 600;
        margin-bottom: 16px;
      }
    }

    &--bitrix {
      padding: 0;
    }
  }

  &-results {
    padding-top: 150px;

    @media (max-width: 1199px) {
      padding-top: 72px;
    }

    @media (max-width: 768px) {
      padding-top: 32px;
    }

    &__title {
      @media (max-width: 991px) {
        margin-bottom: 0 !important;
      }
    }

    &__list {
      position: relative;
      margin-top: 85px;
      padding: 0 0 48px 0;
      list-style: none;
      @media (max-width: 991px) {
        margin-top: 0;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;
        font-size: 32px;
        line-height: 42px;
        font-weight: 600;
        margin-bottom: 16px;

        &::after {
          content: "";
          width: 16px;
          height: 24px;
          flex-shrink: 0;
          margin-left: 16px;
          -webkit-background-size: contain;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url(../img/services-results__arrow.svg);
        }

        &.down {
          &::after {
            transform: rotate(180deg);
          }
        }

        @media (max-width: 768px) {
          font-size: 1.75rem;
        }

        @media (max-width: 576px) {
          font-size: 1.375rem;
        }
      }

      @media (max-width: 991px) {
        margin-top: 32px;
      }
    }

    &__logos {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: -8px;

      img {
        max-width: 74px;
        margin: 8px;
      }
    }

    .clients-main__items {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
    }

    .container {
      margin-bottom: 0;
    }
  }

  &-glance {
    &__item {
      margin-bottom: 105px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: 991px) {
        margin-bottom: 50px;
      }
    }

    .row {
      &--img-right {
        .img {
          order: 1;
        }

        @media (max-width: 991px) {
          .img {
            order: unset;
          }
        }
      }
    }

    .img {
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
    }

    .h4 {
      margin-bottom: 24px;
    }

    ul {
      margin-bottom: 40px;
    }

    li {
      margin-bottom: 10px;
    }

    ul li:before {
      background: linear-gradient(89.85deg, #0b6ba7 0.1%, #96c15b 99.87%);
    }

    &.standard-pb {
      padding-bottom: 110px;
    }
  }

  &__arrow-link {
    position: relative;
    padding-right: 34px;

    svg {
      position: absolute;
      right: 0;
      top: 4px;
      transition: all 0.3s ease;
    }

    &:hover {
      color: var(--light-blue);
      text-decoration: none;

      svg {
        right: -8px;
      }

      svg > path {
        stroke: var(--light-blue);
      }
    }
  }
}
