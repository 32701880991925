.f-serv {
    color: #fff !important;

    &__title {
        font-size: 18px;
        margin-bottom: 25px;
        display: inline-block;
        color: #fff !important;

        &:hover {
            color: var(--light-blue) !important;
        }
    }

    &__label {
        margin-bottom: 10px;
        display: block;
        color: #ffffff;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -25px;
        justify-content: space-between;
    }

    &__item {
        margin-bottom: 25px;
        width: 45%;
        padding-right: 25px;
    }

    &__element {
        display: block;
        color: rgba(#ffffff, 0.4);
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        color: rgba(#ffffff, 0.4);
    }

    @media (max-width: 991px) {
        padding-top: 20px;
    }

    @media (max-width: 768px) {
        &__list {
            justify-content: start;
            flex-direction: column;
        }

        &__item {
            width: 100%;
        }
    }
}

.bg-lines {
    background-size: contain;
}
