.integrations {
  &__list {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0 15px;
    list-style: none;

    @media(max-width: 991px) {
      justify-content: start;
      align-items: flex-start;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;

    @media(max-width: 991px) {
      width: 50%;
      height: 90px;
      margin-bottom: 20px;
      justify-content: space-between;
    }

    @media(max-width: 768px) {
      padding-right: 2rem;
    }
  }

  &__image-wrapper {
    margin-bottom: 10px;
  }

  span {
    opacity: 0.6;
  }
}
