.fade {
    opacity: 0;
    transition: opacity .3s ease;
    &.show {
        opacity: 1;
    }
}

.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    text-align: center;
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -.36em;
    }

    &__dialog {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 500px;
        flex: none;
        min-width: 0;
        margin: 0 auto;
        transform: translate(-50%, -50%);
        //background: $modal-bg;
        //border-radius: $brd-rad-box $brd-rad-btn;
        padding: 42px;

        //center modal dialog
        display: inline-block;
        text-align: left;
        vertical-align: middle;
    }

    &__close {
        position: absolute;
        z-index: 1000;
        right: 10px;
        top: 45px;
        font-size: 24px;
        //color: $error-color;
        width: 15px;
        height: 15px;
        line-height: 40px;
        text-align: center;
        border-radius: 0;
        fill: #fff;

        .link-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }

        &:hover {
            color: white;
        }
    }

    &_centered {
        text-align: center;

        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -.36em;
        }

        .modal__dialog {
            display: inline-block;
            text-align: left;
            vertical-align: middle;
        }
    }

    &.fade .modal__dialog {
        transition: .3s ease;
    }

    &.in {
        display: flex;

        .modal__dialog {
            opacity: 1;
            transform: none;
        }
    }

    .modal-open & {
        overflow-x: hidden;
        overflow-y: auto;
    }

    @media (max-width:767px) {
        .modal__dialog {
            //padding-right: $container-gutter-xs;
            //padding-left: $container-gutter-xs;
            max-width: 100%;
            border-radius: 0;
        }
    }
}


.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    //background: $modal-backdrop-bg;
    transition: .3s ease;

    &.fade {
        opacity: 0;
    }

    &.in {
        opacity: 1;
    }
}
.modal-vac__btn {
    display: block;
    width: 100%;
}
.modal-vac {
    &__title {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 5px;
        text-align: center;
    }
    &__text {
        text-align: center;
    }
}
.modal-vac__body {
    max-width: 825px;
    width: 100%;
    margin: 0 auto;
}
.modal-vac form {
    max-width: 350px;
    width: 100%;
    margin-top: 40px;
    padding-bottom: 40px;
}
.modal-vac__note {
    margin-top: 15px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
}

.modal-vac,
.modal-ok-subscribe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main-bg);
    z-index: 2000;
    visibility: visible;
    opacity: 1;
    transition: .3s ease;
    overflow-x: hidden;
    overflow-y: auto;
    .nx-actions {
        text-align: center;
    }
    &.hidden {
        visibility: hidden;
        opacity: 0;
        z-index: -1;
    }
    &__btn {
        position: relative;
        font-size: 16px !important;
        padding: 10px 20px;
        color: #fff !important;
        text-decoration: none !important;
        border: none;
        border-radius: 3px;
        span {
            position: relative;
            z-index: 10;
        }
        &:hover {
            color: #fff !important;
            &:before {
                opacity: 1;
                visibility: visible;
            }
            &:after {
                opacity: 0;
                visibility: hidden;
            }
        }
        &:after,
        &:before {
            border-radius: 3px;
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            visibility: hidden;
            opacity: 0;
            transition: .3s ease;
        }
        &:after {
            background: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%);
            visibility: visible;
            opacity: 1;
            width: 101%;
        }

        &:before {
            background: linear-gradient(89.85deg, #96C15B 0.1%, #0B6BA7 99.87%);
        }
    }
    &__body {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 80px 10px 0;
    }
    .title {
        font-size: 32px !important;
        margin-bottom: 40px;
    }
    .modal-icon {
        margin-bottom: 20px;
    }
}

.modal-ok-subscribe {
    &__body {
        text-align: center;
    }

    &__btn {
        padding: 10px 60px;
    }
}

.modal-success__close {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 45px;
    right: 10px;
    fill: #fff;
}
.modal-success__close .link-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
