/* main portfolio */
.portfolio__grid {
    position: relative;
    z-index: 5;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 250px;
    grid-gap: 10px;
    grid-auto-flow: dense;
}

.portfolio__subtitle {
    font-size: 24px;

    @media (max-width: 576px) {
        font-size: 20px;
    }
}

.portfolio-top {
    position: relative;
    z-index: 2;
}

.portfolio-grid__item_big {
    grid-row: auto / span 2;
    grid-column: auto / span 2;
}

.portfolio-grid__item {
    overflow: hidden;
    &.item-big {
        grid-row: auto / span 2;
        grid-column: auto / span 2;
    }
    &:first-child {
        grid-row: auto / span 2;
        grid-column: auto / span 2;
    }
    /* transition: all .3s ease; */
}

.portfolio-grid__item p, .portfolio-grid__item span.h4, .portfolio-grid-item__label {
    color: #fff !important;
}

.portfolio-grid__item:hover {
    color: #fff !important;
}

.portfolio-grid__item .portfolio-grid-item__info {
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease;
}

.portfolio-grid__item:hover .portfolio-grid-item__info {
    visibility: visible;
    opacity: 1;
}

.portfolio-grid-item__inner {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    top: 0;
    transition: all .3s ease;
}

.portfolio-grid__item img {
    transition: .3s ease;
}
.portfolio-grid__item:hover img {
    transform: scale(1.2);
}
.portfolio-grid__item:hover .portfolio-grid-item__inner {
    background: rgba(0, 0, 0, .55);
}

.portfolio-grid-item__labels {
    bottom: 0;
    left: 0;
}

.portfolio-grid__item:hover .portfolio-grid-item__labels div:last-child {
    opacity: 0;
}

.portfolio-grid-item__label:first-child {
    background: rgba(0, 0, 0, .4);
}

.portfolio-grid-item__label:last-child {
    background: rgba(0, 0, 0, .75);
}

.portfolio-grid__item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* portfolio */
.portfolio-filter,
.news-filter {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--line-dark-20);
    .pf-filter__arrow {
        height: 58px;
    }
    @media (max-width: 767px) {
        padding-bottom: 0;
        border-bottom: none;
        .pf-filter__arrow {
            display: none !important;
            height: 30px;
        }
    }
}

.arrow-container {
    position: relative;
    overflow: hidden;
}

.pf-filter {
    position: relative;
    button:not(:active) {
        color: var(--gray_opacity);
    }
    button {
        background: none;
        border: none;
        outline: none;
        white-space: nowrap;
        color: var(--main-bg);
        font-size: 20px;
        &.active-filter {
            color: var(--main-bg) !important;
        }
        &.pf-filter-btn-active {
            color: var(--main-bg) !important;
        }
    }
    &__arrow {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50px;
        height: 38px;
        background: -webkit-gradient(linear, right top, left top, from(#FFFFFF), to(rgba(255, 255, 255, 0)));
        background: -o-linear-gradient(right, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 10;
        display: none !important;
        svg {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 15px;
            height: 15px;
            transform: translate(-50%, -50%);
        }
    }
}

.pf-filter-top {
    display: flex;
}

.pf-filter__list-top {
    display: flex;
}

.pf-filter-container {
    position: relative;
    padding-bottom: 10px;
    margin-top: 35px;
    .pf-filter__list {
        display: flex;
        position: relative;
        z-index: 2;
        background: #ffffff;
        .filter-btn {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                display: block;
                width: 100%;
                height: 100%;
                background: rgba(34, 41, 46, 0.05);
            }
            &:first-child {
              &::after {
                  border-radius: 50px 0 0 50px;
              }
            }
            &:last-child {
                &::after {
                    border-radius: 0 50px 50px 0;
                }
            }
        }
    }
}

.portfolio-filter button:not(:active) {
    color: var(--gray_opacity);
}

.portfolio-filter button.active-filter {
    color: var(--main-bg);
}

.portfolio-filter button {
    background: none;
    border: none;
    outline: none;
    white-space: nowrap;
}

.portfolio-white-block img {
    width: 100%;
    object-fit: contain;
    max-height: 300px;
}

.portfolio-white-block__inner {
    align-items: flex-start;
}
