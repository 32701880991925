/* Typo */

body {
    font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    background-color: var(--deep-gray);
    line-height: 1.42;
    color: #fff;
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6,button,.button {
    font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #fff;
    font-weight: normal;
}

.h4_dk {
    color: var(--white_opacity);
}

.first-header {
    letter-spacing: .11em;
}

@media(min-width: 769px) {
    h1,.h1 {
        font-size: 3rem; /* 48px */
        line-height: 1.2;
    }
    h2,.h2 {
        font-size: 2rem; /* 32px */
    }
    h3,.h3 {
        font-size: 1.5rem; /* 24px */
    }
    h2,.h2,h3,.h3 {
        line-height: 1.3;
    }
    h4,.h4 {
        font-size: 1.25rem; /* 20px */
    }
    h5,.h5,h6,.h6,button,.button {
        font-size: 1.125rem;  /* 18px */
    }
    h4,.h4,h5,.h5,h6,.h6,button,.button,p {
        line-height: 1.42;
    }
}

@media(max-width:768px) {
    h1,.h1 {
        font-size: 2rem; /* 32px */
    }
    h2,.h2 {
        font-size: 1.75rem; /* 28px */
    }
    h3,.h3 {
        font-size: 1.5rem; /* 24px */
    }
    h4,.h4 {
        font-size: 1.25rem; /* 20px */
    }
}

@media(max-width:576px) {
    body {
        font-size: 14px;
    }
    h1,.h1 {
        font-size: 1.5rem; /* 24px */
    }
    h2,.h2 {
        font-size: 1.375rem; /* 22px */
    }
    .h2-xs {
        font-size: 1.1rem;
    }
    h3,.h3 {
        font-size: 1.25rem; /* 20px */
    }
    h4,.h4,h5,.h5,h6,.h6 {
        font-size: 1.125rem; /* 18px */
    }
    button,.button {
        font-size: 1rem;
    }
}

