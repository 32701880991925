
.chess-grid > .row:nth-child(odd) > div:first-child {
    order: 0;
}

.chess-grid > .row:nth-child(odd) > div:last-child {
    order: 1;
}

.chess-grid > .row:nth-child(even) > div:first-child {
    order: 1;
}

.chess-grid > .row:nth-child(even) > div:last-child {
    order: 0;
}

@media (max-width: 991.98px) {
    .chess-grid > .row > div {
        order: unset !important;
    }
}
