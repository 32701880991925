.v-filter {
    &__title {
        font-size: 32px !important;
        margin-right: 20px;
    }
    
    &__line {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }
    
    &__list {
        display: flex;
        //width: 100%;
        margin: 0;
    }
    
    &__link {
        white-space: nowrap;
        color: rgba(26, 28, 27, 0.6) !important;
        text-decoration: none !important;
        &.active{
            color: #ffffff !important;
            sup {
                color: #ffffff;
            }
        }
        sup {
            opacity: 1;
            font-weight: 600;
            text-decoration: none !important;
            color: var(--main-bg);
        }
    }
    
    &__item {
        position: relative;
        padding-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    
    &__cnt {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
        font-weight: 600;
    }
    
    @media (max-width: 767px) {
        &__line {
            flex-direction: column;
            align-items: flex-start;
        }
        
        &__title {
            line-height: 35px !important;
            margin-bottom: 15px;
        }
    }
}