.functions {
    &__head {
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;
        background-color: transparent;
        padding-bottom: 52%;
    }
    
    &__item {
        margin-bottom: 3rem !important;
    }
    
    &__title {
        margin-bottom: 5px;
        font-size: 22px;
    }
    
    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
    
    @media(max-width:767px) {
        &__item {
            margin-bottom: 3rem !important;
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}