$list-slider: (
    icon-fz: 30px,
    icon-w: 22px,
    icon-h: 35px,
);
.nx-list-slider {
    position: relative;

    margin-bottom: 15px;

    &__slider {
        overflow: hidden;
        .list-items__el {
            margin-bottom: 0;
        }
        .list-items__head {
            padding-bottom: 99%;
            border: none;
            margin-bottom: 20px;
        }
        &.list-items {
            margin-bottom: 0;
        }
    }

    &__prev,
    &__next {
        position: absolute;
        top: 29%;
        width: 45px;
        height: 45px;
        text-align: center;
        //fill: rgba($c-brown, .35);
        //transition: all $tr-time $tr-type;
        cursor: pointer;
        z-index: 10;
        border-radius: 50%;
        &-icon {
            //@extend %centered-absolute;
            display: block;
            font-size: map_get($list-slider, icon-fz);
            line-height: 1;
            width: map_get($list-slider, icon-w);
            height: map_get($list-slider, icon-h);
        }
        &:hover {
            //fill: rgba($c-brown, .7);
        }
        &.swiper-button-disabled {
            &,
            &:hover {
                //fill: rgba($c-brown, .15);
                cursor: default;
            }
        }
    }
    &__prev,
    &__next {
        cursor: pointer;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            background-color: rgba(white, 0.7);
        }
        &:after {
            display: block;
            content: url("data:image/svg+xml; utf8, <svg width='26' height='13' viewBox='0 0 26 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.485352 6.14992L24.4854 6.14992M24.4854 6.14992L18.8246 0.706299M24.4854 6.14992L18.8246 11.9063' stroke='black' stroke-opacity='0.5' stroke-width='1.5'/></svg>");
            line-height: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1rem;
            margin: 0;
            padding: 0;
        }
    }
    &__prev {
        left: -55px;
        transform: scaleX(-1);
    }
    &__next {
        right: -55px;
    }

    &_overflow {
        height: 250px;
        .nx-list-slider__slider {
            height: 100%;
        }
        .nx-list-slider__link {
            display: block;
            width: 100%;
        }
        .nx-list-slider__img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .nx-list-slider__prev {
            left: 0;
        }
        .nx-list-slider__next {
            right: 0;
        }
        .nx-list-slider__prev,
        .nx-list-slider__next {
            top: 45%;
        }
    }

    &_lg {
        height: 350px;
    }

    &_industries {
        .swiper-slide {
            background-size: cover;
        }

        .depart-main-item__inner {
            @media (max-width: 768px) {
                height: 92% !important;
            }
        }

        .nx-list-slider__prev,
        .nx-list-slider__next {
            display: none;

            @media (max-width: 767px) {
                display: block;
            }
        }

        @media (min-width: 768px) {
            height: auto;

            .swiper-wrapper {
                display: flex;
                flex-wrap: wrap;
            }

            .swiper-slide {
                flex: 0 0 50%;
                max-width: 50%;
                height: 250px;
            }
        }

        @media (min-width: 992px) {
            .swiper-slide {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
            }
        }
    }

    &_portfolio {
        @media (min-width: 768px) {
            height: auto;

            .swiper-wrapper {
                position: relative;
                z-index: 5;
                display: grid;
                grid-auto-rows: 250px;
                grid-gap: 10px;
                grid-auto-flow: dense;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(auto-fit, 225px);
            }

            .portfolio-grid-item__info {
                visibility: hidden;
                opacity: 0;
                transition: all 0.3s ease;
            }

            .portfolio-grid-item__inner {
                position: absolute;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
            }

            .swiper-slide:first-child {
                grid-row: auto/span 2;
                grid-column: auto/span 2;
            }

            .swiper-slide {
                overflow: hidden;
                color: #ffffff;

                img {
                    transition: all 0.3s ease;
                }

                &:hover {
                    img {
                        transform: scale(1.2);
                    }

                    .portfolio-grid-item__inner {
                        background: rgba(0, 0, 0, 0.55);
                    }

                    .portfolio-grid-item__info {
                        visibility: visible;
                        opacity: 1;
                        color: #ffffff;
                    }

                    .portfolio-grid-item__labels div:last-child {
                        opacity: 0;
                    }
                }
            }



            .nx-list-slider__prev,
            .nx-list-slider__next {
                display: none;
            }
        }

        @media (min-width: 992px) {
            .swiper-wrapper {
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(auto-fit, 210px);
            }
        }

        @media (min-width: 1201px) {
            .swiper-wrapper {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }

    &_news {
        @media (min-width: 768px) {
            height: auto;
        }
    }

    @media (max-width: 768px) {
        display: block;
    }
}

.dn-xs {
    @media (max-width: 768px) {
        display: none !important;
    }
}
