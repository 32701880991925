header.header {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    z-index: 1000;
    width: 100%;
    padding: 2rem 1rem !important;
    &.in-move {
        position: fixed;
        top: 0;
        padding: 30px 0 !important;
        transform: translateX(-50%) translateY(-10px);
        background-color: #1A1C1B !important;
        .bx-panel & {
            top: 39px;
        }
        .header__descriptor {
            display: none;
        }
    }
}
.header {
    &__lang-xs{
        display: none;
        .enabled & {
            @media (max-width: 767px) {
                display: block;
                position: absolute;
                right: 60px;
                .nav__lang {
                    margin: 0 !important;
                    margin-left: 10px !important;
                    position: relative;
                    top: 3px;
                }
            }
            @media (max-width: 375px) {
                right: 50px;
            }
        }
    }
}
.in-move {
    .header__nav {
        padding-top: 0 !important;
    }
}
.header__project {
    font-size: 16px !important;
    padding: 10px 20px !important;
    color: #fff !important;
    border-radius: 3px;
    position: relative;
    span {
        position: relative;
        z-index: 10;
    }
    &:hover {
        color: #fff !important;
        &:before {
            opacity: 1;
            visibility: visible;
        }
        &:after {
            opacity: 0;
            visibility: hidden;
        }
    }
    &:after,
    &:before {
        border-radius: 3px;
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 101%;
        height: 102%;
        visibility: hidden;
        opacity: 0;
        transition: .3s ease;
    }
    &:after {
        background: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%);
        visibility: visible;
        opacity: 1;
        width: 101%;
    }

    &:before {
        background: linear-gradient(89.85deg, #96C15B 0.1%, #0B6BA7 99.87%);
    }

    @media(max-width:992px) {
        display: none !important;
    }
}
.header-nav__links {
    margin-right: 40px;
    @media  (max-width: 767px) {
        margin-right: 0;
    }
}
.header_dark {
    color: var(--light-gray);
}
.header__up {
    border-bottom: 1px solid rgba(255,255,255,.1);
    .in-move & {
        display: none !important;
    }
}

.header__up a {
    color: var(--white_opacity);
}

.header a:hover, .header a:focus, aside a:hover {
    color: var(--light-blue);
}

.header__up a.active {
    color: #fff;
}
.nav__msg {
    display: none;
}

.header-aside {
    display: flex;
    padding: 7rem 7rem 40px 7rem;
    @media(max-width:1200px) {
        padding: 3rem 3rem 40px 3rem;
    }
    @media screen and (max-width: 991px) {
        flex-direction: column;
        overflow: scroll;
    }
}
.aside-desktop {
    @media screen and (max-width: 991px) {
        display: none;
    }
}
.aside-mob {
    display: none;
    @media screen and (max-width: 991px) {
        margin-top: 48px;
        display: block;
    }
}
aside {
    min-width: 320px;
    width: 50%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    transform: translate3D(125%,0,0);
    transition: transform .3s ease;
    z-index: 2000;
}
aside.active {
    transform: translate3D(0,0,0);
}
.aside-footer__icons a {
    opacity: .75;

    img {
        height: 18px;
    }
}
.aside-footer__icons a:hover {
    opacity: 1;
}
.aside__close {
    top: 50px;
    right: 50px;
    z-index: 1000;
    @media(max-width:1200px) {
        top: 15px;
        right: 15px;
    }
    @media screen and (max-width: 991px) {
        top: 45px;
        right: 45px;
    }
}

.header__descriptor {
    font-size: 14px;
    background: var(--main-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
}

.nav__lang {
    margin-left: 2rem;
}
.nav__hamb {
    display: none;
}
.header-nav__logo-move {
    display: none !important;
    .in-move &{
        display: block !important;
    }
}
.header-nav__logo-top {
    .in-move &{
        display: none;
    }
}
.header-up__list a {
    letter-spacing: .11em;
}
.header-nav__logo img {
    width: 180px;
}
.header.enabled .header-nav__logo img {
    width: 136px;
}

@media(max-width:992px) {
    .header a:not(:last-of-type) {
        margin-right: 0.5rem !important;
    }
    .header__descriptor {
        font-size: 1.2rem;
    }
    .nav__lang {
        margin-left: 0;
    }
    .nav__link:nth-child(n+4) {
        display: none;
    }
}
.nav__link {
    color: #fff !important;
    &.active {
        color: var(--light-blue) !important;
    }
    &:hover {
        color: var(--light-blue) !important;
    }
}
@media(max-width:768px) {
    .header__up {
        flex-wrap: wrap;
    }
    .header-nav__logo {
        position: relative;
    }
    .header-nav__links {
        margin-left: 0 !important;
        justify-content: space-between;
        display: flex;
    }
    .header a:not(:last-child) {
        margin-right: 1rem !important;
    }
    .header__descriptor {
        font-size: 1rem;
        width: 300px;
    }
    .nav__dots,.nav__link {
        display: none;
    }
    .nav__hamb {
        display: block;
        padding-top: 0.25rem;
        margin-left: 1rem;
    }
    .header-nav-links__list {
        display: flex;
        align-items: flex-start;
    }
}

@media(max-width:576px) {
    .header-up__list {
        width: 100%;
        margin-top: 1rem !important;
    }
    aside {
        width:100%;
    }
}

@media(max-width:480px) {
    .header__descriptor {
        font-size: 0.8rem;
        width: 250px;
        display: block;
    }
    .header-up__list a {
        font-size: 0.75rem;
    }
    .header.enabled .nav__hamb {
        top: -40px;
    }
    .header.enabled .nav__msg {
        top: -38px;
    }
}

@media(max-width:375px) {
    .header-up__list {
        display: flex;
        flex-wrap: wrap;
    }
}

@media(max-width:320px) {
    .header-nav-links__list {
        flex-direction: column;
        justify-content: center;
    }
    .nav__lang {
        margin: 1rem 1.25rem;
        order: 1;
    }
}
#header {
    .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
.header.enabled {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    transform: translateX(0);
    background: var(--deep-gray);
    z-index: 11;
    padding: 1rem !important;
    .bx-panel & {
        top: 39px;
    }
}
.header.enabled .header__up {
    justify-content: start !important;
}

.header__nav {
    flex-wrap: wrap;
    @media (max-width:991px) {
        flex-wrap: nowrap;
    }
}
.header.enabled .header__nav {
    padding: 0 !important;
}
.header.enabled .header-nav__logo {
    margin-right: 5px;
}
.header.enabled .nav__hamb, .header.enabled .nav__msg {
    position: absolute;
    top: -39px;
    right: 0;
    display: block;
}
.header.enabled .nav__msg {
    right: 50px;
    top: -40px;
    display: none;
}
.header.enabled .header-up__list a:not(.active), .header.enabled .header__descriptor, .header.enabled .header-nav-links__list a, .header.enabled .nav__dots {
    display: none;
}

@media(max-width:576px) {
    .text-uppercase.no-underline.text-uppercase.active {
        .enabled & {
            position: absolute;
            transform: translateY(-50%);
            left: 165px;
        }
    }
    .header.enabled .header-up__list {
        width: auto;
        margin-top: 0.5rem !important;
        margin-left: auto;
        right: 100px;
    }
}

@media(max-width:375px) {
    .header.enabled .nav__hamb {
        top: -38px;
    }
    .header.enabled .nav__msg {
        display: none;
        top: -35px;
    }
}
#contact-page {
    margin-top: 50px;
}
.h-serv {
    display: flex;
    flex-direction: column;
    margin-bottom: -30px;
    padding-left: 40px;

    &__caption {
        margin-bottom: 15px;
    }
    &__element {
        max-width: 400px;
        width: 100%;
    }
    &__item {
        width: 100%;
        color: #fff !important;
        margin-bottom: 45px;
        padding-right: 20px;
    }
    &__title {
        font-size: 18px;
        margin-bottom: 15px;
        display: block;
        color: #fff !important;
        &:hover {
            text-decoration: none !important;
            color: var(--light-blue) !important;
        }
    }
    &__link {
        display: block;
        font-size: 18px;
        margin-bottom: 15px;
        color: rgba(#ffffff, 0.4);

        &:hover {
            text-decoration: none;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__icon {
        display: none;
    }

    @media(max-width:1200px) {
        &__title,
        &__link {
            font-size: 16px;
        }
    }

    @media(max-width: 991px) {
        padding-left: 0;
        &__item {
            width: 100%;
            padding-right: 0;
        }
        &__title {
            &:focus,
            &:hover {
                color: #fff !important;
                text-decoration: none !important;
            }
            &.active {
                .h-serv__icon{
                    transform: translateY(-50%) scale(-1);
                }
            }
        }
        &__title {
            position: relative;
            padding-right: 15px;
        }
    }
}

a:active {
    color: #FFFFFF !important;
}

.footer-city-item__name.h4 {
    font-size: 1.1rem;
}
