.nx-section {
    &_page {
        padding-top: 60px;
        padding-bottom: 100px;
    }
    &__item {
        margin-bottom: 60px;
    }
    
    &__head {
        margin-bottom: 50px;
        .nx-section__title {
            font-size: 50px;
            margin-bottom: 20px;
        }
        
        .nx-section__text {
            font-size: 21px !important;
        }
    }
    
    &__item-lg {
        margin-bottom: 80px;
    }
    
    &__item-sm {
        margin-bottom: 40px;
    }
    @media(max-width:767px) {
        &_page {
            padding-top: 40px;
            padding-bottom: 20px;
        }
        
        &__item {
            margin-bottom: 40px;
        }
        
        &__item-small {
            margin-bottom: 30px;
        }
    }
}