.video {
    height: 410px;
    iframe {
        width: 100%;
        height: 100%;
    }
    &__link {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        position: relative;
        padding: 40px;
        display: flex;
        align-items: flex-end;
        transition: all .3s ease;
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(360deg, rgba(39, 40, 40, 0.04) 0%, rgba(39, 40, 40, 0.599076) 48.27%, #1A1C1B 100%), linear-gradient(0deg, rgba(29, 33, 35, 0.52), rgba(29, 33, 35, 0.52));
            z-index: 1;
        }
        &:hover {
            text-decoration: none;
            color: var(--light-blue) !important;
            background-size: 110%;
        }
    }
    
    &__info {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
    }
    
    &__icon {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        flex: none;
    }
    
    &__title {
        font-size: 1.125rem;
        color: #fff !important;
    }
    
    &__timer {
        margin-left: 10px;
        color: rgba(#fff, .4) !important;
        font-size: 16px;
        font-weight: 300;
        transform: translateY(1px);
    }
    
    @media(max-width:767px) {
        height: 230px;
        &__link {
            padding: 25px;
        }
        &__icon {
            width: 40px;
            height: 40px;
            margin-right: 15px;
        }
    }
}

.video-note {
    margin-top: 10px;
    font-size: 1.125rem;
    color: rgba(26, 28, 27, 0.5) !important;
    font-style: italic;
}