/* UI KIT */
@font-face {
    font-family: "Proxima Nova";
    font-display: swap;
    src: url("../fonts/mark_simonson_-_proxima_nova_regular.woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    font-display: swap;
    src: url("../fonts/mark_simonson_-_proxima_nova_bold.woff");
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    background-color: var(--deep-gray);
    line-height: 1.42;
    color: #fff;
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6,button,.button {
    font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #fff;
    font-weight: normal;
}

:root {
    --main-gradient: linear-gradient(90deg, #0b6ba7 0%, #96c15b 100%);
    --main-bg: #1a1c1b;
    --line-dark-20: rgba(26,28,27,.2);
    --line-white-20: rgba(255,255,255,.2);
    --deep-gray: #1a1c1b;
    --gray: rgb(166,166,166);
    --gray_opacity: rgba(26,28,27,.5);
    --light-gray: rgb(211,211,211);
    --light-gray_opacity: rgba(34,34,34,.2);
    --deep-blue: #22292e;
    --blue: #0B6BA7;
    --light-blue: #25A2CA;
    --dark-green: #079883;
    --green: #36A85B;
    --light-green: #96C15B;
    --white_opacity: rgba(255,255,255,.4);
    --red: #FF6F6F;

    --india-bg-primary: #FFFFFF;
    --india-bg-secondary: #232B37;
    --india-typography-primary: #09101B;
    --india-typography-secondary: #FFFFFF;

    --padding-size: 31px;
    @media (max-width: 558px) {
        --padding-size: 20px;
    }

}



.bg_gradient {
    background: var(--main-gradient);
}
.bg_deep-gray {
    background-color: var(--deep-gray);
}
.bg_gray {
    background-color: var(--gray);
}
.bg_gray_opacity {
    background-color: var(--gray_opacity);
}
.bg_light-gray {
    background-color: var(--light-gray_opacity);
}
.bg_light-gray_opacity {
    background-color: var(--light-gray_opacity);
}
.bg_blue {
    background-color: var(--blue);
}
.bg_light-blue {
    background-color: var(--light-blue);
}
.bg_deep-blue {
    background-color: var(--deep-blue);
}
.bg_dark-green {
    background-color: var(--dark-green);
}
.bg_green {
    background-color: var(--green);
}
.bg_light-green {
    background-color: var(--light-green);
}
.bg_black {
    background: #000;
}
.bg_white {
    background: #fff;
}
.bg_white_opacity {
    background-color: var(--white_opacity);
}

.color_gradient {
    color: var(--main-gradient);
}
.color_main-bg {
    color: var(--main-bg);
}
.color_deep-gray {
    color: var(--deep-gray);
}
.color_gray {
    color: var(--gray);
}
.color_gray_opacity {
    color: var(--gray_opacity) !important;
}
.color_light-gray {
    color: var(--light-gray_opacity);
}
.color_light-gray_opacity {
    color: var(--light-gray_opacity);
}
.color_blue {
    color: var(--blue);
}
.color_light-blue {
    color: var(--light-blue);
}
.color_deep-blue {
    color: var(--deep-blue);
}
.color_dark-green {
    color: var(--dark-green);
}
.color_green {
    color: var(--green);
}
.color_light-green {
    color: var(--light-green) !important;
}
.color_black {
    color: #000;
}
.color_white {
    color: #fff !important;
}
.color_white_opacity {
    color: var(--white_opacity) !important;
}

.hidden-xs {
    @media(max-width:767px) {
        display: none !important;
    }
}

.hidden-xs-min {
    display: block !important;
    @media(min-width:767px) {
        display: none !important;
    }
}

.promo-slider__top {
    @media(max-width:767px) {
        position: absolute;
        left: 15px;
        z-index: 500;
        top: 200px;
        display: flex !important;
    }
    @media(max-width: 320px) {
        top: 210px;
    }
}


.bg_left-img {
    position: relative;
    .row {
        position: relative;
        z-index: 10;
    }
    &:after {
        content: '';
        display: block;
        width: 135px;
        height: 730px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url('/local/html/img/bg-left-img.svg');
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 1;
    }

    @media(max-width:992px) {
        &:after {
            display: none;
        }
    }
}

.simplebar-track.simplebar-horizontal {
    background: var(--light-gray);
    //background: transparent;
    //border: 1px solid green;
    height: 4px;
    bottom: 3px;
    .simplebar-scrollbar {
        height: 4px;
        top: calc(50% - 2px);
        //border: 1px solid red;
        background: #a0a0a0;
        //background: transparent;
        &::before {
            display: none;
        }
    }
}

.simplebar-offset {
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        display: block;
        width: 50px;
        height: calc(100% - 2px);
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    }
    &.end {
        &::after {
            display: none;
        }
    }
}

.simplebar-content-wrapper {
  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.glow {
    filter: drop-shadow(-30px 40px 24px rgba(11, 107, 167, 0.6)) drop-shadow(30px 0 24px rgba(150, 193, 91, 0.6));
}
