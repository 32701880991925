.subscribe {
    padding-top: 90px;
    padding-bottom: 110px;
    &_lt {
        .subscribe__col {
            max-width: 825px;
            width: 100%;
        }
    }
    &__title {
        font-size: 2rem;
        margin-bottom: 7px;
        font-weight: bold;
        span {
            color: var(--light-green) !important;
        }
    }

    &__text {
        font-size: 1.2rem;
        color: var(--deep-gray) !important;
        a {
            color: var(--light-blue) !important;
        }
    }

    &__note {
        color: rgba(#1A1C1B, .3) !important;
        margin-top: 10px;
        font-size: 12px;
        a {
            color: rgba(#1A1C1B, .3) !important;
        }
    }

    &__row {
        display: flex;
        align-items: stretch;
        height: 50px;
        position: relative;
        input {
            box-shadow: none !important;
        }
        &.error {
            margin-bottom: 15px;

            .form-error {
                bottom: -20px;
                color: var(--red) !important;
            }

            @media(max-width: 768px) {
                label {
                    order: 0;
                }
                .form-error {
                    order: 1;
                    position: static;
                    margin-bottom: 8px;
                }
                button {
                    order: 2;
                }
            }

        }
        label {
            margin-right: 10px;
            width: 300px !important;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        padding: 0 30px;
        border: none;
        border-radius: 3px;
        position: relative;
        span {
            display: block;
            margin: 0 auto;
            text-align: center;
            position: relative;
            z-index: 10;
            color: #fff !important;
        }
        &:hover {
            text-decoration: none;
            color: #fff !important;
            &:before {
                opacity: 1;
                visibility: visible;
            }
            &:after {
                opacity: 0;
                visibility: hidden;
            }
        }
        &:after,
        &:before {
            border-radius: 3px;
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            visibility: hidden;
            opacity: 0;
            transition: .3s ease;
        }
        &:after {
            background: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%);
            visibility: visible;
            opacity: 1;
            width: 101%;
        }

        &:before {
            background: linear-gradient(89.85deg, #96C15B 0.1%, #0B6BA7 99.87%);
        }
    }
    &__btn-gr {
        position: relative;
        box-sizing: border-box;
        width: 162px;
        height: 50px;
        border-radius: 5px;
        border: none;
        overflow: hidden;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-weight: 600;
        padding: 3px;
        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, #0B6BA7, #96C15B, #0B6BA7);
            -webkit-background-size: 200%;
            background-size: 200%;
            transition: .2s;
        }
        &::after {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            z-index: 2;
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            background: #ffffff;
            border-radius: 3px;
        }
        span {
            position: relative;
            z-index: 3;
            color: var(--dark-green);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .2s;
        }
        &:hover, &:active, &:focus {
            text-decoration: none;
            &:before {
                background-position: -100% 0;
            }
            span {
                text-decoration: none;
                background-position: -100% 0;
            }
        }
    }
    @media (max-width:991px) {
        flex-direction: column;
        &__col {
            margin-bottom: 15px;
            max-width: 100%;
            margin-right: 0;
        }
        &__row {
            width: 400px;
            label {
                width: 100% !important;
            }
        }
    }
    @media (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 60px;
        &__row {
            height: auto;
            flex-direction: column;
            width: 100%;
        }
    }
}

.mainForm_visible {
    position: relative !important;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    opacity: 1 !important;
    overflow: visible !important;
    display: block !important;
    label {
        border: 1px solid #969696 !important;
        border-radius: 5px;
    }
    input, label {
        height: 50px !important;
        color: #000 !important;
    }
    form {
        width: 465px;
        justify-content: center;
    }
    @media (max-width: 768px) {
        form {
            width: 100% !important;
        }
    }
    .animated-placeholder:focus ~ .placeholder, .animated-placeholder:not(:placeholder-shown) ~ .placeholder {
        transform: translate3d(0, -1.25rem, 0);
    }
}

.mainForm_visible input::placeholder, .mainForm_visible .placeholder, .mainForm_visible option:disabled {
    color: rgba(26, 28, 27, 0.5);
    top: 50%;
    z-index: -1;
}

.svg-icons {
    display: block;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    width: 0;
    height: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -999;
}
