.text-center {
    text-align: center;
}
.bordered {
    border: 1px solid #fff;
    border-radius: .25rem;
}
.bordered-top_white {
    border-top: 1px solid var(--line-white-20);
}
.bordered-top_dark{
    border-top: 1px solid var(--line-dark-20);
}
.bg_white .bordered {
    border: 1px solid var(--main-bg);
}

.bg_white .button-transition:hover {
    color: #fff !important;
    background-color: var(--main-bg);
}

.bg-img {
    position: relative;
    z-index: 1;
}
.bg-img:before {
    content: '';
    background-image: var(--bg-img, url(/local/html/img/bg-lines.svg));
    background-repeat: no-repeat;
    background-position: var(--bg-pos,100% 100%);
    background-size: var(--bg-size,contain);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}
.bg-img--portfolio:before {
    background-position: var(--bg-pos,100% top);
}
.bg-img--portfolio:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 350px;
    bottom: 0;
    background: linear-gradient(transparent 0%, #1a1c1b 100%);
    z-index: -1;
}
.bg-img-2 {
    position: relative;
    z-index: 1;
}
.bg-img-2:before {
    content: '';
    background-image: var(--bg-img, url(/local/html/img/bg-lines-width.svg));
    background-repeat: no-repeat;
    background-position: var(--bg-pos,100% 100%);
    background-size: var(--bg-size,contain);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

@media screen and (max-width: 1199px) {
    .bg-img-2:before {
        display: none;
    }
}

.bgp-br {
    background-position: bottom right;
    background-repeat: no-repeat;
}
/* .bg-lines-2:before {
  background: url(../img/bg-lines-2.svg) no-repeat 100% 100%;
}
.bg-lines-3:before {
  background: url(../img/Group.svg) no-repeat 100% 100%;
} */

.standard-padding {
    padding-top: 164px;
    padding-bottom: 164px;
}
.standard-pt {
    padding-top: 110px;
}
.standard-pb {
    padding-bottom: 164px;
}

._blank {
    white-space: nowrap;
}
._blank:after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="44" height="14" viewBox="0 0 14 14" fill="none"><path d="M9.19999 0.768359H12.7834L12.2143 1.29693H9.19999V0.768359ZM13.2 5.01122V2.24087L13.8 1.68373V5.01122H13.2ZM13.8 1.04259L13.5049 0.768359H13.8V1.04259ZM13.1442 0.979231L13.5574 1.36317L13.2 1.69502V1.49693V1.29693H13H12.8021L13.1442 0.979231ZM12.8 1.69693V2.06644L6.35623 8.04993L5.94314 7.66636L12.3713 1.69693H12.8ZM11.5 13.3684H1.5C0.746242 13.3684 0.2 12.8406 0.2 12.1755V2.88979C0.2 2.22466 0.746242 1.69693 1.5 1.69693H6.80001V2.2255H1.5C1.3088 2.2255 1.13219 2.28519 1.00141 2.40662C0.869294 2.5293 0.800002 2.70039 0.800002 2.88979V12.1755C0.800002 12.3649 0.869294 12.536 1.00141 12.6587C1.13219 12.7801 1.3088 12.8398 1.5 12.8398H11.5C11.6912 12.8398 11.8678 12.7801 11.9986 12.6587C12.1307 12.536 12.2 12.3649 12.2 12.1755V7.26836H12.8V12.1755C12.8 12.8406 12.2538 13.3684 11.5 13.3684Z" fill="white" stroke="white" stroke-width="0.4"/></svg>');
}

@media(max-width:1400px) {
    .standard-padding {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}
@media(max-width:768px) {
    .bg-lines:before {
        background-size: 45%;
    }
    .standard-padding {
        padding-top: 96px;
        padding-bottom: 96px;
    }
    .standard-pt {
        padding-top: 96px;
    }
    .standard-pb {
        padding-bottom: 96px;
    }
}

@media(max-width: 520px) {
    .standard-pt {
        padding-top: 32px;
    }
}

@media(max-width:350px) {
    ._blank {
        white-space:normal;
    }
}


.bg_white div:not(#map), .bg_white span, .bg_white p, .bg_white h1,.bg_white .h1, .bg_white h2,.bg_white .h2, .bg_white h3,.bg_white .h3, .bg_white h4,.bg_white .h4, .bg_white h5,.bg_white .h5, .bg_white h6,.bg_white .h6, .bg_white a {
    color: var(--main-bg);
}
.body_lock {
    overflow: hidden;
}
.button {
    min-height: 50px;
}

ul.ul {
    padding-left: 0;
    list-style: none;
}
ul.ul li {
    margin-bottom: 1rem;
}
ul.ul li::before {
    content: "—";
    color: var(--white_opacity);
    display: inline-block;
    width: 1em;
    margin-left: 1em;
    padding-right: 1.5rem;
}
.sentinal {
    height: 150px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: -100;
}

.b-portfolio-section:first-of-type:before,.b-portfolio:first-of-type:before {
    display: block;
    content:'';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 320px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0) 100%);
}

/* Странное решение, позволяющее нивелировать padding-top: 168px у body */
main > .h-100vh {
    margin-top: -168px;
    height: auto !important;
    padding-top: 256px !important;
    padding-bottom: 164px;
}

@media(max-width: 991px) {
    main > .h-100vh {
        margin-top: -186px;
    }
}
@media(max-width: 768px) {
    main > .h-100vh {
        margin-top: -170px;
    }
}
@media(max-width: 638px) {
    main > .h-100vh {
        margin-top: -194px;
    }
}
@media(max-width: 576px) {
    main > .h-100vh {
        margin-top: -200px;
    }
}
@media(max-width: 480px) {
    main > .h-100vh {
        margin-top: -190px;
    }
}
@media(max-width: 353px) {
    main > .h-100vh {
        margin-top: -208px;
    }
}
@media(max-width: 320px) {
    main > .h-100vh {
        margin-top: -244px;
    }
}

.overlay {
    width: 100vw;
    height: 100%;
    position: fixed;
    background: transparent;
    transition: background .3s ease;
    z-index: -100;
    top: 0;
}

.overlay.active {
    z-index: 1001;
    background: rgba(0, 0, 0, .25);
}


/* about-block */
.about-main__aside {
    border-left: 1px solid var(--white_opacity);
}

/* parters-main */
.partners-main__item {
    height: 100px;
}

@media (max-width: 991px) {
    .partners-main__item {
        height: 80px;
    }
}

.partners-main__item img {
    /*width: 100%;   *//*TODO убрали, чтобы выводить без масштабирования*/
}

/* depart-main */
.depart-main__item {
    position: relative;
    height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.depart-main-item__inner .h3 {
    z-index: 2;
}

.depart-main-item__inner p {
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease;
    z-index: 2;
}

.depart-main-item__inner:hover p {
    visibility: visible;
    opacity: 1;
}

.depart-main-item__inner {
    box-sizing: border-box;
    background: rgba(0, 0, 0, .5);
    transition: all .3s ease;
}

.depart-main-item__gradient {
    z-index: 0;
    background: var(--main-gradient);
    visibility: hidden;
    opacity: 0;
    left: 0;
    top: 0;
    transition: all .3s ease;
    z-index: 1;
}

.depart-main-item__inner:hover .depart-main-item__gradient {
    visibility: visible;
    opacity: 1;
}

.depart-main-item__image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.depart-main-item__image-wrapper::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.depart-main-item__image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* clients-main */
.clients-main__item {
    width: 12.5%;
}

.clients-main__item img {
    max-width: 100%;
}

/* .services-main-item__info {
    min-height: 150px;
} */

.services-main-item__info img {
    display: none;
}

/* vendors */
.vendors__item {
    height: 175px;
}

.vendors-item__inner {
    border-top: 1px solid var(--deep-gray);
    border-left-width: 0;
    border-right-width: 0;
    background: transparent;
}

.vendors .depart-main-item__inner:hover .vendors-item__picture {
    display: none !important;
}

.vendors i {
    position: absolute;
    background: var(--line-white-20);
    height: 1px;
    bottom: 0;
    left: 0;
    width: 100%;
}

.vendors i:first-child {
    height: auto;
    width: 1px;
    top: 0;
    left: auto;
    right: 0;
}

.vendors__item:nth-child(3n) i:first-child {
    display: none;
}

.vendors__item:nth-child(3n) i:first-child {
    display: none;
}

.vendors:after {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 4px;
    background: var(--deep-gray);
    content: '';
}

.clients-img-list img {
    height: 75px;
    width: calc(100% / 8);
    object-fit: scale-down;
    margin-bottom: 2rem;
}


.error_submit {
    border: 1px solid var(--red);
    padding: 1em;
}

.modal-success {
    display: none;
    width: 100vw;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    padding-top: 80px;
    text-align: center;
    position: fixed;
    top: 0;
    z-index: 1001;
    background-color: var(--deep-blue);
}

.modal-success .modal-icon {
    margin-bottom: 20px;
}

.modal-success__title {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 600;
}

.modal-success__text {
    font-size: 18px;
    line-height: 1.4;
}

.modal-success.active {
    display: flex;
}

@media (max-width: 650px) {
    .mh-300 {
        max-height: 300px;
    }
}

.percantage svg {
    display: inline-block;
    vertical-align: unset;
}

svg.rotated {
    transform: scale(-1);
}


.advans__title {
    margin-bottom: 15px;
}
