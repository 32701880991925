.socials {
    &__label {
        font-size: 18px;
        margin-bottom: 15px;
        color: rgba(26, 28, 27, 0.5) !important;
    }
    &__text {
        margin-bottom: 15px;
        font-size: 18px;
    }
    &__list {
        display: flex;
        align-items: center;
        height: 80px;
        & > * {
            height: 100%;
            border: 1px solid #EEEEEE;
            margin: -1px 0 0 -1px;
        }
    }

    &__link {
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: relative;
        &:after {
            content: '';
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            visibility: hidden;
            opacity: 0;
            background: linear-gradient(139.36deg, #0B6BA7 2.69%, #96C15B 100%);
            transition: .3s ease;
            z-index: 1;
        }
        &:hover {
            &:after {
                visibility: visible;
                opacity: 1;
            }
            .socials__icon {
                fill: #fff;
            }
        }
    }

    &__icon {
        width: 30px;
        height: 30px;
        position: relative;
        z-index: 2;
        &.icon-vk {
            fill: #4D76A1;
        }
        &.icon-fb {
            fill: #3B5998;
        }
        &.icon-tw {
            fill: #03A9F4;
        }
        &.icon-tg {
            fill: #039BE5;
        }
        &.icon-insta {
            fill: #C21975;
        }
    }

    @media(max-width:767px) {
        flex-direction: column;
        padding-bottom: 15px;
        &__label {
            margin-right: 0;
            margin-bottom: 15px;
        }
        &__list {
            height: auto;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .likely {
        .socials__ellikely__widget {
            height: 80px;
            .likely {
                &__icon, &__counter {
                    display: none;
                }
            }
        }
    }
}

.socials-wr {
    margin-top: 32px;
}