a.solutions__body {
    &:hover {
        background-color: #0066B2;
        border-color: #0066B2;
        color: #fff !important;
        text-decoration: none;
        .solutions__title,
        .solutions__price,
        .solutions__btn a {
            color: #fff !important;
        }
        .solutions__text {
            color: rgba(255, 255, 255, .5) !important;
        }
        .arrow-link {
            color: #fff !important;
        }
        .arrow-link_light {
            &:after {
                content: url("data:image/svg+xml; utf8, <svg width='26' height='13' viewBox='0 0 26 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.485352 6.14992L24.4854 6.14992M24.4854 6.14992L18.8246 0.706299M24.4854 6.14992L18.8246 11.9063' stroke='rgb(255,255,255)' stroke-opacity='1' stroke-width='1.5'/></svg>");
            }
        }
    }
}
.solutions {
    margin-bottom: -40px;

    &__item {
        margin-bottom: 40px;
    }

    &__btn {
        font-weight: bold;
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid var(--light-blue);
        padding: 27px;
        height: 100%;
        background-color: transparent;
        transition: all .3s ease;
        color: var(--deep-gray);
    }

    &_dark-green {
        a.solutions__body {
            &:hover {
                background-color: var(--dark-green);
                border-color: var(--dark-green);
            }
        }
        .solutions__body {
            border: 1px solid var(--dark-green);
        }
    }

    &_green {
        a.solutions__body {
            &:hover {
                background-color: var(--green);
            }
        }
        .solutions__body {
            border: 1px solid var(--green);
        }
    }

    &_light-green {
        a.solutions__body {
            &:hover {
                background-color: var(--light-green);
            }
        }
        .solutions__body {
            border: 1px solid var(--light-green);
        }
    }

    &__spacer {
        margin-top: auto;
    }

    &__title {
        font-weight: 600;
        width: 100%;
        overflow-wrap: break-word;
        font-size: 18px;
    }

    &__price {
        font-weight: 600;
    }

    &__text {
        color: rgba(26, 28, 27, 0.5) !important;
        transition: none;
    }

    &__title,
    &__price,
    &__text {
        margin-bottom: 15px;
    }

    @media (max-width: 768px) {
        &__body {
            padding: 30px 30px 45px;
        }
        &__price {
            font-size: 16px;
        }
    }
}
