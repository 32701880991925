.footer-wrapper {
    background: #151616;
}
.footer {
    color: var(--white_opacity);
}
.footer__link {
    color: #ffffff;

    &:visited {
        color: #ffffff;
    }
}

.footer__arrow-links {
    width: 264px;
}

.footer a:hover {
    color: var(--light-blue);
    text-decoration: none;
}

.footer-arrow-link {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-arrow-link svg {
    transform: translate3d(-8px,0,0);
    transition: transform .3s ease;
}
.footer-arrow-link:hover svg {
    transform: translate3d(0,0,0);
}
.footer-arrow-link:hover svg>path {
    stroke: var(--light-blue);
}

.footer__copyrights a {
    color: var(--white_opacity);
}

.footer-bottom__links a {
    white-space: nowrap;
    margin-bottom: .5rem;
    display: block;
    letter-spacing: .11em;
}

/* grid */
.footer__copyrights {
    /*margin-top: 4rem;*/
    margin-top: 3em;
    grid-area: footer__copyrights;
    width: 280px;
    margin-right: 4rem;
}
.footer__logo-links {
    grid-area: footer__logo-links;
    width: 264px;
    margin-right: 4rem;
}
.footer__main-links {
    grid-area: footer__main-links;
    width: 100%;
    margin-bottom: 32px;
    padding-bottom: 22px;
    border-bottom: 1px solid var(--white_opacity);
}
.footer__services {
    grid-area: footer__services;
    display: none;
}

.footer__main {
    display: grid;
    /*grid-template-areas: "footer__logo-links footer__main-links"
    "footer__logo-links footer__copyrights";*/
     grid-template-areas: "footer__logo-links footer__main-links"
                         "footer__logo-links footer__services"
                         "footer__copyrights footer__services";
    grid-template-columns: 400px 1fr;
    grid-template-rows: 125px 100px 200px 1fr;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    @media (max-width: 1200px) {
        grid-template-rows: 125px 100px 150px 1fr;
    }
}

.footer-main-links__list {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
}
.footer-main-links__item {
    margin-right: 2rem;
    @media (max-width: 992px) {
        width: calc(50% - 2rem);
        margin-bottom: 25px;
    }
}
.footer-main-links__social {
    width: 20%;
    text-align: right;

    img {
        height: 18px;
    }
}

.footer-services__item {
    width: 50%;
    position: relative;
}
.footer-services__dd a:not(:hover) {
    color: var(--white_opacity) !important;
}

.footer-bottom__links a:not(:hover),.footer-city__item a:not(:hover) {
    color: var(--white_opacity);
}
.footer-bottom__links a.active {
    color: #fff;
}
.footer-city-list__header {
    white-space: nowrap;
    display: block;
}
.footer__city-list {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.footer-city-list a {
    font-size: 0.75rem;
}

@media(max-width:992px) {
    .footer-main-links__list,.footer-main-links__social,.footer-main-links__list a {
        width: 50%;
    }
    .footer__main {
        grid-template-columns: 300px 1fr;
        grid-template-rows: 125px 100px 1fr;
    }
    .footer-services__item {
        width: 100%;
    }
    .footer-city-list {
        flex-wrap: wrap;
        justify-content: start !important;
    }
    .footer-city-list__header {
        width: 300px;
    }
    .footer-arrow-link:last-child {
        border: none;
    }
}

@media(max-width:992px) {
    .footer-city-list__header {
        width: 250px;
    }
    .footer-city-list {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .has-dropdown .footer-services__dd{
        /* временно откроем пункты меню */
        /* display: none; */
    }
    .has-dropdown.active .footer-services__dd{
        display: block;
    }
    .footer-services__item {
        position: relative;
        padding-right: 4rem !important;
    }
    .footer__dd-button:before {
        content: '';
        background: url(../img/plus.svg) no-repeat;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        /* временно уберем плюсы */
        display: none;
    }
    .footer__dd-button.active:before {
        background: url(../img/minus.svg) no-repeat;
    }
    .footer__main-links {
        flex-direction: column;
        border: none;
        margin-bottom: 0;
    }
    .footer-main-links__list, .footer-main-links__social, .footer-main-links__list a {
        width: 100%;
    }
    .footer-main-links__list {
        margin-bottom: -20px;
        order: 1;
    }
    .footer-main-links__social {
        text-align: left;
        margin-bottom: 4rem;
    }
    .footer-main-links__social a:first-child {
        margin-left: 0 !important;
    }
    .footer-main-links__list a {
        margin-bottom: 1rem;
    }
    .footer__logo-links {
        margin-right: 2rem;
    }
    .footer__services {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        padding-top: 2rem;
    }
    .footer__main {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "footer__logo-links footer__main-links"
        "footer__services footer__services"
        "footer__copyrights footer__copyrights";
    }
}

@media (max-width:576px) {
    .footer-city-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 480px) {
    .footer__main {
        grid-template-columns: 100%;
        grid-template-rows: 1fr;
        grid-template-areas: "footer__logo-links"
        "footer__main-links"
        "footer__services"
        "footer__copyrights";
    }
    .footer-main-links__social {
        margin-top: 4rem;
    }
    .footer-main-links__social a {
        margin-right: 1rem;
    }
    .footer-main-links__social img {
        transform: scale(1.5);
    }
    .footer-main-links__list a {
        width: 50%;
    }
    .footer__city-list {
        flex-direction: column;
    }
    .footer-city-list {
        justify-content: space-between !important;
    }
    .footer-city-list__header {
        margin-bottom: 2rem;
    }
    .footer-city__item:nth-child(odd) {
        margin-right: 1rem !important;
    }
    .footer-city__item:nth-child(even) {
        margin-right: 0 !important;
    }

    .footer-bottom__links {
        flex-wrap: wrap;
    }
    .footer-bottom__links a {
        font-size: 0.75rem;
    }
    .footer__copyrights {
        margin-right: 0;
        width: 260px;
    }
}
