.error-page {
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-image: url('/local/html/img/404-lines.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 0;
    }
    &__body {
        position: relative;
        z-index: 1;
        padding-top: 125px;
        padding-bottom: 95px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__icon {
        width: 555px;
        height: 200px;
        margin-bottom: 40px;
        background-image: url("/local/html/img/404.svg");
        background-size: 100%;
    }
    &__btn {
        border-radius: 3px;
        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }
    &__text {
        margin-bottom: 60px;
        font-size: 18px;
    }
    
    @media (max-width: 992px) {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        &__body {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    
    @media (max-width: 767px) {
        &__icon {
            width: 100%;
            height: 150px;
            background-repeat: no-repeat;
            background-position: center;
        }
        &__text {
            margin-bottom: 40px;
        }
    }
}