.v-container {
    padding-bottom: 80px;
    @media(max-width: 576px) {
        padding-bottom: 40px;
    }
}
.v-list {
    margin-top: 10px;
    margin-bottom: 25px;
    &__item {
        border-top: 2px solid rgba(#1A1C1B, .1);
        &:first-child {
            border-top: none;
        }
    }
    &__arrow {
        display: block;
        width: 25px;
        height: 11px;
        flex-shrink: 0;
        margin-left: 16px;
        opacity: 0;
        visibility: hidden;
        transition: .3s ease;
    }
    &__link {
        display: flex;
        align-items: center;
        max-width: 825px;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 35px;
        position: relative;
        font-size: 24px !important;
        text-decoration: none !important;
        color: #1A1C1B !important;
        &:hover {
            color: var(--light-blue) !important;
            .v-list__arrow {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    @media (max-width:767px) {
        &__link {
            max-width: 250px;
            padding-top: 20px;
            padding-bottom: 25px;
            font-size: 18px !important;
        }
    }
}