.show-more {
    text-align: center;
    &_left {
        text-align: left;
    }
    &__btn {
        border: 1px solid rgba(21, 22, 21, 0.4);
        font-size: 16px;
        font-weight: 600;
        padding: 12px 50px 12px 25px;
        border-radius: 50px;
        position: relative;
        transition: all .3s ease;
        color: var(--main-bg) !important;
        z-index: 3;
        &:hover {
            border: 1px solid var(--green);
            background-image: var(--main-gradient);
            background-color: #fff;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            //color: #fff !important;
            text-decoration: none;
            .show-more__icon path {
                stroke: var(--green);
            }
        }
    }
    
    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 11px;
        height: 11px;
        right: 30px;
        transition: all .3s ease;
    }
}