.quote {
    display: flex;
    padding: 40px 40px 50px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #22292E 0%, #22292E 61.97%, rgba(34, 41, 46, 0.5) 100%);
        z-index: 1;
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        z-index: 2;
    }
    &__icon {
        width: 40px;
        height: 40px;
        background-size: contain;
        margin-bottom: 20px;
    }
    
    &__text {
        font-size: 1.125rem;
        color: #fff !important;
        margin-bottom: 10px;
    }
    
    &__note {
        font-size: 14px;
        font-weight: 300;
        color: #fff !important;
    }
    
    @media(max-width:767px) {
        background-size: cover;
        padding: 25px 25px 30px;
    }
}