.mainForm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1100;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease;
    overflow-y: auto;
    overflow-x: hidden;
}

.mainForm.active {
    visibility: visible;
    opacity: 1;
    overflow-x: hidden;
}

.mainForm .h1 {
    max-width: 20em;
    padding: 0 1em;
    @media(max-width: 1080px) {
        padding: 0 3em;
    }
    @media(max-width: 876px) {
        padding: 0 1em;
        width: 100%;
        max-width: 100%;
    }
}

label {
    .placeholder {
        pointer-events: none;
        &.error {
            color: var(--red)
        }
    }
}

.mainForm form {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
}

.mainForm form input, .mainForm form select, .mainForm form option, .mainForm form textarea, .mainForm form label {
    width: 100%;
    position: relative;
    height: 55px;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    color: #fff;
    margin-bottom: 10px;
    background: transparent;
    font-size: 16px !important;
    border: 1px solid rgba(#fff, .3);
    &.error {
        border-color: var(--red);
    }
    .field-error, .mainForm__error {
        color: var(--red);
        &.active {
            display: block;
        };
    }

    .field-error {
        display: none;
    }
}

.mainForm .select {
    &__placeholder {
        position: absolute;
        top: 16px;
        padding: 0 54px 0 20px;
        color: rgba(255, 255, 255, 0.4);
    }
}

.modal-vac form input, .modal-vac form select, .modal-vac form option, .modal-vac form textarea, .modal-vac form label {
    width: 100%;
    position: relative;
    height: 55px;
    min-height: 55px;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    color: #fff;
    margin-bottom: 10px;
    background: transparent;
    font-size: 16px !important;
    border: 1px solid rgba(#fff, .3);
}
.modal-vac form textarea {
    min-height: 100px;
    padding-top: 1.4rem;
}
.modal-vac form label,
.mainForm form label {
    border: none;
    height: auto;
}

.mainForm textarea {
    height: auto;
    min-height: 55px;
    max-width: 100%;
    max-height: 150px;
    resize: none;
}
.mainForm form option {
    color: #000;
}
.modal-vac input, .modal-vac select, .modal-vac textarea,
.mainForm input, .mainForm select, .mainForm textarea {
    padding-left: 20px;
    padding-right: 20px;
}

.modal-vac input, .modal-vac textarea,
.mainForm input, .mainForm textarea {
    padding-top: 1rem;
    //resize: none;
}

.label-textarea .placeholder {
    top: 25px !important;
}
.label-textarea.focused {
    .placeholder {
        z-index: 1;
        transform: translate3d(0, -1.5rem, 0);
        font-size: 13px;
        width: 94%;
        left: 1px;
        /* background-color: #1a1c1b; */
    }
}


.modal-vac input::placeholder, .modal-vac .placeholder, .modal-vac option:disabled,
.mainForm input::placeholder, .mainForm .placeholder, .mainForm option:disabled {
    color: rgba(#fff, .4);
    left: 0;
    top: 28px;
    z-index: 0;
    background: transparent;
    transform: translateY(-50%) scale(1);
    transition: all .3s ease;
    padding-left: 20px;
}

.modal-vac .error .placeholder,
.mainForm .error .placeholder {
    color: var(--red);
}

.mainForm form select {
    color: #fff;
}

.mainForm form select.selected {
    color: #fff;
}

input.animated-placeholder:focus ~ .placeholder,
textarea.animated-placeholder:focus ~ .placeholder,
input.animated-placeholder:not(:placeholder-shown) ~ .placeholder
/* textarea.animated-placeholder:not(:placeholder-shown) ~ .placeholder */ {
    z-index: 1;
    transform: translate3d(0, -1.25rem, 0);
    font-size: 13px;
}
.mainForm .subtitle {
    margin-left: 20px;
}
.mainForm__title {
    display: block;
    text-align: center;
    margin-bottom: 5px;
}
.mainForm__text {
    margin-bottom: 0;
    padding: 0 1em;
}
.mainForm__body {
    margin-bottom: 10px;
}
.mainForm__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 24px 0;
}
.mainForm__note {
    margin-top: 15px;
    font-size: 12px;
}
.mainForm__action {
    margin-top: 30px;
    width: 100%;
    button {
        width: 100%;
        border: none;
        font-size: 21px;
    }
}
.mainForm .select-before {
    display: block;
    position: absolute;
    top: 25px;
    right: 20px;
    background: transparent;
    z-index: 1;
    pointer-events: none;
    opacity: .4;
}


.mainForm {
    textarea, input {
        &::placeholder {
            color: rgba(0, 0, 0, 0);
        }
    }
}


.error[data-form-group] {
    position: relative;
    padding-bottom: 8px;
}

.form-error {
    display: block;
    font-size: 12px;
    color: var(--red);
    position: absolute;
    bottom: 10px;
    left: 0;
}
