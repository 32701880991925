.v-select {
    position: relative;
    
    &.v-select-active {
        .v-select__arrow {
            transform: translateY(-50%) scale(-1);

        }

        .v-select__icon {
            path {
                stroke: var(--light-blue);
            }
        }
        
        .v-select__list {
            visibility: visible;
            opacity: 1;
            z-index: 500;
        }
    }
    &__link {
        padding-right: 15px;
        display: flex;
        align-items: center;
        position: relative;
        &:hover, &:focus {
            text-decoration: none;
        }
    }
    &__icon {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        flex: none;
        path {
            transition: .2s;
        }
    }
    
    &__arrow {
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scale(1);
        right: 0;
        transition: .3s ease;
    }
    
    &__title {
        font-size: 16px !important;
        font-weight: 600;
    }
    
    &__list {
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        position: absolute;
        top: 25px;
        left: 0;
        padding: 15px 20px 20px;
        border: 2px solid #D1D2D1;
        background-color: #fff;
        width: 310px;
        transition: .3s ease;
    }
    
    &__item {
        display: block;
        padding: 4px 0;
        color: #1A1C1B !important;
        z-index: 500;
        font-size: 16px !important;
        line-height: 16px !important;

        &:hover {
            text-decoration: none;
            color: var(--light-blue) !important;
        }
        
        &.active {
            color: rgba(26, 28, 27, 0.6) !important;
            pointer-events: none;
        }
    }
    
    @media (max-width: 767px) {
        &__list {
            width: 280px;
        }
    }
}