.welcome {
    padding-top: 0;
    background-color: white;

    .text-gradient {
        background: -webkit-linear-gradient(#0B6BA7, #96C15B);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .decorate {
        position: absolute;
        content: "";
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        z-index: -1;

        &--double-caret {
            width: 60px;
            height: 60px;
            background-image: url("/local/html/img/welcome/decorate/double-caret.svg");
        }

        &--plus {
            background-image: url("/local/html/img/welcome/decorate/plus.svg");
        }

        &--flow-arrow {
            background-image: url("/local/html/img/welcome/decorate/flow-arrow.svg");
        }

        &--strategy {
            background-image: url("/local/html/img/welcome/decorate/strategy.svg");
        }

        &--ellipse-arrow {
            width: 58px;
            height: 70px;
            background-image: url("/local/html/img/welcome/decorate/ellipse-arrow.svg");
        }

        &--music-notes {
            width: 24px;
            height: 24px;
            background-image: url("/local/html/img/welcome/decorate/music-notes.svg");
        }

        &--video-camera {
            width: 32px;
            height: 32px;
            background-image: url("/local/html/img/welcome/decorate/video-camera.svg");
        }

        &--rectangle {
            width: 51px;
            height: 34px;
            background-image: url("/local/html/img/welcome/decorate/rectangle.svg");
        }

        &--border {
            padding: 10px;
            box-sizing: content-box;
            border: 1px solid #0C6CA6;
            border-radius: 50%;
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    .content {
        overflow: hidden;
    }

    .wrapper {
        position: relative;
        max-width: calc(1200px + var(--padding-size) * 2);
        margin: 0 auto;
        padding: 0 var(--padding-size);
        display: flex;
        flex-direction: column;
        z-index: 1;
    }

    .btn {
        text-decoration: none;
        outline: none;
        border-radius: 3px;
        padding: 16px 29px;
        white-space: nowrap;
        transition: all linear 0.2s;
        cursor: pointer;
        text-align: center;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            outline: none;
        }

        &--primary {
            font-weight: 700;
            color: white !important;
            background: var(--main-gradient);
            border: none;
            text-transform: uppercase;
            transition: all linear 0.2s;

            &:hover {
                color: var(--india-bg-primary) !important;
                background: linear-gradient(89.85deg, #96c15b 0.1%, #0b6ba7 99.87%);
            }
        }

        &--secondary {
            background: var(--india-bg-primary);
            border: none;
            font-weight: 600;

            &:hover {
                background: rgba(white, 0.9);
            }

            &:disabled {
                opacity: 0.2;
                border: 2px solid #ffffff;
                background: transparent;
            }

            &-text {
                color: var(--india-bg-primary);
                background: var(--main-gradient);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &--hollow {
            font-weight: 600;
            background: transparent;
            border: 2px solid white;
            color: white !important;

            &:hover {
                background: white;

                .btn--hollow-text {
                    color: var(--india-bg-primary);
                    background: var(--main-gradient);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        &--link {
            background: transparent;
            border: none;
            color: var(--india-typography-primary);

            &:hover {
                color: var(--blue);
            }
        }

        &--shadow {
            -webkit-appearance: none;
            -webkit-box-shadow: 0px 100px 80px rgba(2, 52, 69, 0.25),
            0px 42px 33px rgba(2, 52, 69, 0.179714),
            0px 22px 18px rgba(2, 52, 69, 0.149027),
            0px 12px 10px rgba(2, 52, 69, 0.125),
            0px 7px 5px rgba(2, 52, 69, 0.100973),
            0px 3px 2px rgba(2, 52, 69, 0.0702864);

            box-shadow: 0px 100px 80px rgba(2, 52, 69, 0.25),
            0px 42px 33px rgba(2, 52, 69, 0.179714),
            0px 22px 18px rgba(2, 52, 69, 0.149027),
            0px 12px 10px rgba(2, 52, 69, 0.125),
            0px 7px 5px rgba(2, 52, 69, 0.100973),
            0px 3px 2px rgba(2, 52, 69, 0.0702864);
        }
    }

    .header-welcome {
        background: var(--india-bg-primary);
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 19px 0 19px;

        &__logo {
            width: 274px;
            height: 48px;
            flex-shrink: 0;
            margin-right: 24px;

            img {
                width: 100%;
            }

            @media (max-width: 991px) {
                width: 210px;
                height: 40px;
            }
            @media (max-width: 767px) {
                width: 190px;
                height: 35px;
            }
        }

        a {
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 767px) {
                flex-wrap: wrap;
            }
        }

        &__block {
            display: flex;
            align-items: center;

            @media (max-width: 991px) and (min-width: 767px) {
                width: 100%;
                justify-content: space-between;
            }
        }

        &__btn-wrapper {
            margin-left: 40px;

            @media (max-width: 767px) {
                display: none;
            }

            &--mobile {
                display: flex;
                justify-content: center;
                margin-left: 0;
                width: 100%;
                margin-top: 15px;
                text-align: center;

                @media (min-width: 767px) {
                    display: none;
                }
            }
        }

        &__btn {
            font-weight: 400;
            padding: 10px 20px;

            &--mobile {
                max-width: 280px;
                width: 100%;
            }
        }

        &__contacts {
            display: flex;

            @media (max-width: 991px) {
                flex-direction: column;
            }
            @media (max-width: 767px) {
                flex-direction: row;
            }

            &--item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 20px;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: var(--india-typography-primary);

                @media (max-width: 991px) {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
                @media (max-width: 767px) {
                    margin-bottom: 0;
                    margin-right: 20px;
                    span {
                        display: none;
                    }
                }

                img {
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                    @media (max-width: 767px) {
                        width: 20px;
                        height: 20px;
                        margin-right: 0;
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }

        &--thank {
            .header-india__content {
                justify-content: center;
            }

            .header-india__logo {
                margin-right: 0;
            }
        }
    }

    .footer-welcome {
        background-color: #1e2530;
        padding: 30px 0 30px;

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__info {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding-bottom: 67px;

            @media (max-width: 991px) {
                flex-direction: column;
                align-items: center;
                padding-bottom: 20px;
            }

            @media (max-width: 991px) {
                padding-bottom: 15px;
            }
        }

        &__logo {
            flex-shrink: 0;

            @media (max-width: 991px) {
                width: 210px;
                margin-bottom: 35px;
            }

            @media (max-width: 767px) {
                width: 190px;
                margin-bottom: 20px;
            }
        }

        &__links {
            display: flex;
            margin-left: auto;

            @media (max-width: 991px) {
                flex-wrap: wrap;
                margin-left: 0;
            }
        }

        &__link {
            position: relative;
            margin-right: 68px;
            padding-right: 32px;
            font-size: 20px;
            line-height: 1.3;
            color: rgba(white, 0.6);

            span {
                display: block;
                font-size: 32px;
                line-height: 1;
                background: -webkit-linear-gradient(#24FF00, #A0BF4A);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &::after {
                position: absolute;
                content: "";
                width: 24px;
                height: 24px;
                bottom: 3px;
                right: 0;
                background-image: url("/local/html/img/welcome/footer/link.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;

                @media (max-width: 991px) {
                    width: 18px;
                    height: 18px;
                }

                @media (max-width: 767px) {
                    width: 15px;
                    height: 15px;
                    bottom: 1px;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                text-decoration: none;
            }

            @media (max-width: 991px) {
                margin-bottom: 20px;
                margin-right: 50px;
                padding-right: 26px;
                font-size: 14px;

                span {
                    font-size: 22px;
                }
            }

            @media (max-width: 767px) {
                margin-bottom: 20px;
                margin-right: 30px;
                padding-right: 23px;
                font-size: 12px;

                span {
                    font-size: 16px;
                }
            }
        }

        &__bottom {
            padding-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.4);

            @media (max-width: 767px) {
                flex-direction: column;
            }

            &--rights {
                display: flex;
                justify-content: space-between;

                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        &__authority,
        &__reserved {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 1.2;
            color: rgba(white, 0.6);

            @media (max-width: 991px) {
                font-size: 14px;
            }

            @media (max-width: 767px) {
                text-align: center;
            }
        }
    }

    .promo {
        position: relative;
        display: flex;
        align-items: center;
        height: 100vh;
        min-height: 760px;
        padding: 186px 0 100px;

        &::before {
            position: absolute;
            content: "";
            width: 4768px;
            height: 4642px;
            left: 50%;
            bottom: -1318px;
            transform: translateX(-53%);
            background-image: url("/local/html/img/welcome/backgrounds/shape-1.svg");
            background-repeat: no-repeat;
            background-size: contain;
            z-index: -1;

            @media (max-width: 767px) {
                bottom: -1200px;
            }
        }

        &__supheading,
        &__subheading {
            max-width: 1014px;
        }

        &__supheading {
            margin-bottom: 26px;
            font-size: 40px;
            line-height: 1.3;
            text-transform: uppercase;

            @media (max-width: 991px) {
                font-size: 26px;
            }

            @media (max-width: 767px) {
                font-size: 20px;
            }
        }

        &__heading {
            margin-bottom: 26px;
            font-size: 100px;
            line-height: 1.1;
            text-transform: uppercase;

            span {
                font-weight: 700;
            }

            @media (max-width: 991px) {
                font-size: 66px;
            }

            @media (max-width: 767px) {
                font-size: 50px;
            }
        }

        &__subheading {
            font-size: 36px;
            line-height: 1.3;

            @media (max-width: 991px) {
                font-size: 24px;
            }

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }

        &__anchor {
            position: absolute;
            display: flex;
            align-items: center;
            left: 50%;
            bottom: 50px;
            transform: translateX(-50%);
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: white;
            text-transform: uppercase;
            transition: all 0.3s linear;
            white-space: nowrap;

            img {
                margin-left: 20px;
                animation: levitate-1 2s linear infinite;
            }

            &:hover {
                text-decoration: none;
                color: white;
                opacity: 0.7;
            }

            @media (max-width: 991px) {
                font-size: 16px;

                img {
                    margin-left: 10px;
                }
            }

            @media (max-width: 767px) {
                bottom: 140px;
                font-size: 14px;
            }
        }

        @media (max-width: 767px) {
            padding-top: 100px;
            padding-bottom: 180px;
        }
    }

    .about {
        margin-top: 200px;
        padding-top: 30px;
        margin-bottom: 60px;

        &__top {
            display: flex;

            @media (max-width: 991px) {
                flex-direction: column;
            }
        }

        &__head-wrapper {
            position: relative;
            flex-shrink: 0;
            width: fit-content;
            margin-right: 361px;

            @media (max-width: 1200px) {
                margin-right: 330px;
            }

            @media (max-width: 991px) {
                margin-right: 0;
            }
        }

        &__heading {
            width: fit-content;
            margin: 0;
            font-weight: 700;
            font-size: 40px;
            line-height: 1.3;
            text-transform: uppercase;

            @media (max-width: 991px) {
                font-size: 26px;
            }

            @media (max-width: 767px) {
                font-size: 20px;
            }
        }

        &__atom-wrapper {
            position: absolute;
            top: 147px;
            left: calc(100% + 23px);
            box-shadow: 30px 30px 40px rgba(0, 0, 0, 0.25);

            .inner {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 200px;
                height: 200px;
                background-color: white;
                border-radius: 10px;

                img {
                    width: 120px;
                    height: 120px;
                }

                @media (max-width: 991px) {
                    width: 160px;
                    height: 160px;

                    img {
                        width: 100px;
                        height: 100px;
                    }
                }

                @media (max-width: 767px) {
                    width: 100px;
                    height: 100px;

                    img {
                        width: 60px;
                        height: 60px;
                    }
                }
            }

            &::before,
            &::after {
                position: absolute;
                content: "";
                width: 100px;
                height: 150px;
                left: 3px;
                top: -122px;
                background-image: url("/local/html/img/welcome/about/atom-top-path.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                z-index: -1;

                @media (max-width: 991px) {
                    width: 60px;
                    left: 20px;
                    top: -81px;
                }
            }

            &::before {
                @media (max-width: 991px) {
                    width: 60px;
                    left: 20px;
                    top: -81px;
                }

                @media (max-width: 767px) {
                    width: 50px;
                    left: 1px;
                    top: -76px;
                }
            }

            &::after {
                width: 192px;
                height: 107px;
                left: auto;
                right: -82px;
                top: 18px;
                background-image: url("/local/html/img/welcome/about/atom-right-path.svg");

                @media (max-width: 991px) {
                    width: 152px;
                    right: -30px;
                    top: 68px;
                    transform: rotate(90deg);
                }

                @media (max-width: 767px) {
                    width: 90px;
                    right: -15px;
                    top: 38px;
                }
            }

            @media (max-width: 991px) {
                top: 62px;
            }

            @media (max-width: 767px) {
                top: 48px;
            }
        }

        &__top-text {
            max-width: 640px;
            margin-top: 222px;

            .up {
                margin-bottom: 30px;
                font-weight: 700;
                font-size: 64px;
                line-height: 1.2;
                text-transform: uppercase;
                color: #3A4659;

                span {
                    display: inline-block;
                    font-weight: 400;
                    text-transform: none;
                    background: -webkit-linear-gradient(#0B6BA7, #96C15B);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                @media (max-width: 991px) {
                    margin-bottom: 20px;
                    font-size: 42px;
                }

                @media (max-width: 767px) {
                    margin-bottom: 15px;
                    font-size: 32px;
                }
            }

            .down {
                font-size: 52px;
                line-height: 1.2;
                color: #3A4659;

                span {
                    display: inline-block;
                    font-weight: bold;
                    font-size: 40px;
                    line-height: 1.2;
                    color: #96C15B;

                    @media (max-width: 991px) {
                        font-size: 26px;
                    }

                    @media (max-width: 767px) {
                        font-size: 20px;
                    }
                }

                @media (max-width: 991px) {
                    font-size: 34px;
                }

                @media (max-width: 767px) {
                    font-size: 26px;
                }
            }

            @media (max-width: 991px) {
                margin-top: 240px;
            }

            @media (max-width: 767px) {
                margin-top: 180px;
            }
        }

        @media (max-width: 767px) {
            margin-top: 70px;
            margin-bottom: 90px;
        }
    }

    .departments {
        position: relative;
        margin-bottom: 242px;

        &::before {
            position: absolute;
            content: "";
            width: 1745px;
            height: 1694px;
            left: 50%;
            top: -312px;
            transform: translateX(-46%);
            background-image: url("/local/html/img/welcome/backgrounds/shape-2.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top;

            @media (max-width: 767px) {
                transform: translateX(-35%);
            }
        }

        .decorate {
            &--double-caret {
                left: -173px;
                top: 200px;
                animation: levitate-3 4s linear infinite;

                &:nth-child(2) {
                    left: auto;
                    right: -258px;
                    top: 634px;
                }
            }

            &--plus {
                left: 56px;
                top: -21px;
                animation: levitate-1 6s ease-in-out infinite;

                @media (max-width: 767px) {
                    top: -41px;
                }
            }

            &--flow-arrow {
                right: 0;
                top: 0;
                animation: levitate-2 6s 2s ease-in-out infinite;
            }
        }

        &__wrapper {
            display: flex;
            flex-wrap: wrap;
            max-width: 940px;
            margin: 0 auto;

            @media (max-width: 767px) {
                flex-direction: column-reverse;
                margin: 0;
            }
        }

        &__top {
            width: 100%;
            transform: translate(80px, 15px);

            @media (max-width: 767px) {
                transform: none;
            }
        }

        &__bottom {
            width: 100%;
            display: flex;
            align-items: center;

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &__item {
            max-width: 550px;
            margin-bottom: 54px;

            &--bottom {
                max-width: 445px;
            }

            @media (max-width: 767px) {
                margin-bottom: 25px;
            }
        }

        &__head-wrapper {
            max-width: 350px;
            margin-right: 130px;

            @media (max-width: 767px) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        &__heading {
            font-size: 40px;
            line-height: 1.2;
            text-transform: uppercase;

            @media (max-width: 991px) {
                font-size: 26px;
            }

            @media (max-width: 767px) {
                font-size: 20px;
            }
        }

        &__here {
            margin-top: 36px;
            font-weight: bold;
            font-size: 24px;
            line-height: 1.4;
            text-transform: uppercase;
            color: #0C6CA6;

            img {
                width: 60px;
                height: 60px;
                margin-right: 20px;
                animation: levitate-1 2s linear infinite;

                @media (max-width: 991px) {
                    width: 40px;
                    height: 40px;
                    margin-right: 15px;
                }

                @media (max-width: 767px) {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }
            }

            @media (max-width: 991px) {
                margin-top: 30px;
                font-size: 16px;
            }
        }

        &__label-wrapper {
            position: relative;
            width: 120px;
            height: 120px;
            margin-left: 23px;
            margin-bottom: 65px;
            background-color: white;
            border-radius: 50%;
            box-shadow: 40px 40px 40px rgba(0, 0, 0, 0.12);
            z-index: -1;

            img {
                position: absolute;
                width: 80px;
                height: 80px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                object-fit: contain;
            }

            &::after {
                position: absolute;
                content: "";
                width: 12px;
                height: 49px;
                left: 50%;
                bottom: -63px;
                transform: translateX(-50%);
                background-image: url("/local/html/img/welcome/departments/line.svg");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                @media (max-width: 767px) {
                    content: none;
                }
            }

            @media (max-width: 767px) {
                width: 80px;
                height: 80px;
                margin-bottom: 20px;
                margin-left: 0;

                img {
                    width: 45px;
                    height: 45px;
                }
            }
        }

        &__name {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 32px;
            line-height: 1.5;
            color: #3A4659;

            span {
                display: inline-block;
                font-weight: 300;
            }

            @media (max-width: 991px) {
                font-size: 22px;
            }

            @media (max-width: 767px) {
                font-size: 24px;
            }
        }

        &__description {
            font-size: 20px;
            line-height: 1.3;
            color: #4C4C4C;

            @media (max-width: 991px) {
                font-size: 18px;
            }
        }

        @media (max-width: 991px) {
            margin-bottom: 85px;
        }

        @media (max-width: 767px) {
            margin-bottom: 50px;
        }
    }

    .info {
        position: relative;
        margin-bottom: 350px;

        &::before {
            position: absolute;
            content: "";
            width: 1825px;
            height: 1675px;
            top: 50%;
            left: 50%;
            transform: translate(-40%, -50%);
            background-image: url("/local/html/img/welcome/backgrounds/shape-3.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            z-index: -1;
        }

        .decorate {
            &--plus {
                top: -102px;
                left: 147px;
                animation: levitate-4 5s 0.5s linear infinite;
            }

            &--strategy {
                top: 320px;
                right: -200px;
            }

            &--ellipse-arrow {
                top: 247px;
                left: -154px;
            }

            &--double-caret {
                top: 42%;
                left: 56%;
            }

            &--music-notes {
                bottom: 695px;
                right: 195px;

                @media (max-width: 991px) {
                    bottom: 555px;
                    right: 75px;
                }

                &:nth-child(1) {
                    animation: levitate-2 2s linear infinite;
                }

                &:nth-child(2) {
                    bottom: 600px;
                    right: 430px;
                    animation: levitate-5 3s linear infinite;

                    @media (max-width: 991px) {
                        bottom: 545px;
                        right: 310px;
                    }
                }
            }

            &--video-camera {
                bottom: 635px;
                right: 375px;

                @media (max-width: 991px) {
                    bottom: 565px;
                    right: 232px;
                }
            }
        }

        &__wrapper {
            margin-bottom: 220px;

            &:last-child {
                margin-bottom: 0;
            }

            @media (max-width: 991px) {
                margin-bottom: 70px;
            }

            @media (max-width: 767px) {
                margin-bottom: 75px;
            }
        }

        &__head-wrapper {
            margin-bottom: 160px;

            &--short {
                padding-right: 320px;

                @media (max-width: 991px) {
                    padding-right: 170px;
                }

                @media (max-width: 767px) {
                    padding-right: 0;
                }
            }

            @media (max-width: 991px) {
                margin-bottom: 120px;
            }

            @media (max-width: 767px) {
                margin-bottom: 80px;
            }
        }

        &__heading {
            margin-bottom: 10px;
            font-weight: normal;
            font-size: 26px;
            line-height: 1.2;
            text-transform: uppercase;
            color: #989898;

            @media (max-width: 991px) {
                font-size: 22px;
            }

            @media (max-width: 767px) {
                font-size: 16px;
            }
        }

        &__subheading {
            margin-bottom: 34px;
            font-size: 40px;
            line-height: 1.2;
            color: #3A4659;

            span {
                color: #96C15B;
            }

            @media (max-width: 991px) {
                margin-bottom: 24px;
                font-size: 26px;
            }

            @media (max-width: 767px) {
                margin-bottom: 10px;
                font-size: 24px;
            }
        }

        &__description {
            margin: 0;
            font-size: 40px;
            line-height: 1.2;
            color: #3A4659;

            &--small {
                font-weight: 400;
                font-size: 24px;
                line-height: 140%;
                color: #232B37;
                opacity: 0.8;
            }

            a {
                color: #0B6BA7;
            }

            @media (max-width: 991px) {
                font-size: 26px;

                &--small {
                    font-size: 18px;
                }
            }

            @media (max-width: 767px) {
                font-size: 20px;

                &--small {
                    font-size: 18px;
                }
            }
        }

        &__bottom-text {
            margin-top: 62px;
            margin-bottom: 0;
            font-weight: 700;
            font-size: 24px;
            line-height: 1.4;
            text-align: right;
            color: #09101B;
            opacity: 0.8;

            @media (max-width: 991px) {
                margin-top: 30px;
                font-size: 20px;
                text-align: left;
            }

            @media (max-width: 767px) {
                margin-top: 15px;
            }
        }

        &__items {
            display: flex;
            justify-content: center;

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
            }
        }

        &__item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 436px;
            margin-right: 116px;
            margin-bottom: 60px;
            padding: 107px 24px 67px;
            border: 10px solid white;
            border-radius: 10px;
            background: linear-gradient(180deg, #FAFAFA 0%, rgba(245, 245, 245, 0.17) 100%);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);

            h3 {
                min-height: 87px;
                margin-bottom: 20px;
                font-weight: 700;
                font-size: 24px;
                line-height: 1.2;
                text-align: center;
                color: #3A4659;

                span {
                    color: #96C15B;
                }

                @media (max-width: 991px) {
                    min-height: 72px;
                    margin-bottom: 15px;
                    font-size: 20px;
                }

                @media (max-width: 767px) {

                }
            }

            @media (max-width: 991px) {
                margin-right: 30px;
                padding: 95px 15px 60px;
            }

            @media (max-width: 767px) {
                margin-right: 0;
                margin-bottom: 80px;
                padding: 75px 15px 40px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__image-wrapper {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 162px;
            height: 162px;
            left: 50%;
            top: -81px;
            transform: translateX(-50%);

            @media (max-width: 991px) {
                width: 130px;
                height: 130px;
                top: -65px;
            }

            @media (max-width: 767px) {
                width: 100px;
                height: 100px;
                top: -50px;
            }
        }

        &__image-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 120px;

            img {
                width: 60px;
                height: 60px;
            }

            @media (max-width: 991px) {
                width: 95px;
                height: 95px;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            @media (max-width: 767px) {
                width: 75px;
                height: 75px;

                img {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        &__circle {
            border-radius: 50%;
            background-color: white;
            box-shadow: 40px 40px 40px rgba(0, 0, 0, 0.12);
        }

        &__text-content {
            margin-bottom: 40px;
            font-size: 20px;
            line-height: 1.3;
            text-align: center;
            color: #000000;

            @media (max-width: 991px) {
                font-size: 18px;
            }
        }

        &__btn {
            min-width: 302px;
            margin-top: auto;

            @media (max-width: 991px) {
                min-width: 250px;
                font-size: 16px;
            }

            @media (max-width: 767px) {
                min-width: auto;
                width: 100%;
            }
        }

        &__controller-wrapper {
            position: absolute;
            width: 200px;
            height: 200px;
            top: 50%;
            right: -60px;
            transform: translateY(-50%);
            box-shadow: 30px 30px 40px rgba(0, 0, 0, 0.25);

            .inner {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 200px;
                height: 200px;
                background-color: white;
                border-radius: 10px;
            }

            img {
                width: 120px;
                height: 120px;
            }

            &::before,
            &::after {
                position: absolute;
                content: "";
                width: 448px;
                height: 150px;
                left: 50px;
                top: -123px;
                background-image: url("/local/html/img/welcome/info/controller-top-path.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                z-index: -1;
            }

            &::after {
                width: 298px;
                height: 97px;
                left: -170px;
                bottom: -90px;
                top: auto;
                background-image: url("/local/html/img/welcome/info/controller-bottom-path.svg");
            }

            @media (max-width: 1400px) {
                right: 0;
                transform: translateY(-25%);
            }

            @media (max-width: 991px) {
                width: 150px;
                height: 150px;

                &::before {
                    width: 218px;
                    left: 100px;
                }

                &::after {
                    width: 158px;
                    left: -54px;
                    bottom: -102px;
                }
            }

            @media (max-width: 797px) {
                display: none;
            }
        }

        @media (max-width: 991px) {
            margin-bottom: 200px;
        }

        @media (max-width: 767px) {
            margin-bottom: 120px;
        }
    }

    .guide {
        position: relative;
        margin-bottom: 415px;

        &::before {
            position: absolute;
            content: "";
            width: 4285px;
            height: 4285px;
            top: -1340px;
            left: 50%;
            transform: translateX(-53%);
            background-image: url("/local/html/img/welcome/guide/background.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            z-index: -1;

            @media (max-width: 991px) {
                top: -1240px;
            }

            @media (max-width: 767px) {
                top: -1570px;
                width: 2700px;
            }
        }

        .decorate {
            &--rectangle {
                top: -70px;
                right: -55px;
            }
        }

        &__head-wrapper {
            position: relative;
            margin-bottom: 186px;

            &::before {
                position: absolute;
                content: "";
                width: 395px;
                height: 395px;
                top: -120px;
                left: -130px;
                border-radius: 50%;
                background-image: url("/local/html/img/welcome/guide/glow.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                z-index: -1;

                @media (max-width: 767px) {
                    content: none;
                }
            }

            @media (max-width: 991px) {
                margin-bottom: 145px;
            }

            @media (max-width: 767px) {
                margin-bottom: 30px;
            }
        }

        &__head-inner {
            display: flex;

            @media (max-width: 767px) {
                flex-direction: column;
            }
        }

        &__supheading {
            display: inline-block;
            margin-bottom: 10px;
            font-size: 26px;
            line-height: 1.2;
            text-transform: uppercase;
            color: white;

            @media (max-width: 991px) {
                font-size: 22px;
            }

            @media (max-width: 767px) {
                font-size: 16px;
            }
        }

        &__heading {
            flex-shrink: 0;
            width: 400px;
            margin: 0;
            margin-right: 88px;
            font-weight: 700;
            font-size: 40px;
            line-height: 1.2;
            color: white;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

            span {
                color: #BBFF00;
            }

            @media (max-width: 991px) {
                width: 355px;
                margin-right: 50px;
                font-size: 26px;
            }

            @media (max-width: 767px) {
                font-weight: 400;
                font-size: 24px;
            }
        }

        &__footnote {
            position: relative;
            margin-top: 20px;
            margin-bottom: 30px;
            font-size: 24px;
            line-height: 1.4;
            color: rgba(white, 0.8);

            &::before {
                position: absolute;
                content: "*";
                top: -15px;
                left: -20px;
                font-size: 40px;
                color: #96C15B;

                @media (max-width: 991px) {
                    top: -6px;
                    left: -14px;
                    font-size: 28px;
                }
            }

            @media (max-width: 991px) {
                font-size: 18px;
            }

            @media (max-width: 767px) {
                margin-top: 8px;
                margin-left: 14px;
            }
        }

        &__head-bottom-text {
            margin-left: 488px;
            font-weight: 700;
            font-size: 24px;
            line-height: 1.3;
            color: white;

            @media (max-width: 991px) {
                margin-left: 403px;
                font-size: 20px;
            }

            @media (max-width: 767px) {
                margin-left: 0;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding-left: 35px;
            list-style: none;

            @media (max-width: 767px) {
                padding-left: 0;
            }
        }

        &__item {
            display: flex;
            margin-bottom: 133px;

            h3 {
                margin-bottom: 15px;
                font-weight: 700;
                font-size: 32px;
                line-height: 1.5;
                color: #96C15B;

                @media (max-width: 991px) {
                    margin-bottom: 10px;
                    font-size: 22px;
                }

                @media (max-width: 767px) {
                    margin-bottom: 8px;
                    font-size: 20px;
                }
            }

            &:nth-child(even) {
                .guide__decorate-dots {
                    transform: scaleX(-1) translateX(110px);
                }
            }

            &:last-child {
                margin-bottom: 0;

                .guide__image-wrapper {
                    &::after {
                        width: 17px;
                        height: 151px;
                        bottom: -165px;
                        background-image: url("/local/html/img/welcome/guide/arrow-bottom.svg");
                    }
                }

            }

            @media (max-width: 767px) {
                flex-direction: column;
                margin-bottom: 50px;
            }
        }

        &__image-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 162px;
            height: 162px;
            margin-right: 285px;
            background: #232B37;
            box-shadow: 40px 40px 40px rgba(0, 0, 0, 0.12);
            border-radius: 50%;
            transform: translateY(-65px);

            &::before {
                position: absolute;
                content: "";
                width: 520px;
                height: 365px;
                top: -97px;
                left: -100px;
                background-image: url("/local/html/img/welcome/guide/item-background.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                z-index: -1;

                @media (max-width: 991px) {
                    width: 390px;
                    height: 355px;
                    top: -114px;
                    left: -76px;
                }

                @media (max-width: 767px) {
                    content: none;
                }
            }

            &::after {
                position: absolute;
                content: "";
                width: 6px;
                height: 122px;
                bottom: -136px;
                left: 50%;
                transform: translateX(-50%);
                background-image: url("/local/html/img/welcome/guide/line-bottom.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                z-index: -1;

                @media (max-width: 991px) {
                    bottom: -133px;
                }

                @media (max-width: 767px) {
                    content: none;
                }
            }

            @media (max-width: 991px) {
                width: 120px;
                height: 120px;
                margin-right: 250px;
                transform: translateY(-48px);
            }

            @media (max-width: 767px) {
                width: 80px;
                height: 80px;
                margin-right: 0;
                margin-bottom: 10px;
                transform: none;
            }
        }

        &__image-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 120px;
            background: linear-gradient(180deg, #24FF00 0%, #A0BF4A 100%);
            box-shadow: 40px 40px 40px rgba(0, 0, 0, 0.12);
            border-radius: 50%;
            z-index: 0;

            img {
                width: 60px;
                height: 60px;
                object-fit: contain;
                z-index: 2;

                @media (max-width: 991px) {
                    width: 40px;
                    height: 40px;
                }

                @media (max-width: 991px) {
                    width: 35px;
                    height: 35px;
                }
            }

            @media (max-width: 991px) {
                width: 90px;
                height: 90px;
            }

            @media (max-width: 767px) {
                width: 60px;
                height: 60px;
            }
        }

        &__decorate-dots,
        &__decorate-beam,
        &__decorate-top-line,
        &__decorate-arrow {
            position: absolute;
            width: 294px;
            height: 287px;
            top: 0px;
            left: -20px;
            background-image: url("/local/html/img/welcome/guide/dots.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            @media (max-width: 767px) {
                display: none;
            }
        }

        &__decorate-dots {
            @media (max-width: 991px) {
                width: 264px;
                height: 257px;
                top: -10px;
                left: -40px;
            }
        }

        &__decorate-beam {
            width: 311px;
            height: 311px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url("/local/html/img/welcome/guide/beam.svg");
            z-index: -2;
        }

        &__decorate-top-line {
            width: 6px;
            height: 122px;
            top: -150px;
            left: 50%;
            transform: translateX(-50%);
            background-image: url("/local/html/img/welcome/guide/line-top.svg");
        }

        &__decorate-arrow {
            width: 88px;
            height: 88px;
            top: -72px;
            left: auto;
            right: -78px;
            background-image: url("/local/html/img/welcome/guide/arrow-top.svg");

            @media (max-width: 991px) {
                top: -75px;
                right: -82px;
            }
        }

        &__text-content {
            display: flex;
            align-items: flex-start;

            @media (max-width: 1200px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &__text {
            margin-right: 50px;
            margin-bottom: 0;
            font-size: 24px;
            line-height: 1.4;
            color: rgba(white, 0.8);

            @media (max-width: 1200px) {
                margin-right: 0;
            }

            @media (max-width: 991px) {
                font-size: 18px;
            }
        }

        &__link {
            position: relative;
            flex-shrink: 0;
            margin-top: 8px;
            padding-right: 52px;
            font-weight: 700;
            font-size: 16px;
            line-height: 1;
            text-transform: uppercase;
            color: #96C15B;

            &::after {
                position: absolute;
                content: "";
                width: 32px;
                height: 32px;
                top: -8px;
                right: 0;
                background-image: url("/local/html/img/welcome/guide/arrow-circle.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                @media (max-width: 767px) {
                    width: 24px;
                    height: 24px;
                    top: -6px;
                    right: 18px;
                }
            }

            &:hover {
                opacity: 0.8;
                text-decoration: none;
            }

            @media (max-width: 767px) {
                margin-top: 12px;
            }
        }

        @media (max-width: 1200px) {
            margin-bottom: 300px;
        }

        @media (max-width: 991px) {
            margin-bottom: 225px;
        }

        @media (max-width: 767px) {
            margin-bottom: 75px;
        }
    }

    .contacts {
        margin-bottom: 150px;

        .decorate {
            &--double-caret {
                top: 50px;
                left: -70px;
                animation: levitate-1 5s linear infinite;
            }
        }

        &__wrapper {
            max-width: 1030px;
            width: 100%;
            margin: 0 auto;
        }

        &__head-wrapper {
            margin-bottom: 30px;

            @media (max-width: 767px) {
                margin-bottom: 20px;
            }
        }

        &__heading {
            margin: 0;
            font-weight: 400;
            font-size: 26px;
            line-height: 1.2;
            text-transform: uppercase;
            color: white;

            @media (max-width: 991px) {
                font-size: 22px;
            }

            @media (max-width: 767px) {
                font-size: 16px;
            }
        }

        &__content {
            padding: 77px 84px 100px;
            background: linear-gradient(180deg, #FAFAFA 0%, #F5F5F5 100%);
            border: 10px solid white;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
            border-radius: 10px;

            @media (max-width: 991px) {
                padding: 40px 20px;
            }

            @media (max-width: 767px) {
                padding: 30px 15px;
            }
        }

        &__title {
            margin-bottom: 90px;
            font-weight: 700;
            font-size: 40px;
            line-height: 1.2;
            color: rgba(#09101B, 0.8);

            @media (max-width: 991px) {
                font-size: 26px;
            }

            @media (max-width: 767px) {
                margin-bottom: 30px;
                font-size: 24px;
            }
        }

        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            counter-reset: myCounter;
        }

        &__item {
            position: relative;
            margin-bottom: 120px;
            padding-left: 150px;

            &::before {
                position: absolute;
                display: inline-block;
                top: -10px;
                left: 0;
                counter-increment: myCounter;
                content: "0"counter(myCounter);
                font-weight: bold;
                font-size: 100px;
                line-height: 1.1;
                color: #D3D3D3;

                @media (max-width: 991px) {
                    font-size: 66px;
                }

                @media (max-width: 767px) {
                    top: 2px;
                    font-size: 20px;
                }
            }

            &::after {
                position: absolute;
                content: "";
                width: 2px;
                height: 70px;
                left: 60px;
                bottom: -90px;
                border: 2px dashed #D3D3D3;

                @media (max-width: 991px) {
                    left: 40px;
                    bottom: -70px;
                }

                @media (max-width: 767px) {
                    content: none;
                }
            }

            &:last-child {
                margin-bottom: 0;

                &::after {
                    content: none;
                }
            }

            @media (max-width: 991px) {
                margin-bottom: 90px;
            }

            @media (max-width: 767px) {
                margin-bottom: 35px;
                padding-left: 30px;
            }
        }

        &__link {
            position: relative;
            display: inline-block;
            padding-right: 35px;
            font-weight: 700;
            font-size: 32px;
            line-height: 1.3;
            text-transform: uppercase;

            &::after {
                position: absolute;
                content: "";
                width: 24px;
                height: 24px;
                bottom: 8px;
                right: 0;
                background-image: url("/local/html/img/welcome/contacts/arrow-square-out.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

                @media (max-width: 991px) {
                    width: 20px;
                    height: 20px;
                    bottom: 5px;
                }

                @media (max-width: 767px) {
                    width: 17px;
                    height: 17px;
                }
            }

            @media (max-width: 991px) {
                padding-right: 25px;
                font-size: 22px;
            }

            @media (max-width: 767px) {
                font-size: 20px;
            }
        }

        &__description {
            margin-top: 11px;
            font-size: 20px;
            line-height: 1.3;
            color: rgba(#3A4659, 0.8);

            a {
                font-weight: 700;
                color: rgba(#0C6CA6, 0.8);
                text-decoration: none;
            }

            @media (max-width: 991px) {
                margin-top: 8px;
                font-size: 18px;
            }

            @media (max-width: 767px) {
                margin-top: 5px;
            }
        }

        @media (max-width: 991px) {
            margin-bottom: 105px;
        }

        @media (max-width: 767px) {
            margin-bottom: 75px;
        }
    }

    .extra {
        position: relative;
        padding-bottom: 375px;

        &::after {
            position: absolute;
            content: "";
            width: 1825px;
            height: 1675px;
            top: -910px;
            left: 50%;
            transform: translateX(-50%);
            background-image: url("/local/html/img/welcome/backgrounds/shape-3.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            z-index: -2;
        }

        &::before {
            position: absolute;
            content: "";
            width: 3831px;
            height: 3831px;
            top: -760px;
            left: 50%;
            transform: translateX(-48%);
            background-image: url("/local/html/img/welcome/backgrounds/shape-4.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            z-index: -1;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 950px;
            margin: 0 auto;
            padding: 0 45px;

            @media (max-width: 767px) {
                padding: 0;
            }
        }

        &__image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 200px;
            margin-bottom: 72px;
            background: white;
            box-shadow: 30px 30px 40px rgba(0, 0, 0, 0.25);
            border-radius: 10px;

            img {
                width: 120px;
                height: 120px;
                object-fit: contain;
            }

            @media (max-width: 991px) {
                width: 160px;
                height: 160px;
                margin-bottom: 50px;

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            @media (max-width: 767px) {
                width: 100px;
                height: 100px;
                margin-bottom: 30px;

                img {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        &__head-wrapper {
            margin-bottom: 16px;

        }

        &__heading {
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 26px;
            line-height: 1.2;
            text-align: center;
            text-transform: uppercase;
            color: white;

            @media (max-width: 991px) {
                font-size: 22px;
            }

            @media (max-width: 767px) {
                font-size: 16px;
            }
        }

        &__subheading {
            margin: 0;
            font-weight: 700;
            font-size: 40px;
            line-height: 1.2;
            background: -webkit-linear-gradient(#24FF00, #A0BF4A);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: center;

            @media (max-width: 991px) {
                font-size: 26px;
            }

            @media (max-width: 767px) {
                font-size: 20px;
            }
        }

        &__text-content {
            margin-bottom: 73px;
            font-size: 24px;
            line-height: 1.4;
            text-align: center;
            color: white;

            @media (max-width: 991px) {
                margin-bottom: 50px;
                font-size: 18px;
            }

            @media (max-width: 767px) {
                margin-bottom: 30px;
            }
        }

        &__bottom-text {
            margin: 0;
            font-weight: 700;
            font-size: 32px;
            line-height: 1.3;
            text-align: center;
            text-transform: uppercase;
            color: white;

            @media (max-width: 991px) {
                font-size: 22px;
            }

            @media (max-width: 767px) {
                font-size: 20px;
            }
        }

        @media (max-width: 991px) {
            padding-bottom: 180px;
        }

        @media (max-width: 767px) {
            padding-bottom: 90px;
        }
    }
}

@keyframes levitate-1 {
    0% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(-5px);
    }
}

@keyframes levitate-2 {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0.9);
    }
}

@keyframes levitate-3 {
    0% {
        transform: translate(-5px, 10px) rotate(-5deg);
    }

    50% {
        transform: translate(5px, 0px) rotate(5deg);
    }

    100% {
        transform: translate(-5px, 10px) rotate(-5deg);
    }
}

@keyframes levitate-4 {
    0% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(15px);
    }

    100% {
        transform: translateX(-5px);
    }
}

@keyframes levitate-5 {
    0% {
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(10deg);
    }

    100% {
        transform: rotate(-10deg);
    }
}