.nx-actions{
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -20px;
    position: relative;
    
    &__item{
        margin-right: 30px;
        margin-bottom: 20px;
        &:last-child{
            margin-right: 0;
        }

        .link {
            font-size: 21px;
            line-height: 26px;
            color: var(--main-bg);
            text-decoration: none;
            transition: .2s;
            &:hover, &:focus, &:active {
                text-decoration: none;
                color: var(--light-blue) !important;
            }
        }
    }
    
    &_center{
        justify-content: center;
    }
    
    &_space-between {
        justify-content: space-between;
    }
    
    &_offset-top {
        margin-top: 30px;
    }
    
    &_small {
        .nx-actions__item {
            margin-right: 10px;
        }
    }
    
    &_note{
        flex-wrap: nowrap;
        align-items: flex-start;
        .nx-actions__item_btn{
            flex: none;
        }
    }
    
    &_modal {
        display: block;
        margin: 40px 0 -10px;
        .nx-actions__item {
            margin-bottom: 10px;
        }
    }
    
    &_col {
        display: block;
    }
    
    &_single {
        margin-bottom: 0;
        .nx-actions__item {
            margin-bottom: 0;
            margin-right: 0;
        }
    }
}